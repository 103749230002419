module.exports = {
  "FORMS": "Formulare",
  "FOLDERS": "Ordner",
  "ADMINISTRATIVE": "Administrativ",
  "INVITE": "Einladung",
  "INVITES": "Einladungen",
  "USER_MANAGEMENT_OVERVIEW": "Nutzerübersicht",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Nutzerinformation",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Nutzer bearbeiten",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Nutzer löschen",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Einladung erneut senden",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Name",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Admin",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Gruppen",
  "ACCOUNT_OWNER": "Kontoinhaber",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "E-Mail-Adresse",
  "USER_MANAGEMENT_ALL_USERS": "Alle Nutzer",
  "USER_MANAGEMENT_NEW_TITLE": "Neue(r) Nutzer",
  "USER_MANAGEMENT_EDIT_TITLE": "Nutzer verwalten",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Berechtigungen",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Admin",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "Keine Gruppen",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Ordner",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(nach Gruppe)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "Keine Ordner",
  "USER_MANAGEMENT_EDIT_SECURITY": "Nutzersicherheit",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Nutzereinstellungen",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Benutzername",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Vorname",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Nachname",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Telefonnummer",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Danger Zone",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Nutzer entfernen",
  "CUSTOMER_GROUPS_NEW_TITLE": "Neue Gruppe",
  "CUSTOMER_GROUPS_NAME_LABEL": "Name",
  "CUSTOMER_GROUPS_NO_GROUPS": "Es gibt keine Gruppen.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "Keine sichtbaren Gruppen. Bitte ändern Sie den Filter",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Gruppenmitglieder",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Gruppe hinzufügen",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Mitglied von",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Bearbeiten",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Entfernen",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Gruppe bearbeiten",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "App-Berechtigungen",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Diese Gruppe hat Zugriff auf die Formulare folgender Ordner: ",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Plattform-Berechtigungen",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Diese Gruppe verwaltet:",
  "MANAGE_FORMS_LABEL": "Formulare & Ordner",
  "MANAGE_REGISTRATIONS_LABEL": "Submissions",
  "MANAGE_TASKS_LABEL": "Aufgaben",
  "MANAGE_USERS_LABEL": "Nutzer",
  "MANAGE_SSO_LABEL": "Single Sign-On (SSO)",
  "MANAGE_DATA_SOURCES_LABEL": "Datenquellen",
  "MANAGE_TEMPLATES_LABEL": "Vorlagen",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Eigentumsrecht übertragen",
  "MANAGE_ACCOUNT_LABEL": "Globale Kontoeinstellungen",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Gruppe entfernen",
  "SELECT_GROUPS": "Gruppe auswählen",
  "USER_COUNT": "Enthält {{value}} Nutzer",
  "USER_COUNT_SINGLE": "Enthält einen Nutzer",
  "USER_COUNT_EMPTY": "Enthält keine Nutzer",
  "USER_MANAGEMENT_REMOVE_TITLE": "Nutzer löschen",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Sind Sie sicher, dass Sie diesen Nutzer entfernen wollen?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "Sie können sich nicht selbst entfernen",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Bitten Sie den Kontoinhaber, Sie zu entfernen.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Nutzer aus Gruppe entfernen",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Sind Sie sicher, dass Sie diesen Nutzer aus der Gruppe entfernen möchten?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Einladung löschen",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Sind Sie sicher, dass Sie diese Einladung löschen wollen?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Einladung aus Gruppe entfernen",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Sind Sie sicher, dass Sie diese Einladung aus der Gruppe entfernen wollen?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Gruppe löschen",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Sind Sie sicher, dass Sie diese Gruppe löschen wollen? Dies kann nicht rückgängig gemacht werden! Dies führt jedoch nicht zur Löschung der Nutzer dieser Gruppe.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Nutzer wurde eingeladen",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Sie haben erfolgreich <strong>{{count}} Nutzer </strong> dazu eingeladen, sich Ihrem Account anzuschließen.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "Die gewählte Datei ist größer als 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "Wie viele Nutzer möchten Sie einladen?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Einen Nutzer",
  "USER_MANAGEMENT_INVITE_BULK": "Mehrere Nutzer",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Wählen Sie eine Excel-Datei, welche in der ersten Spalte eine Liste der E-Mail-Adressen enthält",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Sprache der Einladung",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "Nutzereinladung fehlgeschlagen",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "Der Nutzer wurde bereits zu diesem Konto hinzugefügt",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "Änderung der Berechtigungen fehlgeschlagen",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "Die Gruppe konnte nicht entfernt werden, da sonst keine Berechtigung zur Benutzerverwaltung mehr für Sie vorliegt",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "Die Berechtigung konnte nicht entfernt werden, da für Sie keine Berechtigung zur Benutzerverwaltung vorliegt ",
  "ROLES": "Rollen",
  "ROLES_DEFAULT": "Standard-Rollen",
  "ROLES_CUSTOM": "Eigene Rollen",
  "MANAGE_ROLES_TITLE": "Rollen verwalten",
  "MANAGE_ROLES_DESCRIPTION": "Definieren Sie die Nutzer-Berechtigungen, die zu Ihren jeweiligen Rollen gehören.",
  "MANAGE_BILLING": "Abrechnung verwalten",
  "MANAGE_CREDITS": "Abrechnung verwalten",
  "MANAGE_DATA_SOURCES": "Datenquellen verwalten",
  "MANAGE_FORMS": "Alle Formulare & Ordner verwalten",
  "MANAGE_TASKS": "Alle Aufgaben verwalten",
  "MANAGE_REGISTRATIONS": "Alle Submissions verwalten",
  "MANAGE_USERS": "Nutzer & Zugriffe verwalten",
  "MANAGE_SSO": "Single Sign-On (SSO) verwalten",
  "MANAGE_SENDING_DOMAIN": "E-Mail-Domain verwalten",
  "MANAGE_WEBHOOKS": "Webhooks verwalten",
  "MANAGE_TEMPLATES": "Vorlagen verwalten",
  "MANAGE_ACCOUNT": "Konto verwalten",
  "TRANSFER_OWNERSHIP": "Eigentumsrecht übertragen",
  "CUSTOMER_USER": "App-Zugriff",
  "CREATE_FOLDER": "Ordner erstellen",
  "CREATE_FORM": "Formulare erstellen",
  "ADD_FORM": "Formular zu Ordner hinzufügen",
  "REMOVE_FORM": "Formular aus Ordner entfernen",
  "UPDATE_FOLDER": "Ordner aktualisieren",
  "DELETE_FOLDER": "Ordner löschen",
  "CREATE_SUBMISSION": "Submission erstellen",
  "READ_SUBMISSION": "Submissions einsehen",
  "DELETE_SUBMISSION": "Submissions löschen",
  "UPDATE_SUBMISSION": "Submissions aktualisieren",
  "READ_TASK": "Aufgaben anzeigen",
  "UPDATE_TASK": "Aufgaben abschließen",
  "CREATE_TASK": "Aufgaben erstellen",
  "DELETE_TASK": "Aufgaben widerrufen",
  "UPDATE_FORM": "Formular aktualisieren",
  "ARCHIVE_FORM": "Formular löschen",
  "RESTORE_FORM": "Formular wiederherstellen",
  "MANAGE_BILLING_TOOLTIP": "Aktualisieren Sie Ihren Plan, Ihre Rechnungsdetails und Ihre Zahlungsmethode. Sehen Sie Ihre Rechnungen und bisherige Nutzung ein.",
  "MANAGE_CREDITS_TOOLTIP": "Aktualisieren Sie Ihren Plan, Ihre Rechnungsdetails und Ihre Zahlungsmethode. Sehen Sie Ihre Rechnungen und bisherige Nutzung ein.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Erstellen, sehen, aktualisieren und löschen Sie alle Datenquellen",
  "MANAGE_FORMS_TOOLTIP": "Erstellen, sehen und aktualisieren Sie <b> ALLE </b> Formulare und Ordner",
  "MANAGE_TASKS_TOOLTIP": "Aufgaben für <b> ALLE </b> Formulare erstellen, anzeigen und aktualisieren ",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Sehen, löschen und versenden Sie erneut Submissions für <b> ALLE </b> Formulare",
  "MANAGE_USERS_TOOLTIP": "Verwalten Sie die Zugriffe für alle Nutzer, Einladungen und Gruppen",
  "MANAGE_SSO_TOOLTIP": "Konfiguration von Single Sign-On (SSO) verwalten",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Konfiguration für personalisierte E-Mail-Domain verwalten",
  "MANAGE_WEBHOOKS_TOOLTIP": "Erstellen, sehen, aktualisieren und löschen Sie alle Webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Erstellen, sehen, aktualisieren und löschen Sie alle Vorlagen ",
  "MANAGE_ACCOUNT_TOOLTIP": "Globale Kontoeinstellungen verwalten",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Globale Kontoeinstellungen verwalten",
  "CREATE_FOLDER_TOOLTIP": "Erstellen Sie neue Ordner",
  "CREATE_FORM_TOOLTIP": "Innerhalb eines Ordner erstellen, auf den Sie Zugriff haben",
  "ADD_FORM_TOOLTIP": "Zu einem Ordner hinzufügen, auf den Sie Zugriff haben",
  "REMOVE_FORM_TOOLTIP": "Aus einem Ordner entfernen, auf den Sie Zugriff haben",
  "UPDATE_FOLDER_TOOLTIP": "Name, Beschreibung und Symbol aktualisieren.",
  "DELETE_FOLDER_TOOLTIP": "Ordner löschen",
  "CREATE_SUBMISSION_TOOLTIP": "Versenden Sie ein Formular mit der App, um eine neue Submission zu erstellen",
  "READ_SUBMISSION_TOOLTIP": "Submissions für ein bestimmtes Formular anzeigen",
  "DELETE_SUBMISSION_TOOLTIP": "Submissions für ein bestimmtes Formular löschen",
  "UPDATE_SUBMISSION_TOOLTIP": "Submissions für ein bestimmtes Formular aktualisieren",
  "CREATE_TASK_TOOLTIP": "Aufgaben für ein bestimmtes Formular erstellen",
  "READ_TASK_TOOLTIP": "Aufgaben für ein bestimmtes Formular anzeigen",
  "UPDATE_TASK_TOOLTIP": "Aufgaben für ein bestimmtes Formular abschließen",
  "DELETE_TASK_TOOLTIP": "Aufgaben für ein bestimmtes Formular widerrufen",
  "UPDATE_FORM_TOOLTIP": "Formular bearbeiten und veröffentlichen",
  "ARCHIVE_FORM_TOOLTIP": "Formular löschen",
  "ALLOWED": "Ja",
  "ALLOW": "Erlauben",
  "DONT_ALLOW": "Nicht erlauben",
  "NOT_ALLOWED": "Nein",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Ordner und Formulare",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Verwalten Sie die Zugriffe aller Nutzer dieser Gruppe.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Diese Gruppe kann <b>alle Formulare und Ordner</b> mit der oben erwähnten Rolle verwalten",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Dieser Nutzer kann mit dieser Rolle <b>alle Formulare und Ordner</b> verwalten",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Verwalten Sie den Zugriff dieses Nutzers.",
  "ACCESS_ON_ACCOUNT": "Account",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Wählen Sie eine Account-Rolle für alle Nutzer in dieser Gruppe aus.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Wählen Sie eine Account-Rolle für diesen Nutzer aus.",
  "NEW_ROLE": "Neue Rolle erstellen",
  "USER_IN_GROUPS_DESCRIPTION": "Dieser Nutzer gehört folgenden Gruppen an",
  "USER_NOT_IN_GROUP_DESCRIPTION": "Dieser Nutzer gehört keiner Gruppe an",
  "ACCESS_VIA_GROUPS": "Zugriff über Gruppen",
  "ROLE_ACCOUNT_OWNER": "Kontoinhaber",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Konto-Admin",
  "ROLE_OWNER": "Kontoinhaber",
  "ROLE_USER": "Kontonutzer",
  "ROLE_FORM_MANAGER": "Formularmanager",
  "ROLE_FORM_USER": "Formularnutzer",
  "ROLE_FOLDER_MANAGER": "Ordnermanager",
  "USER_DESCRIPTION": "Nutzerinformation",
  "ROLE_DELETE_TITLE": "Rolle entfernen",
  "ROLE_DELETE_MESSAGE": "Sie können nur Rollen löschen, die nicht verwendet werden. Stellen Sie sicher, dass Sie zuerst alle Nutzer/Gruppen aktualisieren, die diese Rolle nutzen.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "External",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Diese Gruppe wird von Ihrem External Directory synchronisiert und kann nicht geändert werden.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "External",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Dieser Nutzer wird von Ihrem External Directory synchronisiert und kann nicht geändert werden.",
  "USER_SUSPENDED": "Suspended",
  "USER_SUSPENDED_TOOLTIP": "Dieser Nutzer wurde in Ihrem External Directory als \"suspended\" markiert",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Eigentumsrecht darf nicht übertragen werden",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Sie sind nicht berechtigt, das Eigentumsrecht an einen eingeladenen Nutzer zu übertragen. Der Nutzer muss zuerst die Einladung annehmen.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "Eigentumsrecht darf nicht übertragen werden",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Sie müssen Kontoinhaber sein, um das Eigentumsrecht auf einen anderen Nutzer zu übertragen",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Eigentumsrecht übertragen",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "Möchten Sie das Eigentumsrecht wirklich an <em>{{firstName}} {{lastName}} ({{username}})</em> übertragen?<br /> <br /> <b>Hinweis:</b > Der Zugriff auf Ihr Konto geht verloren.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Eigentumsrecht wurde übertragen",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> ist jetzt aktueller Kontoinhaber.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "E-Mail",
  "MENU_RULES": "Regeln",
  "MENU_HOOKS": "Integrationen",
  "SUB_FORM_NO_LONGER_USED_TITLE": "Das Subformular wird nicht mehr verwendet.",
  "SUB_FORM_NO_LONGER_USED_BODY": "Das Subformular wird an keiner anderen Stelle im Ordner verwendet. Wollen Sie das Subformular löschen?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Die Subformulare werden an keiner anderen Stelle im Ordner verwendet. Wollen Sie die Subformulare löschen?",
  "UNSUPPORTED_FILE_TYPE": "Es werden nur die folgenden Dateitypen unterstützt: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Diese Version des Formular kann nicht bearbeitet werden",
  "FORM_FINALIZED_MESSAGE": "Wenn Sie Ihr Formular bearbeiten möchten, kehren Sie zur Formular-Übersicht zurück und klicken Sie auf ‘Formular bearbeiten’.",
  "PUBLISH_FAILED_TITLE": "Fehler bei der Veröffentlichung Ihres Formulars",
  "PUBLISH_FAILED_MESSAGE": "Keine Panik, Ihr Formular konnte zwar nicht veröffentlicht werden, wurde jedoch erfolgreich gespeichert. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut. Wenn das Problem weiterhin auftreten sollte, kontaktieren Sie uns.",
  "INTEGRATION_EOL_MESSAGE": "Diese Integration wurde deaktiviert. Bitte entfernen oder ersetzen Sie diese durch eine andere Integration oder einen anderen Webhook. Bitte <a href=\"https://help.moreapp.com/en/support/tickets/new\">wenden Sie sich an unseren Support</a>, wenn Sie weitere Hilfe benötigen.",
  "USER_PICKER_ADD_USER": "Nutzer einladen",
  "USER_PICKER_ADD_GROUP": "Neue Gruppe erstellen",
  "USER_PICKER_ALL_USERS": "Alle Nutzer",
  "USER_PICKER_INVITES": "Einladungen",
  "USER_PICKER_INVITED": "(Eingeladen)",
  "COLLECTION_PICKER_ADD_FORM": "Neues Formular erstellen",
  "COLLECTION_PICKER_ADD_COLLECTION": "Neuen Ordner erstellen",
  "SEARCH_FORMS_PLACEHOLDER": "Suchen Sie nach Ordnern und Formularen",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Spalten auswählen",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Suche",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Ansicht",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Alle löschen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Sind Sie sich absolut sicher?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Sind Sie sicher, dass Sie alle Submissions dieses Formulars löschen möchten? Dies kann nicht rückgängig gemacht werden!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Hierdurch werden ALLE Submissions gelöscht, nicht nur die ausgewählten.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Bitte geben Sie zur Bestätigung Ihre Kunden-ID ein. Diese ist",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Export",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Alle exportieren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Gefiltert exportieren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Ausgewählte exportieren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Bilder einbeziehen",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Bilder einbeziehen",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "In einzelnes Excel-Blatt exportieren",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "Der Export kann etwas Zeit in Anspruch nehmen.",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Sie sind dabei, eine Reihe von Submissions zu exportieren. Das Herunterladen dieser kann etwas Zeit beanspruchen.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "Für dieses Formular gibt es keine Nutzer.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Ausgewählt",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Auswahl aufheben",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Submission-Details",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Submission löschen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Submission dauerhaft löschen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diese Submission löschen wollen? Dies kann nicht rückgängig gemacht werden!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Submission erneut senden",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Submission erneut senden",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Wollen Sie diese Submission erneut senden?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "Die Submission wurde versendet",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Es gab einen Fehler beim wiederholten Versenden Ihrer Submission. Falls dies weiterhin vorkommt, kontaktieren Sie bitte unseren Support.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "E-Mail’s mit PDF-Anhang",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Empfänger",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Informationen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Nutzer",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Daten",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Alle Bilder",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Standort",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "In Google Maps anzeigen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "Nicht aufgenommen",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Datum wurde gespeichert",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Gerät",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Netzwerk",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "App-Version",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Aufgabe",
  "SERIAL_NUMBER": "Seriennummer",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integrationen",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Wiederholen Sie den Schritt {{index}} ",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Bericht herunterladen",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Nächster Versuch in",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "E-Mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "PDF downloaden",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Erneuter Sendeversuch",
  "DOWNLOAD": "Download",
  "MAIL_STATUS_SEND": "{{number}} gesendet",
  "MAIL_STATUS_QUEUED": "{{number}} ausstehend",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} Soft Bounce",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} Hard Bounce",
  "MAIL_STATUS_REJECT": "{{number}} abgelehnt ",
  "BAD_MAILBOX": "Bad Mailbox",
  "INVALID_DOMAIN": "Ungültige Domain",
  "IMAGES": "Bilder",
  "UNPAID_SUBMISSION": "Diese Submission wurde noch nicht bearbeitet.",
  "TRY_BETA_EXPORT": "Testen Sie die neue Beta-Version",
  "EXPORT_ALL_COLUMNS": "Alle Spalten exportieren",
  "SELECTED_COLUMNS": "Spalten auswählen",
  "EXPORT_OPTIONS_TIMEZONE": "Zeitzone",
  "EXPORT_SUCCESS_TITLE": "Export erfolgreich gestartet",
  "EXPORT_SUCCESS_MESSAGE": "Ihr Export wurde gestartet. Dieser wird im Anschluss an <b>{{email}}</b> versendet. Je nach Umfang kann der Export bis zu mehreren Stunden dauern.",
  "EXPORT_SERVICE_UNAVAILABLE": "Export ist derzeit nicht verfügbar. Bitte versuchen Sie es später erneut",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "Exporte einschließlich Dateien sind auf {{limit}} Submissions beschränkt",
  "MORE_INFORMATION": "Mehr Informationen",
  "SELECTABLE_REORDARABLE_DONE": "Erledigt",
  "ACCEPT_CUSTOMER_INVITE": "Möchten Sie die Einladung von '{{customer}}' akzeptieren?",
  "ACCEPT_BUTTON": "Akzeptieren",
  "ACTIVATION_FORM_TITLE": "Kontoaktivierung",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Kundeninformation",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Name",
  "ACTIVATION_USER_INFORMATION_TITLE": "Benutzerinformation",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Vorname",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Nachname",
  "ACTIVATION_PASSWORD": "Passwort",
  "ACTIVATION_PASSWORD_CONFIRM": "Passwort Bestätigung",
  "ACTIVATION_PASSWORD_MISMATCH": "Das angegebene Passwort ist ungültig. Bitte gehen Sie sicher, dass Ihr Passwort die Anforderungen einer Bestätigung erfüllt.",
  "ACTIVATION_FORM_TOC": "Allgemeine Geschäftsbedingungen",
  "ACTIVATION_FORM_TOC_MESSAGE": "Ich gebe meine Zustimmung zu den",
  "ACTIVATION_SUBMIT_BUTTON": "Aktivieren",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Token eingeben",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Ihre Einladung ist abgelaufen oder ungültig",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Kontaktieren Sie Ihren Administrator, um eine neue Einladung zu erhalten",
  "ACTIVATION_CHECK_TOKEN": "Token überprüfen",
  "ACTIVATION_SUCCESS": "Geschafft! Sie haben Ihr Konto erfolgreich aktiviert!",
  "LICENSE_AGREEMENT": "Lizenzvereinbarung",
  "PRIVACY_POLICY": "Datenschutzerklärung",
  "SECURITY_STATEMENT": "Sicherheitserklärung",
  "LOGIN_ON_MOBILE_APP": "Melden Sie sich in der mobilen App an:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Laden Sie unsere App auf Ihr Android- oder iOS-Gerät herunter, um direkt loslegen zu können.",
  "OR": "oder",
  "SCAN_QR_CODE": "QR-Code scannen",
  "NO_SMARTPHONE": "Gerade kein Smartphone zur Hand?",
  "OPEN_IN_WEBCLIENT": "Im Browser öffnen",
  "ICON_PICKER_SELECT": "Symbol auswählen",
  "ICON_PICKER_MODAL_TITLE": "Symbol-Picker",
  "MORE_GRID_VIEW": "Ansicht",
  "MORE_GRID_LOAD_MORE": "Mehr laden",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Details",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Details",
  "DATE_FILTER_FROM_LABEL": "Von",
  "DATE_FILTER_TO_LABEL": "An",
  "DATE_FILTER_PLACEHOLDER": "Datum auswählen",
  "NO_RESULTS_FOUND": "Keine Ergebnisse gefunden",
  "CANVAS_NOT_SUPPORTED_TITLE": "Unterschrift wird nicht unterstützt",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "Sie können diese Funktionalität nicht innerhalb der Plattform verwenden. Diese wird nur in der mobilen App und im Webclient unterstützt.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Foto wird nicht unterstützt",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "Sie können diese Funktionalität nicht innerhalb der Plattform verwenden. Diese wird nur in der mobilen App und im Webclient unterstützt.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Fotomediathek wird nicht unterstützt",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "Sie können diese Funktionalität nicht innerhalb der Plattform verwenden. Diese wird nur in der mobilen App und im Webclient unterstützt.",
  "BARCODE_SCAN_FAILURE": "Barcode-Scanner wird nicht unterstützt",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "Sie können diese Funktionalität nicht innerhalb der Plattform verwenden. Diese wird nur in der mobilen App unterstützt.",
  "DATE_MODAL_TITLE": "Picker",
  "WIDGET_DETAIL_LINK": "Das Subformular kann in anderen Subform-Widgets wiederverwendet werden",
  "WIDGET_DETAIL_UNLINK": "Das Subform-Widget ist mit einem anderen Subformular verknüpft",
  "WIDGET_DETAIL_NEW_TITLE": "Neues Subformular",
  "WIDGET_DETAIL_REMOVE_TITLE": "Subformular entfernen",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Existierendes Subformular auswählen",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Ihre Änderungen werden auf alle Widgets übertragen, welche dieses Subformular verwenden",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Wird in {{value}} Widgets verwendet",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "Ungenutzt",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Sind Sie sicher, dass Sie dieses Subformular entfernen möchten?",
  "COLLECTION": "Ordner",
  "DESCRIPTION": "Beschreibung",
  "LOGIN": "Anmelden",
  "LOGIN_USERNAME": "Benutzername",
  "LOGIN_PASSWORD": "Passwort",
  "LOGIN_SIGN_IN_BUTTON": "Einloggen",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Ungültige Benutzername-/Passwort-Kombination",
  "LOGIN_FORGOT_PASSWORD_LINK": "Passwort vergessen",
  "LOGIN_NO_ACCOUNT_MESSAGE": "Noch kein Benutzerkonto? Wählen Sie Ihren Plan hier!",
  "LOGOUT_SUCCESSFUL_TITLE": "Abgemeldet",
  "LOGOUT_SUCCESSFUL_MESSAGE": "Sie sind jetzt abgemeldet",
  "LOGOUT_SUCCESSFUL_ACTION": "Wieder anmelden",
  "FORGOT_PASSWORD_TITLE": "Passwort vergessen",
  "FORGOT_PASSWORD_EMAIL": "E-Mail-Adresse",
  "FORGOT_PASSWORD_USERNAME": "Benutzername",
  "FORGOT_PASSWORD_SUBMIT": "Übermitteln",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Ungültige E-Mail-Adresse ",
  "FORGOT_PASSWORD_EMAIL_SENT": "Ihr Passwort wurde zurückgesetzt. Folgen Sie dem Link in der E-Mail, welche wir an Ihre Adresse gesendet haben, um ein neues Passwort zu erstellen.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Zurück zur Anmeldung",
  "ACTIVATE_USER_TITLE": "Nutzer aktivieren",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Neues Passwort",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Neues Passwort (Verifizierung)",
  "MENU_HELP": "Help Center",
  "MENU_REGIONS": "Regionen",
  "MENU_CUSTOMERS": "Kunden",
  "MENU_LOGOUT": "Ausloggen",
  "MENU_USERS": "Nutzer",
  "MENU_REGISTRATIONS": "Submissions",
  "MENU_FORMS": "Formulare",
  "MENU_TEMPLATES": "Vorlagen",
  "MENU_SETTINGS": "Einstellungen",
  "MENU_API_DOC": "Dokumente",
  "MENU_DOCUMENTATION": "E-Mail-Dokumentation",
  "MENU_SUPPORT": "Support/Kundenberatung",
  "MENU_PLUGINS": "Plug-In’s",
  "MENU_TRANSACTIONS": "Transaktionen",
  "MENU_BILLING": "Abrechnung",
  "MENU_FINANCIAL": "Finanzielles",
  "MENU_CONTACT": "Kontakt",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Kunde auswählen",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Datenquellen",
  "MENU_PROFILE": "Profil",
  "MENU_ONBOARDING": "Anmeldeseite",
  "MENU_QUEUE": "Ausstehend",
  "PROFILE_INFO": "Informationen",
  "PROFILE_EMAIL_VALID": "E-Mail-Adresse wurde erfolgreich bestätigt",
  "PROFILE_EMAIL_NOT_VALIDATED": "E-Mail-Adresse wurde noch nicht bestätigt.",
  "SUBMITTED": "Eingereicht",
  "SENT": "Gesendet",
  "ACCEPTED": "Akzeptiert",
  "REVOKED": "Widerrufen",
  "REJECTED": "Abgelehnt",
  "CONFIRM": "Bestätigen",
  "AWAITING_PACKAGE_UPLOAD": "In Erwartung auf Paket-Upload",
  "RESELLER": "Reseller",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Einstellungen",
  "CUSTOMER_APPLICATION_OPERATIONS": "Ordner-Handhabung",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "Ordner können nur entfernt werden, wenn diese leer sind",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Gesamte Anzahl gesendeter Formulare",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Anzahl gesendeter Formulare",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Suche…",
  "ADD_FORM_PANEL_TITLE": "Formular hinzufügen",
  "ADD_FORM_PANEL_TITLE_LABEL": "Titel des Formulars",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Titel des Formulars",
  "ADD_FORM_PANEL_SAVE": "Speichern",
  "ADD_FORM_PANEL_ACTIONS_CREATE_WITH_BETA": "Erstellen (Beta)",
  "ADD_TEMPLATE": "Vorlage hinzufügen",
  "MARK_GLOBAL": "Als global markieren",
  "UNMARK_GLOBAL": "Markierung als global aufheben",
  "MARK_GLOBAL_TOOLTIP": "Nur als MoreApp-Admin möglich. Als global markieren, um es auf dem Bildschirm “Neues Formular” öffentlich verfügbar zu machen",
  "ADD_APPLICATION_PANEL_TITLE": "Ordner hinzufügen",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Name des Ordners",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Name",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Formular löschen",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Sind Sie sicher, dass Sie das Formular löschen möchten?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Formular kopieren",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Wählen einen Ordner, um das Formular zu kopieren",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Ordner",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Ordner",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Name der Kopie",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Veröffentlichen",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Nutzer verwalten",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Kopieren",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Löschen",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Ordner kopieren",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Kunde",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Ordner veröffentlichen",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Sind Sie sicher, dass Sie den Ordner veröffentlichen wollen?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "Ordner wurde erfolgreich veröffentlicht!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Ordner konnte aus unbekannten Gründen nicht veröffentlicht werden. Bitte überprüfen Sie die Anwendungsdefinitionen hinsichtlich fehlender Eigenschaften. Falls sich das Problem nicht beheben lässt, kontaktieren Sie bitte Ihren Partner.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Ordner konnte nicht veröffentlicht werden. Bitte beheben Sie folgende Probleme und versuchen Sie es dann erneut:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "Ihr vorangegangener Versuch wies folgende Fehler auf:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Vorherige Fehler anzeigen",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Veröffentlichen",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Auf Marketplace teilen",
  "INVALID_APPLICATION_HEADER": "Anwendungs-Probleme:",
  "INVALID_FORM_HEADER": "Formularprobleme:",
  "INVALID_APPLICATION_START_PAGE": "Der Ordner muss eine Startseite aufweisen",
  "INVALID_APPLICATION_NAME": "Der Ordner muss einen Namen besitzen",
  "INVALID_APPLICATION_THEME": "Dem Ordner muss ein Thema zugewiesen werden",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Die Sucheinstellung ‘Item Markup’ ist ungültig",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "<code>{{fieldName}}</code> weist ungültige Eigenschaften auf",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "<code>{{fieldName}}</code> weist eine ungültige Eigenschaft auf <code>{{property}}</code>",
  "INVALID_VIEW_ITEM_DATA_NAME": "<code>{{fieldName}}</code> hat einen ungültigen Datennamen",
  "INVALID_VIEW_ITEM_WIDGET_UID": "<code>{{fieldName}}</code> Widget wurde nicht installiert",
  "INVALID_VIEW_ITEM_VIEW_ID": "<code>{{fieldName}}</code> bezieht sich auf keine bekannte Ansicht",
  "INVALID_VIEW_ITEM_MAIL": "Ungültiger Platzhalter in E-Mail-Vorlage nahe <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Ungültige dynamische Empfänger in E-Mail-Vorlage nahe <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Ungültiger Empfänger in E-Mail <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Ungültige Regel mit Namen <code>{{ fieldName }}</code>",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Nutzerverwaltung",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Nutzer, die Zugriff haben",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Keine Nutzer",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "Nutzer",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "Nutzer",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Gelöscht",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Gruppen, die Zugriff haben",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "Keine Gruppen",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Legen Sie fest, wer auf dieses Formular zugreifen und es verwenden kann",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Definieren Sie, wer Formulare zu diesem Ordner hinzufügen kann",
  "CONTACT": "Kontakt",
  "ADDITIONAL_FEES_APPLY": "Es fallen zusätzliche Gebühren an",
  "CUSTOMER_CONTACT_VAT_NUMBER": "Umsatzsteuer-ID",
  "CUSTOMER_CONTACT_COC_NUMBER": "Registernummer",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Kundensupport",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Wir haben Ihre Benachrichtigung erhalten und werden entsprechende Maßnahmen ergreifen.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Ihre Benachrichtigung konnte nicht versendet werden. Kontaktieren Sie uns gerne hinsichtlich dieses Problems.",
  "BILLING_INFO": "Unternehmensinformationen",
  "CONTACT_INFO": "Kontaktinformationen",
  "INVOICES": "Rechnungen",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Konto",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Kunden auswählen",
  "COLLECTION_REMOVE_MODAL_TITLE": "Ordner entfernen",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diesen Ordner löschen möchten? Dies kann nicht rückgängig gemacht werden",
  "COPY_FORM_MODAL_TITLE": "Formular kopieren",
  "COPY_FORM_MODAL_MESSAGE": "Wählen Sie einen Ordner aus, in welchen das Formular kopiert werden soll und ändern Sie gegebenenfalls den Namen des Formulars",
  "COPY_FORM_MODAL_NAME_LABEL": "Name",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Zielordner",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Wählen Sie den Ordner aus, in welchen das Formular kopiert werden soll",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Optional können Sie für die Kopie einen anderen Namen wählen.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "Das Konto, zu dem dieses Formular kopiert wird.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "Die Kopie wird in diesem Ordner hinterlegt.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Ansicht löschen",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diese Ansicht löschen wollen?",
  "SUPPORT_TITLE": "Kundensupport",
  "SUPPORT_QUESTION": "Noch etwas nicht ganz korrekt?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Haben Sie einen Bug entdeckt, vermissen eine Funktion oder haben eine Frage? Dann senden Sie uns einfach eine E-Mail unter <a href='mailto:support@moreapp.com'>support@moreapp.com</a>. Bitte versuchen Sie Ihr Problem möglichst schrittweise wiederzugeben, um eine entsprechende Reproduktion zu ermöglichen. Außerdem um welchen Nutzer bzw. um welche Funktion es sich genau handelt.",
  "SUPPORT_QUESTION_SUBTITLE": "Bitte füllen Sie das untere Formular aus, ums uns zu informieren!",
  "SUPPORT_APPLICATION_QUESTION": "Welchen Ordner verwenden Sie?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Name/ID des Ordners",
  "SUPPORT_FORM_QUESTION": "Welches Formular verwenden Sie?",
  "SUPPORT_FORM_PLACEHOLDER": "Formularname",
  "SUPPORT_DEVICE_QUESTION": "Welche Art von Gerät verwenden Sie?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Typ-/System-Version",
  "SUPPORT_DESCRIPTION_QUESTION": "Haben Sie Verbesserungsvorschläge, die uns helfen können?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Beschreibung",
  "SUPPORT_EMAIL_QUESTION": "Falls nötig, wie dürfen wir Sie kontaktieren?",
  "SUPPORT_EMAIL_PLACEHOLDER": "E-Mail-Adresse",
  "SUPPORT_SUCCESS_TITLE": "Vielen Dank!",
  "SUPPORT_SUCCESS_MESSAGE": "Wir haben Ihre Benachrichtigung erhalten und werden entsprechende Maßnahmen ergreifen.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Ordner bearbeiten: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Einstellungen",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Name",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Geben Sie einen Namen für den Ordner ein",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Startseite",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Design",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Bild",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Ansichten",
  "APPLICATION_EDITOR_TO_LABEL": "An",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "In den einfachen Modus wechseln",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Sind Sie sicher, dass Sie auf den einfachen Modus zurückkehren möchten? Dies hat zur Folge, dass Sie Ihr benutzerdefiniertes PDF-Layout verwerfen und zu Ihrem Standard PDF-Layout zurückkehren.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Name",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Durch Kommas getrennte Liste der Empfänger ",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Dynamische Empfänger",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Betreff",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "Von",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Name des Absenders",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Antwort an",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Senden Sie Antworten an diese E-Mail-Adresse",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "Aus der E-Mail des Absenders",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Beigefügte Pins zurücksetzen",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Anhang zurücksetzen",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Sind Sie sicher, dass Sie das PDF zurücksetzen wollen?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Dies wird Ihre Modifikationen überschreiben und generiert den Standard PDF-Anhang Ihrer aktuellen Formularkonfiguration neu.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Alle Bilder hinzufügen",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "PDF mit folgendem Namen anhängen: ",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Kopf- und Fußzeile in PDF verwenden",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Mit Logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Unausgefüllte Felder ausblenden",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Verwenden Sie eine horizontale Ausrichtung für das Subformular",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Platzhalter",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Karte anzeigen",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Hinterlegen Sie eine oder mehrere E-Mail-Adressen, an welche Sie das Formular senden möchten und trennen diese durch Kommas. <br><br><b>Beispiel</b><br>konrad@beispiel.com, julian@beispiel.com",
  "${sys.user}": "Nutzer",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Veröffentlichung fehlgeschlagen",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "Das Formular wurde gespeichert, konnte jedoch aufgrund einiger Fehler nicht veröffentlicht werden.",
  "VIEW_EDITOR_FORM": "Formular",
  "VIEW_EDITOR_EMAIL": "E-Mail-Adresse",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Senden Sie eine E-Mail mit PDF-Anhang für jede eingereichte Submission",
  "VIEW_EDITOR_RULES": "Regeln",
  "VIEW_EDITOR_RULES_SUBTITLE": "Blenden Sie Felder unter bestimmten Bedingungen ein- oder aus",
  "VIEW_EDITOR_SETTINGS": "Einstellungen",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Ändern Sie das Erscheinungsbild und die Verhaltensweise Ihres Formulars",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Ermöglicht es Nutzern in der App nach bereits eingereichten Submissions zu suchen und diese als Grundlage für eine neue Submission zu verwenden",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integrationen",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Lösen Sie für jede eingereichte Submission eine Aktion in einem externen System aus",
  "VIEW_EDITOR_PROPERTIES": "Eigenschaften",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "Der Datenname ist ungültig",
  "VIEW_EDITOR_DATA_NAME": "Datenname",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Dieser Datenname wird dupliziert, was zu unvorhersehbaren Änderungen führen kann",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Regel hinzufügen",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Integration hinzufügen",
  "HOOK_SELECT_TITLE": "Integration auswählen",
  "INTEGRATIONS_SUBTITLE": "Alternativ können Sie <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, unsere <a href='https ://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a> oder <a href='https://zapier.com/apps/moreapp/integrations ' target='_blank'>Zapier</a> verwenden",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "Für diese Integration existieren keine Konfigurationsmöglichkeiten",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "Die Konfiguration wurde erfolgreich bestätigt",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Integration entfernen",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diese Integration löschen wollen? Dies kann nicht rückgängig gemacht werden.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Derzeit sind keine Integrationen zum Hinzufügen verfügbar",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "Die Konfiguration wurde gespeichert, konnte allerdings zum jetzigen Zeitpunkt nicht bestätigt werden. Sollte dieses Problem weiterhin bestehen, wenden Sie sich bitte an den Verantwortlichen der betreffenden Integration: {{author}}.",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Noch mehr Integrationen",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Diese Integration ist seit dem {{lastAvailableDate|date:'medium'}} nicht mehr verfügbar. Sie können die Konfiguration zwar speichern, jedoch wird diese nicht bestätigt. Außerdem wird die Integration erst ausgeführt, wenn diese wieder verfügbar ist.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Bezeichnung",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Bedingungen",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Andere Typen",
  "VIEW_EDITOR_RULE_USER": "Benutzername",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Wählen Sie ein Feld",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Wählen Sie eine Bedingung",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Wählen Sie einen konkreten Wert",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Beginnt mit",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Endet mit",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Enthält",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Größer als",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Kleiner als",
  "VIEW_EDITOR_RULE_CONDITION_IS": "Ist genau",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Hat einen Wert",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "Wert",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "Wert",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Aktuelle Formularfelder",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Weitere Formularfelder",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Aktionen",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Wenn",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "Bedingungen passen, realisiere folgende",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": "Aktion auswählen",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Einstellungen",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Name",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Beschreibung",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Symbol",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "In App ausblenden",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Stammansicht",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Dieses Formular sollte als Stammansicht angezeigt werden",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Beschreibung in der App",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Speichermodus",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Speichern und Schließen (Standard)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Speichern und Schließen & Speichern und Neues",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "Kein Speichern (schreibgeschützt)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Sucheinstellungen",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Aktivieren",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Nur eingereichte Submissions des aktuellen Nutzers anzeigen",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filter aktivieren",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Felder",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Feld auswählen",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formular",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Item-Markup",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Verfügbare Parameter:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "Dieser Wert existiert nicht",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Wert",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Wert im PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Wert",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Hinzufügen",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Bilder als Kennzeichnungen verwenden",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Bild auswählen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Bild auswählen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Symbol auswählen",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Standard-Pinsymbole",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Standardsymbol verwenden",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Zu Favoriten hinzufügen",
  "VIEW_EDITOR_ADVANCED_MODE": "Erweiterter Modus",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Basic",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favoriten",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "Keine Favoriten festgelegt.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Favorisiertes Widget hinzufügen",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Favoriten-Name",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Name",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Originalwert",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Favorisierter Wert",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "Es liegen keine Änderungen am Widget vor.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "E-Mail-Adresse hinzufügen",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Widget löschen",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Sind Sie sicher, dass Sie dieses favorisierte/Lieblings-Widget löschen wollen?",
  "ERROR_SOMETHING_WENT_WRONG": "Etwas ist schief gelaufen.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Bitte versuchen Sie es später erneut. Falls dieses Problem weiterhin bestehen sollte, kontaktieren Sie uns bitte.",
  "ACCESS_DENIED_MESSAGE": "Zugriff verweigert",
  "ACCESS_DENIED_DESCRIPTION": "Diese Plattform ist für Admins. Kontaktieren Sie Ihren Kontoinhaber oder loggen Sie sich in der <a href='{{webclientUrl}}'>Web App</a> ein, um Formulare auszufüllen.",
  "ACCESS_DENIED_LOGOUT": "Abmelden",
  "ACCESS_DENIED_TO_LOGIN": "Zur Anmeldung gehen",
  "PAGE_NOT_FOUND_TITLE": "Seite nicht gefunden",
  "PAGE_NOT_FOUND_DESCRIPTION": "Die von Ihnen gesuchte Seite konnte nicht gefunden werden",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Zurück zur Übersicht in der Plattform",
  "CUSTOMER_RESOURCES_TITLE": "Ressourcen",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Art",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Galerie",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Name",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Als Datei hochladen",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Datei",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Von URL hochladen",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Ressource entfernen",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diese Ressource löschen wollen?",
  "RESOURCES_UPLOAD_FAILURE": "Fehler beim Hochladen",
  "RESOURCES_UPLOAD_INVALID_FILE": "Die ausgewählte Datei ist kein Bild",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "Die ausgewählte Datei überschreitet das Limit für Dateien von 5MB",
  "REGISTRATIONS": "Submissions",
  "START": "Beginnen",
  "CONFIGURE": "Konfigurieren",
  "UPGRADE": "Upgrade",
  "STEP": "Schritt",
  "CREATED": "Erstellt",
  "CREATE": "Erstellen",
  "READ": "Lesen",
  "DELETE": "Löschen",
  "REMOVE": "Entfernen",
  "SUBMIT": "Einreichen",
  "CANCEL": "Abbrechen",
  "CLOSE": "Schließen",
  "SEND": "Senden",
  "SAVE": "Speichern",
  "RENAME": "Umbenennen",
  "RENAME_LINKED_SUBFORM_TITLE": "Verknüpftes Unterformular umbenennen",
  "SAVE_PAYMENT_METHOD": "Zahlungsmethode bestätigen und verwenden",
  "SELECTED_PAYMENT_METHOD": "Zahlungsmethode auswählen",
  "SELECT_OTHER_PAYMENT_METHOD": "Andere Zahlungsmethode verwenden",
  "LOADING": "Wird geladen",
  "SAVE_AND_CLOSE": "Speichern & Schließen",
  "SAVE_AND_PUBLISH": "Speichern & Veröffentlichen",
  "VALIDATE": "Validieren",
  "EDIT": "Bearbeiten",
  "UNDO": "Widerrufen",
  "BACK": "Zurück",
  "NEXT": "Nächste",
  "PREVIOUS": "Vorherige",
  "CLEAR": "Leeren",
  "SELECT": "Auswählen",
  "UPLOAD": "Hochladen",
  "BUSY_UPLOADING": "Beschäftigtes Hochladen",
  "UPDATE": "Aktualisierung",
  "ADD": "Hinzufügen",
  "COPY": "Kopieren",
  "SHARE": "Teilen",
  "ACTIONS": "Aktionen",
  "THEME": "Design",
  "ON": "An",
  "OFF": "Aus",
  "OPTIONS": "Optionen",
  "INSTALLED": "Installiert",
  "EMPTY": "Leer",
  "QUARTER_HOUR": "Alle 15 Minuten",
  "HALF_HOUR": "Alle 30 Minuten",
  "DAILY": "Täglich",
  "WEEKLY": "Wöchentlich",
  "HOURLY": "Stündlich",
  "API": "API",
  "NAME": "Name",
  "EMAIL": "E-Mail-Adresse",
  "DATE": "Datum",
  "DUE_DATE": "Fälligkeitsdatum",
  "HOOKS": "Integrationen",
  "NONE": "Keine",
  "NEVER": "Niemals",
  "USER": "Nutzer",
  "GROUP": "Gruppe",
  "VIA_GROUP": "via Gruppe(n)",
  "BETA": "beta",
  "LOCKED": "Gesperrt",
  "URL": "URL",
  "FILE": "Datei",
  "YES": "Ja",
  "NO": "Nein",
  "DEFAULT": "Standard",
  "SEARCH": "Suche",
  "DETAILS": "Details",
  "UNSAVED_CHANGES_TITLE": "Ungesicherte Änderungen",
  "UNAVAILABLE": "Nicht verfügbar",
  "VERSION": "Version",
  "PRICE": "Preis",
  "FREE": "Frei",
  "ADDRESS": "Adresse",
  "ADDRESS_LINE_1": "Adresse",
  "ADDRESS_LINE_1_PLACEHOLDER": "123 Haupstraße",
  "CITY": "Stadt",
  "COUNTRY": "Land",
  "ZIP_CODE": "Postleitzahl",
  "OK": "Einverstanden",
  "INTERNAL_ERROR": "Interner Fehler",
  "OPEN": "Öffnen",
  "STALLED": "Verzögert",
  "ERROR": "Fehler",
  "SUCCESS": "Erfolg",
  "RETRY": "Wiederholen",
  "SKIP": "Überspringen",
  "IN_PROGRESS": "In Bearbeitung",
  "COMPLETED": "Abgeschlossen",
  "DECLINED": "Abgelehnt",
  "BLOCKED": "Eine der Integrationen ist fehlgeschlagen",
  "NOT_VALIDATED": "Nicht validiert",
  "INVALID": "Ungültig",
  "VALID": "Gültig",
  "VERIFIED": "Geprüft",
  "UNVERIFIED": "Ungeprüft",
  "TYPE": "Typ",
  "VALUE": "Wert",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Erweitert",
  "PREVIEW": "Vorschau",
  "BY": "Von",
  "UNTIL": "Bis",
  "COUPON": "Gutschein",
  "COUPON_ADD_ERROR": "Ungültiger Gutschein",
  "DISCOUNT": "Rabatt",
  "COUPON_REMOVE_TITLE": "Gutschein entfernen",
  "COUPON_REMOVE_MESSAGE": "Sind Sie sicher, dass Sie den Gutschein entfernen möchten?",
  "PERMISSIONS": "Berechtigungen",
  "THIS_MIGHT_TAKE_SOME_TIME": "Dies kann etwas Zeit in Anspruch nehmen",
  "FIELD_ERROR_REQUIRED": "Dieses Feld muss ausgefüllt sein",
  "FIELD_ERROR_MIN_LENGTH": "Feld soll mindestens {{minLength}} Zeichen haben",
  "FIELD_ERROR_MAX_LENGTH": "Feld darf maximal {{maxLength}} Zeichen haben ",
  "FIELD_ERROR_MIN": "Wert ist niedriger als der definierte Minimalwert {{min}} ",
  "FIELD_ERROR_MAX": "Wert ist höher als der definierte Maximalwert {{max}} ",
  "FIELD_ERROR_EMAIL": "Wert entspricht keiner gültigen E-Mail-Adresse",
  "BILLING_BANK_ACCOUNT_NUMBER": "Bankverbindung (IBAN)",
  "BILLING_VAT_NUMBER": "Umsatzsteuer-IdNr.",
  "BILLING_VAT_NUMBER_CHECKING": "Überprüfung",
  "BILLING_VAT_NUMBER_VALID": "Gültig",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "prozentuale MwSt.",
  "BILLING_VAT_NUMBER_INVALID": "Ungültig",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "Länderangaben stimmen nicht überein",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "Das Land aus Ihrer Umsatzsteuer-IdNr. stimmt nicht mit dem von Ihnen angegebenen Land überein. Bitte überarbeiten Sie diese Angaben, damit Ihre Bestellung verarbeitet werden kann.",
  "BILLING_COC_NUMBER": "Handelskammer",
  "BILLING_EMAIL": "Rechnungsmail",
  "VAT_DISCLAIMER": "Die angegeben Preise verstehen sich ohne MwSt. Der zusätzliche Mehrwertsteuerbetrag wird nach dem Zahlungsvorgang angezeigt und auf Ihren Rechnungen ausgewiesen.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Ich möchte den Newsletter erhalten",
  "TERMS_OF_CONDITION_DOWNLOAD": "Allgemeine Geschäftsbedingungen herunterladen",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Sub-Formular",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Neuer Formularname",
  "FORM_BUILDER_SUB_FORM_NAME": "Formularname",
  "TOOLTIP_ADD_APPLICATION": "Ein Ordner beinhaltet Ihre Formulare.<br><br> Jeder Ordner hat seine eigenen Einstellungen, Nutzer-Berechtigungen und kann einzeln veröffentlicht werden.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Dieser Name wird in der App angezeigt.",
  "TOOLTIP_ADD_FORM": "Fügen Sie ein Formular hinzu, um es in der App ausfüllen und einreichen zu können.<b><br>Jedes Formular verfügt über eine Reihe an Feldern, Regeln und spezifischen Einstellungen.",
  "TOOLTIP_ADD_FORM_NAME": "Dieser Name wird in der App angezeigt.",
  "TOOLTIP_COPY_FORM_NAME": "Alternativ können Sie für die Kopie einen abweichenden Namen verwenden.",
  "TOOLTIP_COPY_FORM_DESTINATION": "Die Kopie wird in diesem Ordner hinterlegt.",
  "TOOLTIP_DELETE_FORM": "<b>Diese Aktion ist unwiderruflich</b>.<b>Beachten Sie, dass eingereichte Submissions beibehalten werden.",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Greifen Sie ein Formular</a>, um die Reihenfolge Ihrer Formulare im Ordner zu ändern.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "Ordner wurde archiviert. Um dies rückgängig zu machen, klicken Sie auf das <a>Widerrufen</a>-Feld.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "Ordner wurde erstellt, aber noch nicht veröffentlicht.<br><br>Wenn dieser Ordner in der App angezeigt werden soll, müssen Sie diesen vorerst <a>Veröffentlichen</a>.",
  "TOOLTIP_APP_MANAGE_USERS": "Definieren Sie, welchen Gruppen und/oder Nutzern dieser Ordner, nach Ihrer Anmeldung in der App angezeigt werden soll.",
  "TOOLTIP_VIEW_SETTING_NAME": "Dieser Name wird in der App angezeigt.",
  "TOOLTIP_VIEW_SETTING_ICON": "Dieses Symbol wird in der App verwendet, wenn das Formular in einer Liste angezeigt wird",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Aktivieren Sie diese Option, um dieses Formular in der Übersicht für <a>Formulare</a> anzuzeigen.",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Wählen Sie einen Speicher-Modus: <br><br><a>Speichern und Schließen (Standardmäßig):</a><br>Ein einfaches<b>Speichern</b> -Feld erscheint, welches das Formular (falls gültig) speichert und schließt.<br><br><a>Speichern und Schließen & Speichern und Neues:</a><br>Sowohl die <b>Speichern</b>als auch die <b>Speichern und Neues</b> -Schaltfläche erscheint, sodass Sie weiterhin Submissions des gleichen Formulars einreichen können.<br><br><a>Kein Speichern: </a><br>Lässt das Formular zu einem Schreibgeschütztem-Formular werden. Es werden keine weiteren Schaltflächen angezeigt.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Definiert wie jede Submission, jeder Entwurf oder Aufgabe des Formulars in der App angezeigt wird. Verwenden Sie Platzhalter für Felder, damit Sie jedes Element erkennen können.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Aktivieren Sie die Suche des Formulars in der App.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Aktivieren Sie diese Option, um ausschließlich nach eingereichten Submissions des aktuell angemeldeten Nutzers zu suchen",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Aktivieren Sie diese Option, um nach bestimmten Feldern in den gefundenen Formularen zu filtern.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Folgende Werte aus dem Ergebnisbericht ausschließen.",
  "TOOLTIP_FORM_RULE_CONDITION": "Definieren Sie eine oder mehrere <a>Bedingungen</a>, die genügen, um <a>Aktionen</a> auszuführen.<br><br>Dadurch können irrelevante oder unzutreffende Felder unter bestimmten Bedingungen übersprungen werden.<br><br><b>Zum Beispiel: </b><br>Ausblenden <a>Partnername</a> wenn, <a>Ehestand</a> auf <a>'unverheiratet'</a> gesetzt wurde",
  "TOOLTIP_FORM_RULE_ACTION": "Geben Sie eine oder mehrere <a>Aktionen</a> an, die ausgeführt werden sollen, wenn alle <a>Bedingungen</a> erfüllt sind.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Name, der als Spaltenbezeichnung in der<a>Submission-Übersicht</a> verwendet wird. Standardmäßig wird dieser Wert automatisch auf Basis des <b>Kennzeichnungstextes</b>ausgefüllt.<br><br><b>Zum Beispiel: </b><br>Wenn Sie die Kennzeichnung auf<a>'Wie alt sind Sie?'</a>festlegen wäre der Dateiname<a>wieAltSindSie</a>. Möglicherweise wollen Sie dies aber in<a>'Alter'</a> ändern.",
  "TOOLTIP_ADD_DATA_SOURCE": "Datenquelle hinzufügen",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Name der Datenquelle",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Wählen Sie eine Excel-Datei, die Sie importieren wollen. Stellen Sie sicher, dass die Excel-Datei entweder im ‘.xlsx’- oder ‘.xls’-Format vorliegt. Abweichende Formate können nicht importiert werden. ",
  "TOOLTIP_HOOK": "Die spezifische Konfiguration dieser Integration in diesem Formular",
  "TOOLTIP_DIRECT_FORM_LINK": "Erstellen Sie eine individuelle URL für Ihr Formular, um es mit anderen zu teilen.<br><br>Unter Verwendung dieser URL können Personen ohne ein Benutzerkonto Ihr Formular ausfüllen.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Teilen Sie Ihr Formular auf dem Marketplace, damit es Dritte als Vorlage verwenden können.",
  "RULE_HIDDEN": "Ausgeblendet",
  "RULE_VISIBLE": "Eingeblendet",
  "RULE_GETS_VALUE": "Bekommt Wert",
  "WIDGET_LICENSE_INVALID": "Ungültige Lizenz",
  "WIDGET_LICENSE_BRAND": "Brand",
  "WIDGET_LICENSE_TYPE": "Typ",
  "WIDGET_LICENSE_POWER": "Energie",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "Erste Aufnahme",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "EU-Schadstoffklasse",
  "SEGMENT": "Branche",
  "SEGMENT_AUTOMOTIVE": "Automobil",
  "SEGMENT_CONSTRUCTION": "Bauwesen",
  "SEGMENT_FACILITY": "Gebäudemanagement",
  "SEGMENT_FINANCIAL": "Finanzbranche",
  "SEGMENT_TRADE_AND_INDUSTRY": "Handel- und Produktion",
  "SEGMENT_GOVERNMENT": "Behörden",
  "SEGMENT_HEALTH_CARE": "Gesundheit",
  "SEGMENT_INSTALLATION": "Installation",
  "SEGMENT_OTHER": "Andere",
  "GENERIC": "Allgemein",
  "FACILITY": "Gebäudemanagement",
  "CONSTRUCTION": "Bauwesen",
  "SALES": "Vertrieb",
  "HR": "Personalwirtschaft",
  "AUTOMOTIVE": "Automobil",
  "SAFETY": "Sicherheit",
  "LOGISTICS": "Logistik",
  "INSTALLATION": "Installation",
  "MAINTENANCE": "Service & Wartung",
  "SECURITY": "Sicherheit",
  "REAL_ESTATE": "Immobilien",
  "HEALTHCARE": "Gesundheit",
  "AGRICULTURE": "Landwirtschaft",
  "INDUSTRY": "Industrie",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Unausreichende Berechtigungen",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Sie haben sich auf der Plattform angemeldet. Auf diesen Abschnitt, können einzig die Konto-Administratoren Ihrer Organisation zugreifen. Wenn Sie dennoch eine Berechtigung hierfür benötigen, wenden Sie sich bitte an den entsprechenden Admin Ihres Unternehmens. Wenn Sie nur Formulare ausfüllen sollen, verwenden Sie die App über Ihr Smartphone oder Tablet.",
  "FORM_SUBMISSION_SERVER_ERROR": "Das Formular konnte nicht eingereicht werden. Dies kann in Verbindung eines Serverproblemen auftreten. Bitte versuchen Sie es später erneut.",
  "UNKNOWN_ERROR": "Ein unbekannter Fehler ist aufgetreten.",
  "ALL": "alle",
  "ANY": "beliebige",
  "GLOBAL_ERRORS_TITLE": "Validierungsfehler",
  "USE_TEMPLATE": "Vorlage verwenden",
  "LANGUAGE_EN": "Englisch",
  "LANGUAGE_NL": "Niederländisch",
  "LANGUAGE_DE": "Deutsch",
  "LANGUAGE_ES": "Spanisch",
  "LANGUAGE_RU": "Russisch",
  "LANGUAGE_PT": "Portugiesisch",
  "LANGUAGE_FR": "Französisch",
  "LANGUAGE_IT": "Italienisch",
  "LANGUAGE_CA": "Katalanisch",
  "UNSAVED_CHANGES": "Es existieren nicht gespeicherte Änderungen, welche verworfen werden, wenn Sie Fortfahren",
  "CHANGE_FILE": "Datei ändern",
  "AUTHENTICATED": "Authentifiziert",
  "LANGUAGE": "Sprache",
  "TAGS": "Tags",
  "SELECT_TAGS": "Tags auswählen",
  "SELECT_ALL": "Alle auswählen",
  "SEARCH_TEMPLATES": "Vorlagen suchen",
  "CUSTOMER": "Kunde",
  "PARTNER": "Partner",
  "CUSTOM": "Benutzerdefiniert",
  "AMOUNT": "Menge",
  "PHONE": "Telefonnummer",
  "REGION": "Region",
  "STATUS": "Status",
  "ACTIVE": "Aktiv",
  "INACTIVE": "Inaktiv",
  "DISABLED": "Gesperrt",
  "ACCOUNT": "Konto",
  "DOCUMENTATION": "Dokumentation",
  "COMPANY_NAME": "Firmenname",
  "COMPANY": "Firma",
  "SHOW_MORE": "Mehr anzeigen",
  "WEBSITE": "Webseite",
  "CLICK_HERE": "Hier klicken",
  "MAIL_NOTIFICATION": "E-Mail-Benachrichtigung",
  "CHECKED": "Abgehakt",
  "UNCHECKED": "Unabgehakt",
  "ACCOUNT_NAME": "Kontoname",
  "ACCOUNT_NAME_PLACEHOLDER": "Z.B. Unternehmens GmbH & Co. KG oder Max Mustermann",
  "FEATURE_HOOKS": "Integrationen",
  "FEATURE_HOOKS_MESSAGE": "<h4>Integrationen werden zur Verarbeitung gesammelter Daten verwendet.</h4><p>Gestalten Sie einen Workflow, indem Sie Dateien an OneDrive, Google Sheets oder Word senden.</p>Diese Funktion ist ab dem <b>Zweig</b> Plan verfügbar.</p>",
  "FEATURE_MANUAL_DATASOURCES": "Datenimport",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importieren Sie Daten mithilfe von Excel, Google Sheets oder einer URL (JSON).</h4><p>Diese Funktion ist ab dem <b>Blatt</b> Plan verfügbar.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Automatischer Datenimport",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importieren Sie Daten automatisch, indem Sie Ihrem Google Sheet oder Ihrer URL einen neuen Datensatz hinzufügen. </h4><p>Diese Funktion ist ab dem <b>Zweig</b> Plan verfügbar.</p>",
  "FEATUER_ADVANCED_PDF": "Benutzerdefiniertes PDF",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Gestalten Sie Ihren PDF-Bericht mithilfe von HTML.</h4><p>Diese Funktion ist ab dem <b>Zweig</b> Plan verfügbar.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Erweiterte Benutzerverwaltung",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Verwalten Sie Nutzer- und Gruppen-Berechtigungen in der Plattform</h4><p>Diese Funktion ist ab dem <b>Baum</b> Plan verfügbar.</p>",
  "FEATURE_PDF_LOGO": "Ihr Logo auf PDFs",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Ersetzen Sie das MoreApp-Logo durch Ihr eigenes.</h4><p>Diese Funktion ist ab dem <b>Blatt</b> Plan verfügbar.</p>",
  "FEATURE_MULTIPLE_EMAIL": "Mehrere E-Mails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Fügen Sie Ihrem Formular mehrere E-Mail-Adressen hinzu.</h4><p>Diese Funktion ist ab dem <b>Blatt</b> Plan verfügbar.</p>",
  "FEATURE_WIDGETS": "Pro-Widgets",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Verwenden Sie weitere Pro-Widgets wie das Catalogue-, Calculation- und Rating-Widget.</h4><p>Diese Funktion ist ab dem <b>Blatt</b> Plan verfügbar.</p>",
  "FEATURE_SEARCH": "Suchfunktion in App",
  "FEATURE_SEARCH_MESSAGE": "<h4>Durchsuchen Sie eingereichte Formulare mit der Suchfunktion.</h4><p>Diese Funktion ermöglicht es Ihnen, zuvor eingereichte Submissions aufzurufen und direkt für eine neue Submission zu verwenden.</p><p>Diese Funktion ist ab dem <b>Blatt</b> Plan verfügbar.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Benutzerdefinierte Vorlagen",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Erstellen Sie Ihre eigenen benutzerdefinierten Vorlagen, um bei der Erstellung neuer Formulare Zeit zu sparen.</h4><p>Diese Funktion ist ab dem <b>Baum</b> Plan verfügbar.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Verwenden Sie Webhooks, um bestimmte Ereignisse in Ihrem Konto zu kontrollieren.</h4><p>Bei jedem Ereignis senden wir eine Mitteilung an Ihre konfigurierte URL, damit Sie eine entsprechende Aktion ausführen können. So können komplexe Arbeitsabläufe implementiert werden.</p><p><b>Beispiel</b>: Konfigurieren Sie einen Webhook, sodass dieser bei jedem eingereichten Formular automatisch ausgelöst wird. Und die hierbei implementierten Daten direkt in Ihrer Datenbank speichert.</p><br><p>Diese Funktion ist ab dem <b>Zweig</b> Plan verfügbar.</p>",
  "SWITCH_PLAN_CTA": "Neuen Plan auswählen",
  "SWITCH_PLAN_CONTACT_OWNER": "Kontaktieren Sie Ihren Kontoinhaber",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Fordern Sie ein Upgrade Ihres MoreApp-Kontos an, um die Funktion \"{{feature}}\" verwenden zu können",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Wählen Sie ein Service-Konto aus….",
  "SERVICE_ACCOUNTS_NEW_LINK": "Verbinden",
  "SERVICE_ACCOUNTS_NEW_TEXT": "ein neues Service-Konto",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Kein {{provider}}'-Dienstkonto konfiguriert.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Chatten Sie mit uns",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Herzlichen Dank für Ihr Feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Haben wir Sie ausreichend informiert?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "Wie würden Sie Ihre Erfahrungen bewerten?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "Wie können wir uns verbessern?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Herzlichen Dank für Ihr Feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Geben Sie hier Ihre Bewertung ab!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Geben Sie hier Ihre Verbesserungsvorschläge an",
  "ADD_USER_OR_GROUP": "Nutzer oder Gruppe hinzufügen",
  "ADD_USER_TO_GROUP": "Nutzer hinzufügen",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Wählen Sie einen oder mehrere Nutzer",
  "SELECT_ROLE": "Rolle auswählen",
  "ALL_USERS_ADDED_TO_GROUP": "Sie haben bereits jeden Ihrer Nutzer zu dieser Gruppe hinzugefügt",
  "WHATS_NEW": "Was ist neu?",
  "WIDGET_LABEL": "Label",
  "WIDGET_LABEL_DESCRIPTION": "Verwenden Sie das <b>Label</b>-Widget, um eine Trennlinie innerhalb Ihres <a>Formulars</a> anzuzeigen.<br><br><b>Hinweis:</b><br>Dies ist nicht Teil der <a>Dateneingabe</a>.",
  "WIDGET_TEXT": "Text",
  "WIDGET_TEXT_DESCRIPTION": "Verwenden Sie das <b>Text</b>-Widget, um den Benutzer eine einzelne Textzeile ausfüllen zu lassen.",
  "WIDGET_TEXT_AREA": "Text Area",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Verwenden Sie das <b>Text Area</b>-Widget, um den Benutzer ganze Sätze ausfüllen zu lassen.",
  "WIDGET_NUMBER": "Number",
  "WIDGET_NUMBER_DESCRIPTION": "Verwenden Sie das <b>Number</b>-Widget, um den Benutzer eine Zahl eingeben zu lassen.",
  "WIDGET_RADIO": "Radio",
  "WIDGET_RADIO_DESCRIPTION": "Verwenden Sie das <b>Radio</b>-Widget, um den Benutzer einen Ihrer vordefinierten Werte wählen zu lassen.<br><br><b>Beispiel:</b><br>Männlich / Weiblich.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Verwenden Sie das <b>Checkbox</b>-Widget, damit Benutzer gewünschte Eigenschaften markieren können z.B. Wahr oder Falsch.",
  "WIDGET_PHOTO": "Photo",
  "WIDGET_PHOTO_DESCRIPTION": "Verwenden Sie das <b>Photo</b>-Widget, damit der Benutzer ein Foto mit der <a>Kamera</a> des Geräts aufnehmen oder ein Foto aus der <a>Galerie</a> hinzufügen kann.",
  "WIDGET_SIGNATURE": "Signature",
  "WIDGET_SIGNATURE_DESCRIPTION": "Verwenden Sie das <b>Signature</b>-Widget, um den Benutzer eine digitale Unterschrift zeichnen zu lassen.",
  "WIDGET_HEADER": "Header",
  "WIDGET_HEADER_DESCRIPTION": "Verwenden Sie das <b>Header</b>-Widget, um Überschriften zu Ihrem Formular hinzuzufügen.<br><br><b>Hinweis:</b><br>Dies ist nicht Teil der <a>Dateneingabe</a>.",
  "WIDGET_DATE": "Date",
  "WIDGET_DATE_DESCRIPTION": "Verwenden Sie das <b>Date</b>-Widget, um dem Benutzer die Möglichkeit zu geben, ein Datum einzugeben.",
  "WIDGET_DATETIME": "Datetime",
  "WIDGET_DATETIME_DESCRIPTION": "Verwenden Sie das <b>Datetime</b>-Widget, um dem Benutzer die Möglichkeit zu geben, ein Datum und eine Uhrzeit einzugeben.",
  "WIDGET_TIME": "Time",
  "WIDGET_TIME_DESCRIPTION": "Verwenden Sie das <b>Time</b>-Widget, um dem Benutzer die Möglichkeit zu geben, eine Zeit einzugeben.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Verwenden Sie das <b>Email</b>-Widget, um den Benutzer eine gültige E-Mail-Adresse eingeben zu lassen.<br><br><b>Hinweis:</b><br>Dieses Feld kann als <a>dynamischer Empfänger</a> in den <a>Email-Einstellungen</a> verwendet werden.",
  "WIDGET_PHONE": "Phone",
  "WIDGET_PHONE_DESCRIPTION": "Verwenden Sie das <b>Phone</b>-Widget, damit der Benutzer eine gültige Telefonnummer eingeben kann.",
  "WIDGET_LOOKUP": "Lookup",
  "WIDGET_LOOKUP_DESCRIPTION": "Verwenden Sie das <b>Lookup</b>-Widget, um den Benutzer einen (oder mehrere) Ihrer vordefinierten Werte auswählen zu lassen.",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Verwenden Sie das <b>List Item</b>-Widget, um einen Link zu einem anderen <a>Formular</a> zu erstellen.",
  "WIDGET_SUBFORM": "Subform",
  "WIDGET_SUBFORM_DESCRIPTION": "Verwenden Sie das <b>Subform</b>-Widget, damit ein Nutzer <a>die gleichen Widgets mehrmals</a> zu Ihrem Formular hinzufügen kann.<br><br><b>Beispiel:</b>< br>Fügen Sie einer <a>Submission</a> mehrere <a>verwendete Materialien, Arbeitsstunden, Fotos oder Bauteile</a> hinzu. Es ist auch möglich, Subformulare zu verknüpfen und mit weiteren Subformularen zu arbeiten.",
  "WIDGET_BARCODE": "Barcode",
  "WIDGET_BARCODE_DESCRIPTION": "Verwenden Sie das <b>Barcode</b>-Widget, um den Benutzer einen Barcode mit der <a>Kamera</a> seines Geräts scannen zu lassen.<br><br><b>Hinweis:</b><br>Unterstützt QR, UPC, EAN, CODE_39 und viele mehr.",
  "WIDGET_SLIDER": "Slider",
  "WIDGET_SLIDER_DESCRIPTION": "Verwenden Sie das <b>Slider</b>-Widget, um mit dem Schieberegler Benutzern eine Zahl zwischen einem definierten Minimum- und Maximumwert auswählen zu lassen.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Verwenden Sie das <b>HTML</b>-Widget, um benutzerdefinierte Markups zu Ihrem Formular hinzuzufügen. (Nur für technische Benutzer)",
  "WIDGET_IMAGE": "Image",
  "WIDGET_IMAGE_DESCRIPTION": "Verwenden Sie das <b>Image</b>-Widget, um ein Bild in Ihrem <a>Formular</a> anzuzeigen.<br><br><b>Hinweis:</b><br>Dies ist nicht Teil der <a>Dateneingabe</a>. Wenn Sie möchten, dass Benutzer ein Foto anhängen können, verwenden Sie das <a>Photo</a>-Widget.",
  "WIDGET_SEARCH": "Search",
  "WIDGET_SEARCH_DESCRIPTION": "Verwenden Sie das <b>Search</b>-Widget in Kombination mit Datenquellen, damit Ihre Nutzer importierte Daten auswählen können wie Kunden-, Produkt- und Mitarbeiterinformationen.",
  "WIDGET_RDW": "License Plate (NL)",
  "WIDGET_RDW_DESCRIPTION": "Verwenden Sie das <b>License Plate (NL)</b>-Widget, um Benutzern die Eingabe eines niederländischen Kennzeichens zu ermöglichen. Das Widget zeigt zusätzliche Informationen über das Fahrzeug an. <b>Hinweis:</b> Zum Anhängen von Zusatzinformationen ist eine Internetverbindung erforderlich!",
  "WIDGET_PIN": "Pin",
  "WIDGET_PIN_DESCRIPTION": "Verwenden Sie das <b>Pin</b>-Widget, damit der Benutzer einen oder mehrere Pins auf einem bestimmten Bild oder einer Karte hinzufügen kann. Jeder Pin, den Sie platzieren, öffnet ein <a>Subform</a>, in dem der Benutzer zusätzliche Informationen über den Pin hinzufügen kann.",
  "WIDGET_HELP": "Help",
  "WIDGET_HELP_DESCRIPTION": "Mit dem Help-Widget können Sie zusätzliche Informationen zu jedem Widget hinzufügen. Wenn ein Benutzer auf das Help-Widget klickt, wird ein Pop-up-Fenster mit zusätzlichen Informationen angezeigt.",
  "WIDGET_RATING": "Rating",
  "WIDGET_RATING_DESCRIPTION": "Das Rating-Widget ermöglicht es Benutzern, ein bestimmtes Thema mit einer Anzahl von Sternen zu bewerten. Es ist möglich, eine individuelle Bewertungsskala zu konfigurieren.",
  "WIDGET_CATALOGUE": "Catalogue",
  "WIDGET_CATALOGUE_DESCRIPTION": "Erstellen Sie Ihre eigene Bestell-App mit dem Catalogue-Widget. Füllen Sie einen Warenkorb mit Produkten, wie Sie es in einem Webshop tun. Verwenden Sie eine Datenquelle mit den folgenden erforderlichen Spalten: Id, Name, PriceExVat (in Cents), Beschreibung. Optional: Foto, Vorschaubild und VatRate. Photos und Thumbnail sind URLs, die nur angezeigt werden, wenn Ihr Gerät eine Internetverbindung hat. Sie können der Datenquelle beliebig viele weitere Spalten hinzufügen. Diese werden auf der Produktdetailseite angezeigt.",
  "WIDGET_SEARCH_WITH_GPS": "Search with GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Suche mit GPS-Ortung",
  "WIDGET_TIME_DIFFERENCE": "Time Difference",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Dieses Widget berechnet die Differenz zwischen zwei Time-Widgets bzw. Date-Time-Widgets.",
  "WIDGET_PAYMENT": "Payment",
  "WIDGET_PAYMENT_DESCRIPTION": "Der Benutzer kann einen In-App-Kauf tätigen, um für das Formular zu bezahlen.",
  "WIDGET_STOPWATCH": "Stopwatch",
  "WIDGET_STOPWATCH_DESCRIPTION": "Verwenden Sie das Stopwatch-Widget, um die Zeit in Ihrem Formular zu erfassen. Die Zeit wird zum Beispiel wie folgt angezeigt: 00:16.47.",
  "WIDGET_SMILEY": "Smiley",
  "WIDGET_SMILEY_DESCRIPTION": "Geben Sie mit diesem Widget eine Bewertung basierend auf Smileys ab.",
  "WIDGET_CURRENT_LOCATION": "Current Location",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Ermitteln Sie mit diesem Widget automatisch Ihren aktuellen Standort mithilfe von GPS. Das Ergebnis wäre z.B. 'Rudi-Dutschke-Straße 23, 10969 Berlin, Deutschland'. Das Ergebnis kann jederzeit manuell bearbeitet werden, falls es nicht stimmt.",
  "WIDGET_DRAWING": "Drawing",
  "WIDGET_DRAWING_DESCRIPTION": "Mit diesem Widget können Sie einem Foto Zeichnungen, Linien und Texte hinzufügen.",
  "WIDGET_VIDEO": "Video",
  "WIDGET_VIDEO_DESCRIPTION": "Mit diesem Widget können Sie ein Video aufnehmen oder auswählen. Das Widget führt zu einer URL in Ihrem PDF-Bericht, um das enthaltene Video herunterzuladen.",
  "WIDGET_ZIPCODE": "Zipcode",
  "WIDGET_ZIPCODE_DESCRIPTION": "Das Zipcode-Widget liefert Adressdaten aus einer eingegebenen Postleitzahl.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "Das IBAN-Widget prüft und bestätigt IBAN-Nummern.",
  "WIDGET_DRAWING_FREE": "Drawing Free",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "Eine kostenlose Kopie des Drawing-Widgets. Für die Kunden des Blauwdruk-Partners.",
  "WIDGET_CALCULATION": "Calculation",
  "WIDGET_CALCULATION_DESCRIPTION": "Das Calculation-Widget ermöglicht Operationen wie Addition, Subtraktion, Multiplikation und Division über ein oder mehrere Widgets.",
  "WIDGET_POSTCODE_NL": "Postleitzahl (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Dieses Widget funktioniert nur für niederländische Postleitzahlen! Mit dem Postleitzahl (NL)-Widget rufen Sie Adressinformationen anhand einer Postleitzahl und Hausnummer ab. Sie benötigen eine Internetverbindung, um die Daten abzurufen. Wenn Sie offline sind, können Sie die Daten manuell hinzufügen.",
  "WIDGET_ADVANCED_SEARCH": "Advanced Search",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Mit dem Advanced Search-Widget können Sie Ihre Suchergebnisse in der App einfärben. Sie können konfigurieren, welche Farbe verwendet werden soll, wenn ein Suchergebnis mit einer Abfrage übereinstimmt. Sie können Farben mithilfe von Beschreibungen (wie 'rot' oder 'grün') oder mithilfe von HEX-Farbcodes (wie #1dc4c2) definieren",
  "WIDGET_FILE": "File",
  "WIDGET_FILE_DESCRIPTION": "Mit diesem Widget können Sie Ihrem Formular Dateien jeder Art hinzufügen. Das Widget führt zu einer URL in Ihrem PDF-Bericht, um die enthaltenen Dateien herunterzuladen.",
  "WIDGET_LOCATION": "Standort",
  "WIDGET_LOCATION_DESCRIPTION": "Mit diesem Widget können Sie einen Standort auf einer Karte auswählen.\n\nDas Ergebnis kann jederzeit manuell bearbeitet werden, falls es nicht richtig ist.",
  "WIDGET_PROPERTY_PIN_NAME": "Name",
  "WIDGET_PROPERTY_PIN_FORM": "Formular",
  "WIDGET_PROPERTY_PIN_ICON": "Symbol",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Listenelement",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "Tracking-ID",
  "WIDGET_PROPERTY_LABEL": "Label",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "Name, der über dem Widget angezeigt wird",
  "WIDGET_PROPERTY_OPERATOR": "Rechenoperator",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "In der Berechnung verwendeter Rechenoperator.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Berechnungsbedingungen",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Alle Felder, die in die Berechnung einbezogen werden sollen.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Ursprünglicher Wert",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Ursprünglicher Wert für Berechnung.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Dezimalstellen",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "Anzahl der Stellen, die die Berechnung hat.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "Kein Wert bei ungültigem Term",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "Das Widget sollte keinen Wert erzeugen, wenn einer der Begriffe ungültig ist (d.h. keine Zahl).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Catalogue-Datenquelle",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Wählen Sie eine Ihrer Datenquellen aus, die Sie für das Catalogue-Widget verwenden möchten. Stellen Sie sicher, dass die Datenquelle der gewünschten Konfiguration entspricht.",
  "WIDGET_PROPERTY_CURRENCY": "Währung",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Konfiguriert die zu verwendende Währung, aber nur, wenn der Wert ein Preis ist",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "MwSt.-Prozentsatz",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "Mehrwertsteuerprozentsatz, der auf alle Artikel des Catalogue-Widgets angewendet wird.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Preise anzeigen",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Aktivieren Sie diese Option, wenn Sie möchten, dass die Einzelpreise und die Gesamtpreise in der App und im PDF angezeigt werden.",
  "WIDGET_PROPERTY_SHOW_VAT": "MwSt. anzeigen",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Aktivieren Sie diese Option, wenn Sie möchten, dass die MwSt. in der App und im PDF angezeigt wird.",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Anmerkungen zulassen",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Aktivieren Sie dies, damit Nutzer beim Hinzufügen eines Objekts eine Anmerkung hinterlassen können.",
  "WIDGET_PROPERTY_RATE_ICON": "Bewertungs-Symbol",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Bestimmen Sie, welches Symbol für die Bewertung verwendet wird, z.B. Herzen",
  "WIDGET_PROPERTY_RATE_SCALE": "Bewertungsskala",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Wählen Sie die Anzahl der angezeigten Symbole.",
  "WIDGET_PROPERTY_TITLE": "Titel",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "Der Titel der angegebenen Erklärung.",
  "WIDGET_PROPERTY_EXPLANATION": "Erläuterung",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "Die eigentliche Erklärung.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Verwenden Sie den Text der Hilfe-Schaltfläche",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Wenn Sie diese Option auswählen, wird der unten stehende Text vor einem Fragezeichen angezeigt.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Text der Hilfe-Schaltfläche",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Text, der vor einem Fragezeichen angezeigt wird, wenn das obige Kontrollkästchen aktiviert ist.",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "Berechneter Wert ist ein Preis",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Ergebnis als Preis ausgeben.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ausgeblendete Widgets ignorieren",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Diese Einstellung ermöglicht es, ausgeblendete Widgets bei Berechnungen zu ignorieren. Sie ist ab Version 7.0.32 verfügbar.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Barcode-Scanner verwenden",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Aktivieren Sie diese Option, um Nutzern die Suche nach einem gescannten Barcode zu erlauben.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Standardwert",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Dieser Wert ist bereits vordefiniert.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Standard-Suchanfrage",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "Wenn Sie die Suche öffnen, wird diese vordefinierte Anfrage verwendet.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Bezeichnung",
  "WIDGET_PROPERTY_START_TIME": "Startzeitpunkt",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Startzeit, die für die Berechnung verwendet wird.",
  "WIDGET_PROPERTY_END_TIME": "Endzeitpunkt",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Endzeit, die für die Berechnung verwendet wird.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Hintergrundbild",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Maximale Dauer in Sekunden",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "Maximale Dauer des Videos in Sekunden.",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Video-Qualität",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Wählen Sie Ihre bevorzugte Videoqualität.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Dateityp",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Wählen Sie den Dateityp, der hochgeladen werden kann.",
  "WIDGET_PROPERTY_VALUE": "Wert",
  "WIDGET_PROPERTY_PLACEHOLDER": "Platzhalter",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Beispieleingabe, die nur angezeigt wird, wenn das Feld leer ist und entfernt wird, wenn es befüllt ist.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Maximale Länge",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Maximalanzahl von Zeichen. Wenn dieser Wert überschritten wird, wird in der mobilen App ein Validierungsfehler angezeigt.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Minimale Länge",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Mindestanzahl von Zeichen. Zeigt einen Validierungsfehler in der mobilen App an, wenn die Länge dieses Wertes zu kurz ist.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Minimumwert",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "Die App erlaubt es nicht, einen niedrigeren Wert als diesen zu wählen.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Maximumwert",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "Die App erlaubt es nicht, einen höheren Wert als diesen zu wählen.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Bilder als Beschriftungen verwenden",
  "WIDGET_PROPERTY_OPTIONS": "Optionen",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Definieren Sie die Optionen, die in der App ausgewählt werden können.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Vertikale Ausrichtung",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Aktivieren Sie dieses Feld, um Optionen vertikal statt horizontal auszurichten.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Standardmäßig aktiviert",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Wenn dieses Feld markiert ist, wird es beim Öffnen eines neuen Formulars aktiviert.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Fotoqualität",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Wählen Sie Ihre bevorzugte Fotoqualität.<br><br><a>Bestmöglich (empfohlen)</a><br>Keine Verkleinerung, keine Komprimierung. Könnte Probleme beim Versenden von E-Mails mit vielen Fotos verursachen.<br><br><a>Schneller Upload</a><br>Auf max. 1024x1024 herunterskalieren, um 75% zu komprimieren. Könnte zu Speicherproblemen auf Low-End-Geräten führen.<br><br><a>Hohe Qualität</a><br>Verkleinert auf max. 1920x1920, komprimiert um 10%. Könnte auf Low-End-Geräten und beim Versenden von E-Mails mit vielen Fotos zu Speicherproblemen führen.",
  "WIDGET_PROPERTY_FILENAME": "Dateiname",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Wählen Sie den Namen des Fotos, wie er beim Export angezeigt werden soll.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Auswählen eines Fotos vom Gerät zulassen",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Erlauben Sie Nutzern, ein zuvor aufgenommenes Foto vom Gerät auszuwählen.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Vorschau in voller Größe",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Wählen Sie diese Option, wenn Sie eine Vorschau des Fotos in voller Größe in Ihrem Formular anzeigen möchten.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Kopie auf dem Gerät speichern",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Stiftgröße",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Erhöhen/Verringern der Stiftgröße des Nutzers.",
  "WIDGET_PROPERTY_WIDE": "Breite",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Dadurch wird die zulässige Größe einer Unterschrift verringert und die resultierende Unterschrift wird breiter.",
  "WIDGET_PROPERTY_TEXT": "Text",
  "WIDGET_PROPERTY_HEADER_SIZE": "Größe der Kopfzeile",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Größe der Kopfzeile, von h1 (größte) bis h6 (kleinste)",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Als jetzt markieren",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Aktivieren Sie dieses Kontrollkästchen, um den Wert automatisch auf \"jetzt\" zu setzen, wenn das Formular geöffnet wird.",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Alphabetisch sortieren",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Setzen Sie ein Häkchen, um die Auswahl alphabetisch zu sortieren.",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Mehrfachauswahl zulassen",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Aktivieren Sie dieses Kontrollkästchen, damit mehrere Optionen ausgewählt werden können. Diese ausgewählten Werte werden durch Kommas voneinander getrennt und in den eingereichten Daten gespeichert.",
  "WIDGET_PROPERTY_EXPAND": "Erweitern",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Aktivieren Sie dieses Feld, um die Liste beim Öffnen des Formulars zu erweitern.",
  "WIDGET_PROPERTY_TARGET_VIEW": "Soll-Ansicht",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Wählen Sie das Formular aus, zu dem Sie einen Link herstellen möchten.",
  "WIDGET_PROPERTY_IMAGE": "Image",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Dieses Bild wird in der App als Vorschaubild verwendet.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Schaltfläche um einen Text hinzuzufügen",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Name der Schaltfläche zum Hinzufügen eines Elements über das Subformular.",
  "WIDGET_PROPERTY_SUBFORM": "Subform",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Wählen Sie das Formular, mit dem Sie Ihr zusammengesetztes Objekt erstellen möchten.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Zusammenfassung in App",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Geben Sie an, wie ein hinzugefügter Eintrag angezeigt wird, indem Sie die Werte bestimmter Felder verwenden.<br><br><b>Beispiel:</b><br>Jeder hinzugefügte Eintrag soll angezeigt werden als:<br> ${firstname} ${lastname}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Minimum",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "Mindestanzahl, wie oft ein Subformular ausgefüllt werden muss.",
  "WIDGET_PROPERTY_MAXIMUM": "Maximum",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "Maximalanzahl, wie oft ein Subformular ausgefüllt werden darf.",
  "WIDGET_PROPERTY_STEP_SIZE": "Spanne",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Wird z.B. 3 ausgewählt, wird der Schieberegler immer auf ein Vielfaches von 3 gerundet.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Wert ausblenden",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Legt fest, ob der Wert in der App angezeigt werden soll oder nicht.",
  "WIDGET_PROPERTY_HTML_CODE": "HTML-Code",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Fügen Sie ein HTML-Stück hinzu, das Inline-CSS unterstützt.",
  "WIDGET_PROPERTY_RESOURCE": "Quelle",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Wählen Sie ein Bild aus der Galerie aus.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Maximale Breite",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Bildbreite innerhalb der App. Die Breite wird auf die Bildschirmbreite begrenzt, wenn dieser Wert überschritten wird.",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Maximale Höhe",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Bildhöhe innerhalb der App. Die Höhe wird auf die Bildschirmhöhe begrenzt, wenn dieser Wert überschritten wird.",
  "WIDGET_PROPERTY_DATA_SOURCE": "Datenquelle",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Wählen Sie eine Ihrer Datenquellen aus, in der Sie suchen möchten. Neue Datenquellen können über das Hauptmenü Datenquellen erstellt werden.",
  "WIDGET_PROPERTY_FILTER": "Filter",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filtern Sie die Ergebnisse anhand der Werte anderer Felder in Ihrem Formular.",
  "WIDGET_PROPERTY_LIST_COLORS": "Farbliste",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Wenden Sie Farben auf Datenquelleneinträge an, die mit den konfigurierten Abfragen übereinstimmen.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Barcode-Scannen zulassen",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Aktivieren Sie dieses Kontrollkästchen, damit der Nutzer die Datenquelle mit dem Barcode-Scanner durchsuchen kann.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Letzte Suchanfrage merken",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Aktivieren Sie das Kontrollkästchen, um die Suchanfrage zu speichern. Die Suchabfrage wird automatisch ausgeführt.",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Benutzer erlauben, Bild zu ändern",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Aktivieren Sie dieses Kontrollkästchen, um den Nutzern zu erlauben, ihre eigenen Bilder als Hintergrund für die Pins zu verwenden, die Sie entweder über die Kamera oder die Galerie aufnehmen bzw. auswählen können.",
  "WIDGET_PROPERTY_PINS": "Pins",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Definieren Sie einen oder mehrere Pins, die auf dem angegebenen Bild markiert werden können. Jeder Pin hat sein eigenes Symbol und öffnet das angegebene Formular, wenn er platziert wird.<br><br>In der App werden Ihre Pins automatisch nummeriert. Um Ihre eigene Nummerierung oder Beschriftung zu verwenden, definieren Sie eine Tracking-ID.",
  "WIDGET_PROPERTY_REQUIRED": "Erforderlich",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Wenn dieses Feld angekreuzt ist, muss es ausgefüllt werden, um das Formular speichern zu können.",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Eingabe merken",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Aktivieren Sie diese Option, um die letzte Eingabe des Nutzers auf diesem Gerät für die nächste Submission zu speichern. Wenn Sie einen Standardwert festgelegt haben, denken Sie daran, dass die Eingabe ersetzt wird.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Dezimalzeichen",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Mindestbetrag",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "Die App erlaubt es nicht, einen niedrigeren Wert als diesen zu wählen.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Höchstbetrag",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "Die App erlaubt es nicht, einen Wert zu wählen, der diesen übersteigt.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Zur Ansicht des Bildes anklicken",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Wählen Sie diese Option, wenn Sie eine Vorschau des Fotos in voller Größe anzeigen möchten, indem Sie auf das Bild klicken.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Standardmäßig zum aktuellen Standort",
  "INTEGRATION_TASK": "Task",
  "INTEGRATION_TASK_DESCRIPTION": "Mit dieser Integration können Sie eine Aufgabe auf der Grundlage einer Submission erstellen.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "Mit diesem Plugin können Sie eine MoreApp-Anweisung basierend auf einer eingehenden Submission erstellen.",
  "INTEGRATION_DELETE": "Delete",
  "INTEGRATION_DELETE_DESCRIPTION": "Diese Integration ermöglicht es Ihnen, die Submissions eines Formulars automatisch zu löschen. Stellen Sie sicher, dass dies die letzte Integration ist, die Sie zu Ihrem Formular hinzufügen. Einmal gelöschte Submissions können nicht wiederhergestellt werden. Seien Sie also vorsichtig, wenn Sie diese Integration verwenden!",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Mit dieser Integration können Sie Microsoft Word für den Export von Daten verwenden. Sie können mit dieser Integration PDF- oder Word-Anhänge versenden. Laden Sie einfach Ihre Word.docx-Vorlage hoch, um Berichte individuell zu gestalten.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "Mit der WebDAV-Integration können Sie die PDF-Datei aus einer E-Mail an einen WebDAV-Server senden.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Mit dieser Integration können Sie Ihre ausgefüllten Formulare an eine Google-Tabelle senden. Sie können Ihre Google-Tabellen-ID aus der URL abrufen. Hinweis: Ihre eingereichten Formulare werden einfach als neue Zeile in Ihr Blatt eingefügt. Wir aktualisieren die Blattüberschriften nicht, wenn Sie Ihr Formular aktualisieren. Diese müssen selbst aktualisiert werden. Wir unterstützen keine Bilder/Dateien und alle Daten (Subform-, Pin-, Such-Widgets) werden einer einzelnen Zelle hinzugefügt. Bei Formularen mit Regeln und leeren Feldern können Daten in der falschen Spalte platziert werden.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "Mit der MongoDB-Insert-Integration können Sie Ihre Anmeldedaten in eine Mongo-Datenbank einfügen.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Diese Integration ermöglicht es Ihnen, die an Ihr Formular angehängte PDF-Datei an Microsoft OneDrive zu senden.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Diese Integration ermöglicht es Ihnen, Excel zum Exportieren von Daten zu verwenden. Laden Sie Ihre Excel.xlsx-Vorlage hoch und erhalten Sie Ihre Daten in einer Excel-Datei.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Aktualisieren Sie eine bestimmte Zelle in einem bestehenden Blatt. Diese Integration ermöglicht es Ihnen, ein Blatt zu aktualisieren, das auch als importierte Datenquelle in MoreApp verwendet wird.",
  "INTEGRATION_MESSAGE_BOARD": "Anzeigetafel",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Senden von Nachrichten zulassen",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Google Sheet akzeptieren",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "Nicht für Produktion",
  "INTEGRATION_PROPERTY_FOLDER": "Ordner",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "Der Ordner, für den eine Aufgabe gesendet werden soll.",
  "INTEGRATION_PROPERTY_FORM": "Formular",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "Das Formular (innerhalb des ausgewählten Ordners), das als Ziel für die Aufgabe verwendet werden soll.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Empfänger (E-Mails werden durch Komma getrennt)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Empfänger (E-Mails werden durch Komma getrennt)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamische Empfänger",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Verwenden Sie die Daten aus einem E-Mail-Widget als Empfänger",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Nachricht für die Aufgabe",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Nachricht für die Aufgabe",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Verzögerung (in Tagen)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "Die Anzahl der Tage, die das Subformular bestehen bleiben soll, bevor es gelöscht wird.",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Ausgabeformat",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Legt fest, welches Ausgabeformat als Anhang verwendet werden soll",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Antworten an (optional)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "Die Adresse, an welche die Empfänger antworten werden",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "Von (optional)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "Der Name, von dem die E-Mails gesendet werden sollen",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Empfänger",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Durch Kommas getrennte Empfängerliste",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "An den Nutzer versenden, der das Formular abgeschickt hat",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Aktivieren Sie dies, um den Benutzer, der das Formular abgeschickt hat, als Empfänger hinzuzufügen",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Durch Kommas getrennte Empfängerliste",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Durch Kommas getrennte Empfängerliste",
  "INTEGRATION_PROPERTY_SUBJECT": "Betreff",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Betreff mit optionalen Platzhaltern",
  "INTEGRATION_PROPERTY_BODY": "Format",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "E-Mail-Format mit optionalen Platzhaltern",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Bildqualität",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Wenn Ihre Submission viele hochauflösende Fotos enthält, sollten Sie die Qualität verringern. Andernfalls könnte die E-Mail zu groß für Ihren Posteingang werden.",
  "INTEGRATION_PROPERTY_FILENAME": "Dateiname",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Dateiname mit optionalen Platzhaltern",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Word-Vorlage hochladen",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "Verwenden Sie hier eine Word-Vorlage.",
  "INTEGRATION_PROPERTY_SERVER": "Server",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL zum Server, wird als Basispfad verwendet z.B.: http://my.server.com",
  "INTEGRATION_PROPERTY_PATH": "Pfad",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Pfad, in dem die PDFs gespeichert werden sollen, kann Platzhalter enthalten, z.B.: /Laufwerk/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "WebDAV-Benutzername",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Benutzername, der bei der Authentifizierung am WebDAV-Server verwendet werden soll",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "WebDAV-Passwort",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Passwort, das bei der Authentifizierung im WebDAV-Server verwendet werden soll",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Google-Anmeldung",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Wählen Sie ein Google-Konto, damit auf Ihre Google Spreadsheets zugriffen werden kann",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "Spreadsheet ID",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "Die eindeutige ID Ihres Spreadsheets, die in der URL wie https://docs.google.com/spreadsheets/d/<b>my-spreadsheet-id</b> angezeigt wird.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Name des Blattes",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "Der Name Ihres Blattes, der in der Regel auf 'Blatt1' voreingestellt ist",
  "INTEGRATION_PROPERTY_METADATA": "Metadaten",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Wählen Sie aus, welche Meta-Felder Sie in den Export aufnehmen möchten. Diese werden vor den Formulardaten eingefügt",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB-Host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "Die IP-Adresse des MongoDB-Hosts",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "MongoDB Port",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "Port, auf den MongoDB reagiert",
  "INTEGRATION_PROPERTY_USE_SSL": "SSL verwenden",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Verwenden Sie eine sichere TLS-Verbindung",
  "INTEGRATION_PROPERTY_USERNAME": "Benutzername",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "Der Benutzername des Mongo-Benutzers, den wir authentifizieren sollen",
  "INTEGRATION_PROPERTY_PASSWORD": "Passwort",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "Das Passwort des Mongo-Benutzers, das wir authentifizieren sollen",
  "INTEGRATION_PROPERTY_DATABASE": "Datenbank",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "Datenbank, in der Sie die Submissions speichern möchten",
  "INTEGRATION_PROPERTY_COLLECTION": "Datensammlung",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "Datensammlung, in der Sie Submissions speichern möchten",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Search-Widget",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "Verwenden Sie ein Search-Widget aus Ihrem Formular",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "OneDrive-Anmeldung",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Wählen Sie ein OneDrive-Konto, um Dateien speichern zu können",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "CSV-Datei einbinden",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Hängt automatisch eine CSV-Datei (Comma Separated Value) an Ihr OneDrive-Verzeichnis an",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Excel-Vorlage hochladen",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "Verwenden Sie hier eine Excel-Vorlage.",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Name der Excel-Datei",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Name der Excel-Datei, wie sie angehängt werden soll",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Kopie an Benutzer",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Senden Sie eine Kopie an den Benutzer, der das Formular ausgefüllt hat",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Bilder anhängen",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Bilder und Unterschriften an die Mail anhängen",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Neuer Wert für Widget",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Wählen Sie das Widget, von dem Sie den neuen Wert erhalten möchten",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Name der zu aktualisierenden Spalte",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Definieren Sie die Spalte, die Sie mit dem Wert aus dem ausgewählten \"Neuer Wert für Widget\" aktualisieren möchten",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "Die FTPS-Integration speichert die Übermittlungsdaten Ihrer Submissions auf einem FTPS-Server.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protokoll",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "Das zu verwendende Protokoll für die Verbindung zum FTP-Server",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "SSL-Verifizierung deaktivieren",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Sie können diese Option verwenden, wenn Sie ein self-signed certificate oder eine invalid certificate chain haben (gilt nur bei Verwendung von FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Verwenden Sie den 'impliziten' FTPS-Modus",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Wechseln Sie zwischen 'implizitem' und 'explizitem FTPS-Modus' (gilt nur bei Verwendung von FTPS).",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL zum Server ohne Protokoll, wird als Basispfad verwendet z.B.: my.server.com.",
  "INTEGRATION_PROPERTY_PORT": "Port",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Port zum FTP(S)-Server, standardmäßig 21 oder 990, wenn nicht ausgefüllt.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Legen Sie jede Submission in einem eigenen Ordner ab",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Fügt '/customerId/form-name/serial-number' automatisch an den dezentralen Pfad an, um einen eindeutigen Pfad pro Submission zu erstellen.",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "FTP-Nutzername",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Benutzername, der bei der Authentifizierung am WebDAV-Server verwendet werden soll.",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "FTP-Passwort",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Passwort, das bei der Authentifizierung im WebDAV-Server verwendet werden soll.",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "PDF einbeziehen",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Gibt an, ob auch das PDF kopiert werden soll oder nicht.",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "PDF für E-Mails ohne Empfänger speichern",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Gibt an, ob PDFs gespeichert werden, die für E-Mails ohne Empfänger generiert werden.",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Bilder einbeziehen",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Gibt an, ob auch Bilder kopiert werden sollen oder nicht.",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Excel-Vorlage hochladen",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "Verwenden Sie hier eine Excel-Vorlage",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Alte Begrenzungszeichen verwenden",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Verwenden Sie diese Option, wenn Sie '${' als Startbegrenzer für Platzhalter verwenden möchten (dies wird nur für ältere bestehende Vorlagen empfohlen).",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Name der Excel-Datei",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Name der Excel-Datei, wie sie angehängt werden soll",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Empfänger",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Durch Kommas getrennte Empfängerliste.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Dynamische Empfänger",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Verwenden Sie die Daten aus einem E-Mail-Widget als Empfänger",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Kopie an Nutzer",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Eine Kopie an den Nutzer senden, der das Formular ausgefüllt hat",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Bildqualität",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Höhere Qualität bedeutet mehr Zeit für die Bearbeitung der E-Mail",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Betreff",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Betreff mit optionalen Platzhaltern",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Text",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "E-Mail-Text mit optionalen Platzhaltern",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Überprüfen Sie Ihre Zeiteinstellung",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "Sie können sich nicht einloggen, weil Ihre Zeiteinstellung nicht korrekt ist.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>FIX</b>: Ändern Sie Zeit und Zeitzone Ihres Geräts, damit es genau die Zeit und Zeitzone Ihres aktuellen Standorts verwendet. Danach versuchen Sie es erneut.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Anmeldung erneut versuchen",
  "TOUR_PROGRESS_STEP1_LABEL": "Wie es funktioniert",
  "TOUR_PROGRESS_STEP2_LABEL": "Erzählen Sie uns mehr",
  "TOUR_PROGRESS_STEP3_LABEL": "Ihr erstes Formular",
  "TOUR_SKIP": "Tour überspringen",
  "TOUR_STEP1_TRIAL": "Sie befinden sich in der 14-Tage-Testversion",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Erstellen und modifizieren Sie Formulare auf Ihrem Computer",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Nutzen Sie Ihr Smartphone, Tablet oder Ihre Computer, um Formulare auszufüllen",
  "TOUR_STEP1_RECEIVE_DATA": "Erhalten Sie die eingetragenen Daten im gewünschten Format",
  "TOUR_STEP2_HEADER": "Erzählen Sie uns etwas über sich",
  "TOUR_STEP3_HEADER": "Wie können wir Sie kontaktieren?",
  "TOUR_ERROR_MODAL_TITLE": "Ups…",
  "TOUR_ERROR_MODAL_MESSAGE": "Etwas ist mit unserer Tour schiefgelaufen. Die Person die hierfür verantwortlich ist wird umgehend von Ihren Aufgaben entbunden. In der Zwischenzeit können Sie {{appName}} entdecken.",
  "TOUR_ERROR_MODAL_BUTTON": "Entdecken Sie {{appName}}",
  "FIRST_NAME": "Vorname",
  "LAST_NAME": "Nachname",
  "PAGE_CONTROL_PAGE_SIZE": "Seitengröße",
  "PAGE_CONTROL_TOTAL_ITEMS": "Gesamtanzahl Elemente",
  "VIEW_MANAGEMENT_OVERVIEW": "Übersicht",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Submissions",
  "VIEW_MANAGEMENT_TASKS": "Aufgaben",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Kosten",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Status",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "Auf",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Formular bearbeiten",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Formular bearbeiten (Beta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Teilen",
  "FORM_REMOVE_MODAL_TITLE": "Formular entfernen",
  "FORM_REMOVE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie dieses Formular löschen wollen?",
  "REMOVE_DRAFT_TITLE": "Entwurf entfernen",
  "REMOVE_DRAFT_MESSAGE": "Sind Sie sicher, dass Sie diesen Entwurf löschen wollen?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Vorlage entfernen",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie diese Vorlage löschen wollen?",
  "UNPUBLISHED": "Unveröffentlicht",
  "PUBLISHED": "Veröffentlicht",
  "PUBLISHED_UPDATED": "Veröffentlicht, Aktualisierung verfügbar",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Nutzer verwalten",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Formular im Marketplace platzieren",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Im Marketplace geteilt",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Im Marketplace ausgeblendet",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Im Marketplace ansehen",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Bearbeiten",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Aktualisierung auf neuste Version",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Ihre Formular-Vorlage konnte nicht auf die neuste Version aktualisiert werden. Bitte versuchen Sie es später erneut.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Im Marketplace ausblenden",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Im Marketplace einblenden",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Formular im Marketplace platzieren",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Sind Sie sicher, dass Ihr Formular mit allen anderen Nutzern geteilt werden soll?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Bitte füllen Sie vorerst dieses Formular aus. Die anzugebenden Informationen (ausgenommen Ihre Bankverbindung) wird auf dem Marketplace sichtbar sein.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Aktualisierungsinformation",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Namensfeld",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Beispiel: de.meinefirma",
  "FORM_TEMPLATE_KEY_LABEL": "Key",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Beispiel: Spezialformular",
  "FORM_TEMPLATE_TYPE_LABEL": "Art",
  "FORM_TEMPLATE_NAME_LABEL": "Name",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Benenn Sie Ihre Formular-Vorlage",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Beschreibung",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Beschreiben Sie Ihre Formular-Vorlage",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "Die URL des Logos Ihrer Formular-Vorlage",
  "DIRECT_FORM_LINK": "Direkte URL generieren",
  "DIRECT_FORM_LINK_GENERATED": "Direkte URL öffnen",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Direkte URL widerrufen",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Sind Sie sicher, dass Sie die URL für den direkten Zugriff auf Ihr Formular widerrufen möchten? Dies bedeutet, dass die URL nicht mehr funktionieren wird.",
  "PUBLISH": "Veröffentlichen",
  "PUBLISH_FORM_VERSION": "Diese Version veröffentlichen",
  "PUBLISH_FORM_VERSION_MESSAGE": "Sind Sie sicher, dass Sie diese Formular-Version veröffentlichen wollen?",
  "PUBLISHED_BY": "Veröffentlicht von ",
  "PUBLISHED_ON": "Veröffentlicht",
  "REMARKS": "Bemerkungen",
  "DRAFT": "Entwurf",
  "FORM_HISTORY": "Versionen-Übersicht",
  "FORM_DESCRIPTION_PLACEHOLDER": "Fügen Sie eine kurze Beschreibung Ihres Formulars hinzu",
  "FORM_MOVE_MODAL_TITLE": "Verschieben",
  "FORM_SAVE_AS_TEMPLATE": "Als Vorlage speichern",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Möchten Sie dieses Formular wirklich als Vorlage speichern?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Verschieben Sie das Formular in den ausgewählten Ordner",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Ordner auswählen",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Anscheinend bearbeiten Sie oder einer Ihrer Kollegen dieses Formular gerade. Stellen Sie sicher, dass Sie die einzige Person sind, die dieses Formular zur Zeit bearbeitet und versuchen Sie es im Anschluss erneut.",
  "INTEGRATIONS_EOL_MESSAGE": "Dieses Formular beeinhaltet eine deaktivierte Integration. Bitte entfernen oder ersetzen Sie diese durch eine andere Integration oder einen anderen Webhook. Bitte <a href=\"https://help.moreapp.com/en/support/tickets/new\">wenden Sie sich an den Support</a>, wenn Sie weitere Hilfe benötigen.",
  "DATASOURCE_PICKER_ADD": "Datenquelle hinzufügen",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Nach Datenquellen suchen",
  "GROUP_PICKER_ADD_USER": "Nutzer einladen",
  "GROUP_PICKER_ADD_GROUP": "+ Neue Gruppe erstellen",
  "GROUP_PICKER_ALL_USERS": "Alle Nutzer",
  "GROUP_PICKER_INVITED": "(Eingeladen)",
  "MANAGE_ROLES": "Rollen festlegen",
  "ROLE": "Rolle",
  "SEARCH_GROUP_PLACEHOLDER": "Nach Gruppen suchen",
  "BILLING_TITLE": "Abrechnungen",
  "BILLING_OVERVIEW_MENU_ITEM": "Übersicht",
  "BILLING_INVOICES_MENU_ITEM": "Rechnungen",
  "BILLING_USAGE_MENU_ITEM": "Statistik",
  "SUBTOTAL": "Zwischensumme",
  "TOTAL": "Endsumme",
  "NEXT_INVOICE": "Nächste Rechnung",
  "PREVIEW_CHANGE_EXPLANATION": "Diese Übersicht zeigt Ihnen die nächste Rechnung, nachdem Ihre Änderungen angewandt wurden. Lesen Sie diesen <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Help Center</a>-Beitrag für mehr Informationen zu Ihren Abrechnungen.",
  "PREVIEW_CHANGE": "Vorschau der Änderungen",
  "CHANGE_SUBSCRIPTION_BUTTON": "Plan ändern",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Änderungen anzeigen",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Planänderung",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Geplant",
  "SCHEDULED_CANCEL_MESSAGE": "Die Kündigung Ihres Plans ist zum Ende des aktuellen Abrechnungszeitraums vorgesehen",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Änderung abbrechen",
  "SELECT_PLAN": "Wählen Sie Ihren Plan aus",
  "YOUR_SELECTED_PLAN": "Ihr ausgewählter Plan",
  "LOADING_PLANS": "Verfügbare Pläne werden geladen",
  "MONTHLY_PLAN": "Monatlicher Plan",
  "YEARLY_PLAN": "Jährlicher Plan",
  "MONTH_SHORT": "p. M.",
  "YEAR_SHORT": "p. J.",
  "TRIAL_ACTIVE": "Testversion aktiv",
  "DAYS": "Tage",
  "TRIAL_DAYS_REMAINING": "verbleibende Tage",
  "TRIAL_ENDS_TODAY": "Ihre Testversion endet heute",
  "TRIAL_ENDS_TOMORROW": "Ihre Testversion endet morgen",
  "TRIAL_ENDS_ON": "Ihre Testversion endet am",
  "SUBMISSIONS": "Submissions",
  "USERS": "Nutzer",
  "USAGE": "Nutzung",
  "UNLIMITED": "Unbegrenzt",
  "CHANGE_PLAN": "Plan ändern",
  "CANCEL_PLAN": "Plan kündigen",
  "UNCANCEL_PLAN": "Kündigung aufheben",
  "PLAN_MORE_OPTIONS": "Mehr Optionen",
  "DELETE_ACCOUNT": "Konto löschen",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Es liegt eine offene Rechnung vor",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Sie haben noch eine offene Rechnung, daher können Sie diese Aktion nicht durchführen. Gehen Sie zu Ihren Rechnungen, um Ihre Zahlung abzuschließen.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Zu den offenen Rechnungen",
  "CANCEL_PLAN_MODAL_TITLE": "Plan kündigen",
  "CANCEL_PLAN_MODAL_MESSAGE": "Sie können unser Produkt bis zum Ende Ihres Abrechnungszeitraums weiter nutzen. Danach werden keine Submissions mehr bearbeitet. Sie können noch für 30 Tage auf Ihr Konto zugreifen, um Ihre Daten einzusehen und zu exportieren. Danach wird Ihr Konto gelöscht.",
  "UNCANCEL_PLAN_MODAL_TITLE": "Kündigung aufheben",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Gute Wahl! Sie haben es sich anders überlegt und möchten unser Produkt weiterhin nutzen?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Plan wurde bereits zur Kündigung freigegeben",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "Die Kündigung Ihres Plans ist für das Ende des aktuellen Abrechnungszeitraums vorgesehen.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Bitte teilen Sie uns mit, wie wir unser Produkt verbessern können. Ihr Feedback ist uns wichtig.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>Es kann nicht immer passen.</h4><p>Bitte lassen Sie uns wissen, wie wir unser Produkt verbessern können. Ihr Feedback ist uns sehr wichtig.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Warum möchten Sie Ihr Konto löschen?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "Alternative gefunden",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "Zu teuer",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Keinen Bedarf mehr",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Ich schließe mein Unternehmen",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "Ich bin nicht zufrieden mit dem Produkt",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Funktion nicht vorhanden",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Sonstige",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Bitte wählen Sie mindestens einen Grund aus.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Möchten Sie uns noch etwas mitteilen?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Bitte geben Sie eine Nachricht ein.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Plan kündigen",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Konto löschen",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Ja, ich möchte mein Konto löschen",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Ich verstehe, dass nach <b>30 Tagen</b> mein Konto <b>{{customerName}}</b> ({{customerId}}) und alle damit verbundenen Daten dauerhaft gelöscht werden.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Bitte bestätigen Sie, indem Sie Ihren Kontonamen eingeben",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "Der eingegebene Kontoname stimmt nicht mit dem Namen des Kontos überein, das Sie zu löschen versuchen.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Konto löschen",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "Konto konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.",
  "BILLING_PROFILE": "Zahlungsprofil",
  "BILLING_PHONE_NUMBER": "Telefonnummer",
  "INVOICE_ADDRESS": "Rechnungsanschrift",
  "PAYMENT_METHOD": "Zahlungsmethode",
  "UPCOMING_INVOICE": "Bevorstehende Rechnung",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "In der Testphase",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Das Testen unseres Produkts während der Testphase ist kostenlos. Sind Sie bereit, nach dem Testzeitraum weiterzumachen? Fügen Sie eine Zahlungsmethode hinzu, um Ihre erste Rechnung zu erhalten.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Das Testen unseres Produkts während der Testphase ist kostenlos. Nach Ablauf des Testzeitraums erhalten Sie diese nächste Rechnung.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Nach der Testversion fälliger Betrag",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "Keine anstehenden Rechnungen",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "Ihr Plan wurde gekündigt. Es gibt keine anstehenden Rechnungen. Ihr Abrechnungszeitraum endet am ",
  "CARD_HOLDER_NAME": "Name des Karteninhabers",
  "CARD_HOLDER_NAME_PLACEHOLDER": "z.B. Max Mustermann",
  "ADD_CARD": "+ Zahlungsmethode hinzufügen",
  "MAKE_DEFAULT": "Als Standard wählen",
  "EXPIRES": "läuft ab",
  "PAYMENT_METHOD_REMOVE_TITLE": "Zahlungsmethode entfernen",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Sind Sie sich sicher, dass Sie diese Zahlungsmethode entfernen möchten??",
  "PLAN_LEAF": "Blatt",
  "PLAN_BRANCH": "Zweig",
  "PLAN_TREE": "Baum",
  "PLAN_FOREST": "Wald",
  "PLAN_LEAF_DESCRIPTION": "Für professionelle Formulare",
  "PLAN_BRANCH_DESCRIPTION": "Für bessere Datenverarbeitung",
  "PLAN_TREE_DESCRIPTION": "Für die ganze Firma",
  "PLAN_FOREST_DESCRIPTION": "Für Unternehmen",
  "PLAN_LEAF_FEATURES_HEADER": "Funktionen",
  "PLAN_BRANCH_FEATURES_HEADER": "Alle Funktionen des Blatt-Plans +",
  "PLAN_TREE_FEATURES_HEADER": "Alle Funktionen des Zweig-Plans +",
  "PLAN_FOREST_FEATURES_HEADER": "Alle Funktionen des Baum-Plans +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Ein Nutzer",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Unbegrenzte Nutzeranzahl",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} kostenlose Submissions",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} Bäume",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "0,40€ für jede weitere Submission",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "0,40€ für jede weitere Submission",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "0,40€ für jede weitere Submission",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "0,10€ für jede weitere Submission",
  "FEATURE_FORM_BUILDER": "Form Builder",
  "FEATURE_APP": "App",
  "FEATURE_EXCEL_EXPORT": "Excel-Export",
  "FEATURE_EMAIL": "E-Mail mit PDF-Anhang",
  "FEATURE_RULES": "Regeln",
  "FEATURE_API": "API-Zugriff",
  "FEATURE_MARKETPLACE_TEMPLATES": "MoreApp-Vorlagen",
  "FEATURE_TASKS": "Aufgaben",
  "FEATURE_LOGO_PDF": "Eigenes Logo in PDF-Bericht",
  "FEATURE_DATA_IMPORT": "Datenimport",
  "FEATURE_MULTIPLE_PDF": "Mehrere E-Mails",
  "FEATURE_ADVANCED_PDF": "Benutzerdefiniertes PDF",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Automatischer Datenimport",
  "FEATURE_SINGLE_SIGN_ON": "Single Sign-On (SSO)",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Single Sign-On (SSO)",
  "FEATURE_SENDING_DOMAIN": "Personalisierte E-Mail-Domain",
  "FEATURE_DEDICATED_SUPPORT": "Individueller Support",
  "VAT": "Mwst.",
  "APPLIED_BALANCE": "Angewandter Betrag",
  "AMOUNT_DUE": "Fälliger Betrag",
  "REMAINING_BALANCE": "Verbleibender Betrag",
  "CREDIT_CARD": "Kreditkarte",
  "SEPA_DIRECT_DEBIT": "SEPA-Lastschriftmandat",
  "PAY_ON_FILE": "auf Rechnung",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Diese Zahlungsmethode ist in Ihrem aktuellen Plan nicht verfügbar",
  "SEPA_MANDATE": "Durch die Bereitstellung Ihrer IBAN ermächtigen Sie (A) MoreApp und unseren Zahlungsdienstleister Stripe, Zahlungsanweisungen an Ihre Bank zu senden, um Ihr Konto dementsprechend zu belasten. Zudem bevollmächtigen Sie (B) Ihre Bank, Ihr Konto gemäß dieser Zahlungsanweisungen zu belasten. Dieses Mandat ist nur für Business-to-Business-Transaktionen vorgesehen. Sie haben keinen Anspruch auf eine Rückerstattung von Ihrer Bank, nachdem Ihr Konto belastet wurde. Sie sind jedoch berechtigt, Ihre Bank aufzufordern, Ihr Konto erst an dem Tag zu belasten, an dem die Zahlung fällig ist.",
  "INVOICE_NUMBER": "Rechnungsnummer",
  "INVOICE_DATE": "Datum",
  "INVOICE_TOTAL": "Gesamtbetrag",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "aktueller Status",
  "BASE_MONTH": "monatlicher Plan",
  "BASE_YEAR": "jährlicher Plan",
  "FORMS_MONTH": "monatliche Nutzung",
  "FORMS_YEAR": "jährliche Nutzung",
  "BRANDING_MONTH": "monatliches Branding",
  "BRANDING_YEAR": "jährliches Branding",
  "ADDON_MONTH": "monatliche Add-Ons",
  "ADDON_YEAR": "jährliche Add-Ons",
  "MAINTENANCE_FTPS_MONTH": "monatliche FTPS-Wartung",
  "MAINTENANCE_FTPS_YEAR": "jährliche FTPS-Wartung",
  "MANUAL_INVOICE_DESCRIPTION": "Rechnungen werden an Ihre hinterlegte Abrechnungs-E-Mail-Adresse versendet",
  "USAGE_DAILY": "Täglich",
  "USAGE_MONTHLY": "Monatlich",
  "MONTH": "Monat",
  "YEAR": "Jahr",
  "INVOICE_REFERENCE": "zusätzliche Rechnungsinformationen",
  "INVOICE_REFERENCE_DESCRIPTION": "Diese Information wird der Rechnung für Ihre Verwaltung hinzugefügt, zum Beispiel eine Bestellnummer.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Diese Änderung ist ab sofort gültig",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Diese Änderung wird mit Ihrer nächsten Rechnungsstellung gültig",
  "TREES_PLANTED": "gepflanzte Bäume",
  "TREES_REVENUE_COMMITMENT": "Wir tragen 1 % unseres Umsatzes zu Wiederaufforstungsprojekten bei",
  "DOWNGRADE_QUESTION": "Aus welchem Grund möchten Sie Ihren Plan ändern?",
  "DOWNGRADE_NOT_NEEDED": "Wird nicht mehr benötigt",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Bemerkungen",
  "DOWNGRADE_ALTERNATIVE": "Alternative gefunden",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Welche Alternative?",
  "DOWNGRADE_MISSING_FEATURE": "Funktion nicht vorhanden",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "Welche Funktion wird vermisst?",
  "DOWNGRADE_PRICING": "Zu teuer",
  "DOWNGRADE_PRICING_DESCRIPTION": "Bemerkungen",
  "DOWNGRADE_UNSATISFIED": "Nicht zufrieden",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "Was können wir verbessern?",
  "DOWNGRADE_OTHER": "Sonstige",
  "DOWNGRADE_OTHER_DESCRIPTION": "Wie lautet Ihr Grund?",
  "SELECT_OPTION": "Wählen Sie eine Option",
  "CONTACT_BUTTON": "Kontaktieren Sie uns",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/tours-de",
  "PRICE_LABEL_NOW": "Jetzt",
  "PRICE_LABEL_UPCOMING": "Ab Januar 2025",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Jährliche Abrechnung",
  "BILLING_FLOW_STEP1_LABEL": "Plan wählen",
  "BILLING_FLOW_STEP2_LABEL": "Rechnungsdetails",
  "BILLING_FLOW_STEP3_LABEL": "Zahlungsmethode",
  "BILLING_FLOW_STEP4_LABEL": "Bestätigen",
  "ONETIME": "Einmalig",
  "MONTHLY": "Monatlich",
  "YEARLY": "Jährlich",
  "RECURRENCE": "Wiederauftreten",
  "BRANDING": "Branding",
  "RESTORE": "Wiederherstellen",
  "RESTORE_FORM_MESSAGE": "Wenn Sie dieses Formular wiederherstellen, wird es erneut in den Formularen auf der Plattform angezeigt. In der App wird ebenfalls angezeigt, ob das Formular veröffentlicht wurde.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Erstellen Sie kostenlos Ihren Account",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "14-Tage-Testversion starten",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "E-Mail-Adresse",
  "SIGN_UP_CUSTOMER_PASSWORD": "Passwort",
  "SIGN_UP_CUSTOMER_BUTTON": "Account erstellen",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@unternehmen.de",
  "EMAIL_VALIDATION_VALIDATING": "E-Mail-Adresse wird geprüft…",
  "EMAIL_VALIDATION_FAILED": "Bei der Überprüfung Ihrer E-Mail Adresse ist ein Fehler aufgetreten. Daher konnte das Konto, welches Ihrer Accountbestätigung entspricht, nicht gefunden werden. Es kann jedoch sein, dass Ihr Konto bereits validiert wurde.",
  "EMAIL_VALIDATION_SUCCESS": "Ihre E-Mail Adresse wurde erfolgreich verifiziert",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Klicken Sie auf das Bild, um eine Pinnadel hinzuzufügen",
  "ALERT_TOUCH_DEVICE": "Unsere Plattform funktioniert auf mobilen Geräten nicht vollständig. Bitte benutzen Sie hierfür einen Laptop, einen PC- oder einen Apple-Computer",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>Sie haben eine abgelaufene Rechnung!</b>Gehen Sie sicher, dass Sie <a href ui-sref='portal.customers.billing.overview'> die offene Rechnung begleichen, </a> um weiterhin ausgefüllte Formulare einreichen zu können.",
  "ADD_VIEW_TEMPLATE_TYPE": "Verwendete Vorlage:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Leer",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Installiert",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Weitere Formulare erhalten Sie in unserem Marketplace",
  "BLANK_FORM": "Leer",
  "BLANK_FORM_DESCRIPTION": "Beginnen Sie mit einem leeren Formular",
  "AGREEMENTS_MODAL_TITLE": "Aktualisierung der allgemeinen Geschäftsbedingungen",
  "AGREEMENTS_MODAL_MESSAGE": "Wir haben unsere Nutzervereinbarung inklusive eines Nachtrags zur Datenverarbeitung aktualisiert. Bitte lesen Sie diese sorgfältig durch. Falls Sie Fragen oder Bedenken haben sollten, kontaktieren Sie uns bitte. Andernfalls haben Sie keine weiteren Maßnahmen zu ergreifen. Berücksichtigen Sie bitte das die aktualisierten AGBs ab dem 1. Februar 2019 wirksam werden.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Fortfahren",
  "ADD_PHOTO": "Foto hinzufügen",
  "CAPTURE_PHOTO": "Foto aufnehmen",
  "BROWSE_PHOTO": "Mediathek durchsuchen",
  "DRAW_SIGNATURE": "Unterschrift zeichnen",
  "START_DRAWING": "Mit dem Zeichnen beginnen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Hintergrundbild auswählen",
  "ADD_VIDEO": "Video hinzufügen",
  "SIGN_UP_THANKS": "Wir bedanken uns für Ihre Registration",
  "SIGN_UP_CHECK_MAIL": "Bitte überprüfen Sie Ihr E-Mail Postfach, um Ihre E-Mail-Adresse zu bestätigen.",
  "FORM_BUILDER": "Formular-Editor",
  "MOBILE_APP": "mobile App",
  "PDF_REPORTS": "PDF-Berichte",
  "EXCEL_EXPORT": "Excel-Export",
  "TASKS": "Aufgaben",
  "PDF_LOGO": "Benutzerdefiniertes PDF-Logo",
  "REALTIME_DATA_SOURCES": "Echtzeit-Datenquellen",
  "PDF_LAYOUT": "Benutzerdefiniertes PDF-Layout",
  "RULES": "Regeln",
  "API_ACCESS": "API-Zugriff",
  "COLOR_THEMES": "Farbdesign",
  "THEMES_SYSTEM": "Standarddesign",
  "THEMES_CUSTOM": "Benutzerdefiniertes Design",
  "GROUP_ROLES": "Gruppenrollen und -Berechtigungen",
  "CUSTOM_DOMAIN": "Personalisierte Domain",
  "DEDICATED_ACCOUNT_MANAGER": "Verantwortlicher Account-Manager",
  "PREMIUM_SUPPORT": "Premium-Kundenservice",
  "FILE_SELECT": "Datei auswählen",
  "NEW_CUSTOMER": "+ Neuen Kunden hinzufügen",
  "TO_PORTAL": "Zurück zum Portal",
  "EXPIRED": "Abgelaufen",
  "READ_MORE": "Lesen Sie mehr",
  "CHOOSE_A_PLAN": "Wählen Sie einen Plan",
  "TRIAL": "Testversion",
  "TRIAL_BANNER_TITLE": "Sie nutzen eine kostenlose Testversion",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} Tag(e) übrig",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} Submissions übrig",
  "TRIAL_BANNER_CTA_CONTACT": "Noch Fragen?",
  "TRIAL_BANNER_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-de",
  "UPGRADE_NOW": "Plan wählen",
  "CONTACT_SALES": "Noch Fragen?",
  "BANNER_DELETION_LABEL": "Ihr Konto wird voraussichtlich in {{days}} Tag(en) gelöscht",
  "BANNER_DELETE_TODAY_LABEL": "Ihr Konto wird heute gelöscht",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Wählen Sie einen Plan, um MoreApp weiterhin zu nutzen",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Bitte wählen Sie einen Plan aus, um MoreApp weiter zu nutzen. Andernfalls wird dieses Konto in {{days}} Tag(en) gelöscht.",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Kein aktiver Plan",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Bitte wählen Sie einen Plan, um fortzufahren",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Wichtig:</b> Aufgrund geplanter <a href='https://help.moreapp.com/de/support/solutions/articles/13000100648' target='_blank'>Wartungsarbeiten</a> wird MoreApp am Mittwoch, den 19.04.2023 zwischen 19:00-21:00 Uhr MEZ nicht verfügbar sein.",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Wichtig:</b> Aufgrund geplanter Wartungsarbeiten wird unser Produkt am Mittwoch, den 19.04.2023 zwischen 19:00-21:00 Uhr MEZ nicht verfügbar sein.",
  "BANNER_OVER_USAGE_TITLE": "Sie haben die Anzahl der kostenlosen Submissions überschritten.",
  "BANNER_OVER_USAGE_DESCRIPTION": "✈️ {{ nrOfSubmissionsOverIncluded }} extra Submissions 💰 {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }} extra Kosten",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Plan wählen",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Noch Fragen?",
  "BANNER_OVER_USAGE_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-de",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "Ihre Kreditkarte ist abgelaufen.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Bitte geben Sie eine gültige Zahlungsmethode an.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Zahlungsmethode aktualisieren",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Sie haben eine unbezahlte Rechnung.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Bitte bezahlen Sie Ihre Rechnung, bevor Ihr Konto gesperrt wird.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Rechnung bezahlen",
  "BANNER_UNPAID_INVOICE_TITLE": "Ihr Konto ist aufgrund einer unbezahlten Rechnung gesperrt.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Bezahlen Sie Ihre Rechnung, um die Sperrung aufzuheben.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Rechnung bezahlen",
  "BANNER_PAYMENT_DISPUTE_TITLE": "Es ist ein Zahlungskonflikt aufgetreten. Ihr Konto wird gesperrt.",
  "BANNER_PAYMENT_DISPUTE_PRIMARY_ACTION": "Support kontaktieren",
  "BANNER_PAYMENT_DISPUTE_MAIL_SUBJECT": "Zahlungskonflikt",
  "BANNER_PAYMENT_DISPUTE_MAIL_BODY": "Hallo Support,\n\nich möchte einen Zahlungskonflikt klären.\n\nMit freundlichen Grüßen,\n\n",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Spaltenübersicht wählen",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Datenquelle bearbeiten",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Excel-Datei hochladen",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Import durchführen",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Suche",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Aktivieren",
  "DATA_SOURCES_TITLE": "Datenquellen",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Letzte Aktualisierung",
  "DATA_SOURCES_GENERAL_INFORMATION": "Allgemeine Informationen",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Letzte erfolgreiche Aktualisierung",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Warnungen anzeigen",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "URL-Konfiguration",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Importintervall für URL festlegen",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Importiert…",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Importintervall für Excel festlegen",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Importiert…",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Letzte Warnungen",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Alle {{numberOfWarningMessages}} anzeigen)",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Weniger anzeigen )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Excel-Datei hochladen",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "Die gewählte Datei ist zu groß, um hochgeladen zu werden. Das Limit für eine Excel-Datei beträgt 10MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Wählen Sie die zu importierende Excel-Datei aus. Stellen Sie sicher, dass das verwendete Excel-Format einer ‘.xlsx’- oder ‘.xls’-Datei entspricht. Andere Formate können nicht importiert werden!",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "Dateien hier ablegen",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "HTML5 Drop-Dateien werden nicht unterstützt!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Eintragungsdetails",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameter",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Letzter Aktualisierungstyp",
  "DATA_SOURCES_SEARCH_LABEL": "Datenquelle",
  "DATA_SOURCES_ADD_BUTTON": "+ Datenquelle hinzufügen",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Datenquelle hinzufügen",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Datenquelle bearbeiten",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Name",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Geben Sie einen Namen für die Datenquelle ein ",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Typ",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Geben Sie eine URL ein, von welcher die Daten abgerufen werden sollen",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Parameter",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Parameter hinzufügen",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "Keine Parameter",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Key",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Wert",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Request-Headers",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Request-Header hinzufügen",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Schlüssel",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Wert",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Automatischer Import",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Grundlegende Authentifizierung",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Authentifizierung mit Google-Account",
  "AUTHENTICATED_WITH_GOOGLE": "Authentifizierung mit Google-Account",
  "CHANGE_ACCOUNT": "Account wechseln",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet-ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Werte zwischen /d/ und /edit innerhalb der URL Ihrer Spreadsheet-Datei",
  "DATA_SOURCE_GRID_NO_ENTRIES": "Es liegen keine Einträge für diese Datenquelle vor.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Einträge laden",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Einträge importieren",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Letzte fehlgeschlagene Aktualisierung",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Letzte Aktualisierung: Noch nie",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Letzte Aktualisierung",
  "DATA_SOURCE_WARNINGS_TITLE": "Import-Warnungen",
  "DATA_SOURCE_REMOVE_TITLE": "Datenquelle entfernen",
  "DATA_SOURCE_REMOVE_QUESTION": "Sind Sie sicher, dass Sie die Datenquelle löschen möchten? Dies kann nicht rückgängig gemacht werden!",
  "DATA_SOURCE_ENABLE_TITLE": "Datenquelle aktivieren",
  "DATA_SOURCE_ENABLE_MESSAGE": "Diese Datenquelle wurde deaktiviert, da mehr als zehn aufeinanderfolgende Importe fehlgeschlagen sind. Stellen Sie sicher, dass eine korrekte URL-Konfiguration vorliegt. Wenn Sie die Datenquelle erneut aktivieren, werden wir einen erneuten Importversuch unternehmen.",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Einstellungen",
  "SETTINGS_TAB_GENERAL": "Allgemeines",
  "SETTINGS_TAB_SSO": "Single Sign-On (SSO)",
  "SETTINGS_TAB_SENDING_DOMAIN": "E-Mail-Domain",
  "BILLING_SUCCESS_MESSAGE": "Erfolgreiche Aktualisierung der Rechnungsinformationen des Kunden.",
  "BILLING_DETAILS_HEADER": "Kunde",
  "BILLING_INVOICES_HEADER": "Kunde",
  "GENERAL_HEADER": "Allgemeines",
  "GENERAL_SUCCESS_MESSAGE": "Kundeneinstellungen wurden erfolgreich aktualisiert.",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Logo auswählen",
  "SETTINGS_DATE_FORMAT": "Datumsformat",
  "SETTINGS_CUSTOMER_NAME": "Kundenname",
  "SETTINGS_TAB_THEMES": "Designs",
  "SETTINGS_TAB_THEMES_CUSTOM": "Benutzerdefinierte Designs",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Design hinzufügen",
  "THEME_FORM": "Design",
  "THEME_FORM_NAME_LABEL": "Name",
  "THEME_FORM_BUTTON_LABEL": "Schaltfläche",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Speichertaste",
  "THEME_FORM_BAR_LABEL": "Leiste",
  "THEME_FORM_BACKGROUND_LABEL": "Hintergrund",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service-Konten",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API-Keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Neuer API-Key",
  "API_KEY_CREATED": "Neuer API-Key erstellt",
  "API_KEY_SAFETY": "Sicher aufbewahren",
  "API_KEY_CREATED_MESSAGE": "Bitte bewahren Sie diesen Key an einem sicheren Ort, wie einem Passwort-Manager auf. <br><b> Sie werden diesen anschließend nicht mehr einsehen können.</b",
  "API_KEY_REMOVE_TITLE": "API-Key löschen?",
  "API_KEY_REMOVE_MESSAGE": "Das Löschen dieses Keys kann dazu führen, dass Integrationen, die ihn verwenden, nicht mehr funktionieren! Dies kann nach dem Löschen nicht wiederhergestellt werden.",
  "WEBHOOK_DETAILS": "Webhook-Details",
  "WEBHOOK_ATTEMPTS": "Webhook-Versuche",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Anzahl der innerhalb der letzten 30 Tage unternommenen Zustellversuche eines Ereignisses, an Ihren Endpunkt.",
  "WEBHOOK_REQUEST_BODY": "Request-Body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "Die Nutzdaten, die wir an Ihren Endpunkt gesendet haben",
  "WEBHOOK_RESPONSE": "Antwort",
  "WEBHOOK_RESPONSE_MESSAGE": "Die Antwort, die wir von Ihrem Endpunkt erhalten haben",
  "WEBHOOK_REMOVE_TITLE": "Webhook entfernen?",
  "WEBHOOK_REMOVE_MESSAGE": "Möchten Sie den Webhook wirklich entfernen? Wussten Sie, dass Sie diesen auch in der Detailansicht <b> deaktivieren </b> können?",
  "ADD_SUBSCRIBER": "Abonnenten hinzufügen",
  "EVENT_TYPES": "Ereignistypen",
  "SELECT_EVENT_TYPES": "Wählen Sie die Ereignistypen aus, die Sie abonnieren möchten",
  "SECRET": "Versteckt",
  "SERVICE_ACCOUNTS_NAME": "Name",
  "SERVICE_ACCOUNTS_TYPE": "Typ",
  "SERVICE_ACCOUNTS_ACTIONS": "Aktionen",
  "SERVICE_ACCOUNTS_RECONNECT": "Erneut verbinden",
  "SERVICE_ACCOUNTS_DELETE": "Löschen",
  "SERVICE_ACCOUNTS_ADD": "Servicekonto hinzufügen",
  "SERVICE_ACCOUNTS_NON_FOUND": "Kein Servicekonto konfiguriert",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "Fehler beim Laden der Service-Konten",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "Fehler bei der Aktualisierung des Servicekontos",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "Fehler bei der Erstellung des Servicekontos",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "Fehler bei der erneuten Verbindung des Servicekontos",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "Fehler bei der Löschung des Servicekontos",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Servicekonto löschen",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Möchten Sie Ihr Servicekonto <b> '{{displayName}}' </b> wirklich löschen? <br><br> Formulare mit Integrationen, die dieses Servicekonto verwenden, werden anschließend nicht mehr funktionieren.",
  "GET_STARTED": "Legen Sie los",
  "MANAGE": "Verwalten",
  "SSO_DSYNC": "Directory Sync",
  "SSO": "Single Sign-On (SSO)",
  "SSO_DESCRIPTION": "Verbinden Sie Ihr Company Directory und die Anmeldemethode, um die Benutzerverwaltung zu vereinfachen.",
  "SSO_SHARED_WARNING": "Diese Single Sign-On (SSO)-Konfiguration wird von mehreren Kunden verwendet. Somit wirkt sich jede Änderung gleichzeitig auf alle aus.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Verwenden Sie Single Sign-On (SSO), um sich mit den Informationen anzumelden, die Sie für alle Ihre anderen Anwendungen verwenden.</h4><p></p><br><p>Diese Funktion ist ab dem <b>Wald</b>-Plan verfügbar.</p>",
  "SSO_CONNECTION_ACTIVE": "Aktiv",
  "SSO_CONNECTION_INACTIVE": "Inaktiv",
  "SSO_CONNECTION_DRAFT": "Entwurf",
  "SSO_CONNECTION_PENDING": "Ausstehend",
  "DSYNC_CONNECTION_LINKED": "Verbunden",
  "DSYNC_CONNECTION_UNLINKED": "Getrennt",
  "DSYNC_CONNECTION_PENDING": "Ausstehend",
  "SSO_STEP_1_TITLE": "Legen Sie los",
  "SSO_STEP_2_TITLE": "Single Sign-On (SSO) konfigurieren",
  "SSO_STEP_2_DESCRIPTION": "Mit Identity Provider verbinden",
  "SSO_STEP_3_TITLE": "Directory Synchronisation konfigurieren",
  "SSO_STEP_3_DESCRIPTION": "Synchronisieren Sie Ihre Gruppen und Nutzer, um diese auf dem neuesten Stand zu halten",
  "SSO_STEP_4_TITLE": "SSO aktiviert",
  "SSO_STEP_4_DESCRIPTION": "Ihre Nutzer können sich jetzt mit SSO anmelden",
  "SSO_IMPORTED_USERS": "{{count}} importierte Nutzer aus Ihrem Directory",
  "SSO_IMPORTED_GROUPS": "{{count}} importierte Gruppen aus Ihrem Directory",
  "SSO_NO_IMPORTED_USERS_GROUPS": "Keine importierten Nutzer oder Gruppen",
  "SENDING_DOMAIN_TITLE": "Eigene E-Mail-Domain einrichten",
  "SENDING_DOMAIN_DESCRIPTION": "Machen Sie E-Mails besser erkennbar und einheitlich für Ihr Unternehmen. Wir senden E-Mails (wie PDF-Berichte) von <b>meinunternehmen.com</b> anstelle von moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Diese E-Mail-Domain wird von mehreren Kunden verwendet, alle Änderungen wirken sich auf jeden aus.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Um zu einer anderen Domain zu wechseln, löschen Sie diese und erstellen Sie eine neue.",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domain",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domain",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "Die Domain, von der aus E-Mails gesendet werden, z.B. <a>meinunternehmen.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Standard-Von-Name",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Ein Name oder Titel, der für Ihre Empfänger erkennbar ist. In den meisten Fällen ist dies Ihr Unternehmensname.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "Standard-Von-E-Mail",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "Die Absender-E-Mail wird als Ursprungs-E-Mail-Adresse verwendet. Dies wird auch als Antwort genutzt.",
  "SENDING_DOMAIN_DELETE_TITLE": "Personalisierte E-Mail-Domain löschen?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Das bedeutet, dass Ihre E-Mails wieder von unserer Standarddomain gesendet werden. Sie benötigen die zugehörigen DNS-Einträge nicht mehr. Sie können diese von Ihrem Domain-Anbieter entfernen.",
  "SENDING_DOMAIN_VERIFY_TITLE": "DNS-Einträge hinzufügen",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Fügen Sie diese DNS-Einträge zu Ihrem Domain-Anbieter hinzu, um zu bestätigen, dass Sie Eigentümer dieser Domain sind. Die Überprüfung kann bis zu 48 Stunden dauern, während Ihre Datensatzänderungen weitergegeben werden.",
  "SENDING_DOMAIN_VERIFY_BTN": "Einträge prüfen",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Ungültige Domain '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "Von-E-Mail sollte von der Domain '{{domain}}' stammen",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>E-Mails (wie PDF-Berichte) von Ihrer eigenen E-Mail-Domain senden</h4><p>. Diese Funktion ist ab dem <b>Zweig</b>-Plan verfügbar.</p>",
  "SETTINGS_SUCCESS_MESSAGE": "Ihre Einstellungen wurden gesichert",
  "DYNAMIC_RECIPIENTS_ADD": "Empfänger hinzufügen",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Standard",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "E-Mail-Felder",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Weitere Felder",
  "TASKS_NEW": "Neue Aufgabe",
  "TASKS_PUBLISH_DATE": "Veröffentlicht",
  "TASKS_MESSAGE": "Nachricht",
  "TASKS_AND_X_OTHERS": "und {{others}} mehr",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "Beim Laden der Aufgabe ist ein Fehler aufgetreten. Entschuldigen Sie die Unannehmlichkeiten. Sie können versuchen, die Seite zu aktualisieren.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "Der ausgewählte Ordner wurde bisher nicht veröffentlicht. Um Aufgaben für diesen Ordner zu erstellen, müssen Sie ihn im Bereich Formulare veröffentlichen.",
  "TASK_ADD_TITLE": "Neue Aufgabe",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Nutzer und/oder Gruppe(n) auswählen",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} Nutzer",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "Diese Gruppe enthält keine Nutzer",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Nutzer in dieser Gruppe:",
  "TASK_ADD_MESSAGE_LABEL": "Nachricht",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "Es gibt keine Nutzer oder Gruppen mit Zugriff auf dieses Formular. Bitte geben Sie Ihren Nutzern Zugriff unter „Nutzer verwalten“ in der Formularübersicht.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Aufgabe senden",
  "TASK_DETAIL_TITLE": "Aufgabe",
  "TASK_DETAIL_MESSAGE": "Nachricht",
  "TASK_DETAIL_ACTIONS": "Aktionen",
  "TASK_DETAIL_ACTION_COMPLETE": "Abschließen",
  "TASK_DETAIL_ACTION_REVOKE": "Widerrufen",
  "TASKS_GRID_NO_ENTRIES": "Für dieses Formular liegen keine Aufgaben vor",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Sofort",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "In einer Stunde",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "In zwei Stunden",
  "TASKS_ADD_PUBLISH_TOMORROW": "Morgen",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Erledigt",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "Ausstehend",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Abgelehnt",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Widerrufen",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Abgeschlossen",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Eingefügte Submission in Zeile: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Aufgabe erstellt für:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "Es wurde keine Aufgabe erstellt, da es keine Empfänger gab",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "Der Bericht wurde per E-Mail gesendet an:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "Der Bericht wurde per E-Mail gesendet an:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Standardlogo ersetzen"
}
;