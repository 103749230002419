module.exports = {
  "FORMS": "Formularis",
  "FOLDERS": "Carpetes",
  "ADMINISTRATIVE": "Administratiu",
  "INVITE": "Convidar",
  "INVITES": "Invitacions",
  "USER_MANAGEMENT_OVERVIEW": "Vista general dels usuaris",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Informació de l'usuari",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Editar usuari",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Eliminar usuari",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Reenviar invitació",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Nom",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Admin",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Grups",
  "ACCOUNT_OWNER": "Titular del Compte",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "Adreça electrònica",
  "USER_MANAGEMENT_ALL_USERS": "Tots els Usuaris",
  "USER_MANAGEMENT_NEW_TITLE": "Nou Usuari(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Administrar Usuaris",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Permisos",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Admin",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "No hi ha cap grup",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Carpetes",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(per grup)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "No hi ha cap Carpeta",
  "USER_MANAGEMENT_EDIT_SECURITY": "Seguretat de l'Usuari",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Configuració de l'Usuari",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Nom d'usuari",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Nom",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Cognom",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Número de telèfon",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Zona de Risc",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Eliminar Usuari",
  "CUSTOMER_GROUPS_NEW_TITLE": "Nou Grup",
  "CUSTOMER_GROUPS_NAME_LABEL": "Nom",
  "CUSTOMER_GROUPS_NO_GROUPS": "No hi ha cap grup.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "No hi ha cap grup visible. Si us plau, canvia els filtres.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Membres del Grup",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Afegir Grup",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Membre de ",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Editar",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Eliminar",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Editar Grup",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Permisos del Grup",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Aquest grup pot accedir als formularis de les següents carpetes:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Permisos de la Plataforma",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Aquest grup pot gestionar:",
  "MANAGE_FORMS_LABEL": "Formularis & Carpetes",
  "MANAGE_REGISTRATIONS_LABEL": "Registres",
  "MANAGE_TASKS_LABEL": "Tasques",
  "MANAGE_USERS_LABEL": "Usuaris",
  "MANAGE_SSO_LABEL": "Inici de Sessión Únic",
  "MANAGE_DATA_SOURCES_LABEL": "Bases de dades",
  "MANAGE_TEMPLATES_LABEL": "Plantilles",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Transferir la Titularitat",
  "MANAGE_ACCOUNT_LABEL": "Configuració general del compte",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Eliminar Grup",
  "SELECT_GROUPS": "Seleccionar Grups",
  "USER_COUNT": "Conté {{value}} usuaris",
  "USER_COUNT_SINGLE": "Conté un usuari",
  "USER_COUNT_EMPTY": "No conté usuaris",
  "USER_MANAGEMENT_REMOVE_TITLE": "Eliminar Usuari",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "Segur que vols eliminar aquest usuari?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "No pots eliminar el teu propi usuari",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Sol·licita al titular del compte que t'elimini.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Eliminar usuari del grup",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "Segur que vols eliminar a l'usuari d'aquest grup?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Eliminar Invitació",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "Segur que vols eliminar aquesta invitació?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Eliminar la invitació del grup",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "Segur que vols eliminar la invitació d'aquest grup?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Eliminar Grup",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "Estàs segur que vols eliminar aquest grup? No podràs desfer aquesta acció! Tingues en compte que no s'eliminaran els usuaris que estan dintre d'aquest grup.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Els usuaris han estat convidats",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Has convidat a <strong>{{count}} usuaris</strong> a unir-se al teu compte.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "L'arxiu seleccionat supera els 5 MB",
  "USER_MANAGEMENT_INVITE_TYPE": "Quants usuaris vols convidar?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Un únic usuari",
  "USER_MANAGEMENT_INVITE_BULK": "Múltiples usuaris",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Selecciona un fitxer Excel que contingui una llista dels emails a la primera columna",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Idioma de la invitació",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "La invitació de l'usuari ha fallat",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "L'usuari ja ha estat afegit a aquest compte",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "El canvi en els permisos ha fallat",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "No s'ha pogut eliminar el grup d'usuaris, ja que això deixaria l'usuari actual sense el permís per administrar els usuaris",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "No s'ha pogut eliminar el permís, ja que això deixaria l'usuari actual sense el permís per administrar els usuaris",
  "ROLES": "Rols",
  "ROLES_DEFAULT": "Rols predeterminats",
  "ROLES_CUSTOM": "Rols personalitzats",
  "MANAGE_ROLES_TITLE": "Gestionar rols",
  "MANAGE_ROLES_DESCRIPTION": "Defineix els permisos de cadascun dels rols.",
  "MANAGE_BILLING": "Gestionar facturació",
  "MANAGE_CREDITS": "Gestionar facturació",
  "MANAGE_DATA_SOURCES": "Gestionar bases de dades",
  "MANAGE_FORMS": "Gestionar formularis i carpetes",
  "MANAGE_TASKS": "Gestiona totes les tasques",
  "MANAGE_REGISTRATIONS": "Gestionar tots els registres",
  "MANAGE_USERS": "Gestionar usuaris i accessos",
  "MANAGE_SSO": "Gestionar l'inici de sessió únic",
  "MANAGE_SENDING_DOMAIN": "Gestionar el domini de l'email",
  "MANAGE_WEBHOOKS": "Gestionar Webhooks",
  "MANAGE_TEMPLATES": "Gestionar plantilles",
  "MANAGE_ACCOUNT": "Gestionar el Compte",
  "TRANSFER_OWNERSHIP": "Transferir titularitat",
  "CUSTOMER_USER": "Accés a l'App",
  "CREATE_FOLDER": "Crear carpetes",
  "CREATE_FORM": "Crear formularis",
  "ADD_FORM": "Afegir el formulari a la carpeta",
  "REMOVE_FORM": "Eliminar el formulari de la carpeta",
  "UPDATE_FOLDER": "Actualitzar carpeta",
  "DELETE_FOLDER": "Eliminar carpeta",
  "CREATE_SUBMISSION": "Crear registre",
  "READ_SUBMISSION": "Veure registres",
  "DELETE_SUBMISSION": "Eliminar registres",
  "UPDATE_SUBMISSION": "Actualitzar registres",
  "READ_TASK": "Veure tasques",
  "UPDATE_TASK": "Completar tasques",
  "CREATE_TASK": "Crear tasques",
  "DELETE_TASK": "Anul·lar tasques",
  "UPDATE_FORM": "Actualitzar formulari",
  "ARCHIVE_FORM": "Eliminar formulari",
  "RESTORE_FORM": "Restaurar el formulari",
  "MANAGE_BILLING_TOOLTIP": "Actualitzar la teva subscripció, els detalls de facturació i la forma de pagament. Consultar les teves factures i el teu historial d'ús.",
  "MANAGE_CREDITS_TOOLTIP": "Actualitza la teva subscripció, els detalls de facturació i la forma de pagament. Consulta les teves factures i el teu historial d'ús.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Crear, visualitzar, actualitzar i eliminar totes les bases de dades",
  "MANAGE_FORMS_TOOLTIP": "Crear, visualitzar i actualitzar <b>TOTS</b> els formularis i carpetes",
  "MANAGE_TASKS_TOOLTIP": "Crear, visualitzar i actualitzar tasques per a <b>TOTS</b> els formularis",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Consultar, eliminar i reenviar registres de <b>TOTS</b> els formularis",
  "MANAGE_USERS_TOOLTIP": "Gestionar l'accés de tots els usuaris, invitacions i grups",
  "MANAGE_SSO_TOOLTIP": "Gestionar la configuració de l'Inici de Sessió Únic",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Gestionar la configuració del domini personalitzat de l'email",
  "MANAGE_WEBHOOKS_TOOLTIP": "Crear, visualitzar, actualitzar i eliminar tots els webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Crear, visualitzar, actualitzar i eliminar totes les plantilles ",
  "MANAGE_ACCOUNT_TOOLTIP": "Gestionar la configuració general del compte",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Gestionar la configuració general del compte",
  "CREATE_FOLDER_TOOLTIP": "Crear carpetes noves",
  "CREATE_FORM_TOOLTIP": "Crear dins d'una carpeta a la qual tingueu accés",
  "ADD_FORM_TOOLTIP": "Afegir/moure a una carpeta a la qual tinguis accés",
  "REMOVE_FORM_TOOLTIP": "Eliminar d'una carpeta a la qual tinguis accés",
  "UPDATE_FOLDER_TOOLTIP": "Actualitzar el nom, la descripció i la icona.",
  "DELETE_FOLDER_TOOLTIP": "Eliminar carpeta",
  "CREATE_SUBMISSION_TOOLTIP": "Enviar el formulari a l'aplicació per crear un nou registre",
  "READ_SUBMISSION_TOOLTIP": "Consultar els registres d'un formulari específic",
  "DELETE_SUBMISSION_TOOLTIP": "Elimina els registres d'un formulari específic",
  "UPDATE_SUBMISSION_TOOLTIP": "Actualitzar registres d'un formulari específic",
  "CREATE_TASK_TOOLTIP": "Crear tasques d'un formulari específic",
  "READ_TASK_TOOLTIP": "Consultar tasques d'un formulari específic",
  "UPDATE_TASK_TOOLTIP": "Completar tasques per un formulari específic",
  "DELETE_TASK_TOOLTIP": "Anul·lar tasques d'un formulari específic",
  "UPDATE_FORM_TOOLTIP": "Editar i publicar formulari",
  "ARCHIVE_FORM_TOOLTIP": "Eliminar formulari",
  "ALLOWED": "Si",
  "ALLOW": "Permetre",
  "DONT_ALLOW": "No permetre",
  "NOT_ALLOWED": "No",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Carpetes i Formularis",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Gestionar l'accés de tots els usuaris d'aquest grup.",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Aquest grup pot <b>gestionar tots els formularis i carpetes</b> mitjançant el rol esmentat anteriorment",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Aquest usuari pot <b>gestionar tots els formularis i carpetes</b> mitjançant el seu rol",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Gestionar l'accés d'aquest usuari.",
  "ACCESS_ON_ACCOUNT": "Compte",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Seleccionar un rol per a tots els usuaris d'aquest grup.",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Seleccionar un rol per aquest usuari.",
  "NEW_ROLE": "Crear un nou rol",
  "USER_IN_GROUPS_DESCRIPTION": "Aquest usuari forma part dels següents grups",
  "USER_NOT_IN_GROUP_DESCRIPTION": "Aquest usuari no forma part de cap grup",
  "ACCESS_VIA_GROUPS": "Accés via grups",
  "ROLE_ACCOUNT_OWNER": "Titular del Compte",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Administrador del Compte",
  "ROLE_OWNER": "Titular del Compte",
  "ROLE_USER": "Usuari del Compte",
  "ROLE_FORM_MANAGER": "Responsable del Formulari",
  "ROLE_FORM_USER": "Usuari del Formulari",
  "ROLE_FOLDER_MANAGER": "Responsable de la Carpeta",
  "USER_DESCRIPTION": "Informació de l'Usuari",
  "ROLE_DELETE_TITLE": "Eliminar rol",
  "ROLE_DELETE_MESSAGE": "Només pots eliminar rols que no s'utilitzen. Assegura't d'actualitzar primer tots els usuaris/grups que utilitzen aquest rol.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "Extern",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Aquest grup se sincronitza des del teu directori extern i no es pot modificar.",
  "EXTERNAL_DIRECTORY_USER_LABEL": "Extern",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Aquest usuari se sincronitza des del teu directori extern i no es pot modificar.",
  "USER_SUSPENDED": "Suspès",
  "USER_SUSPENDED_TOOLTIP": "Aquest usuari ha estat suspès del teu directori extern",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "No tens permís per transferir la titularitat",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "No es permet transferir la titularitat a un usuari convidat. L'usuari ha dacceptar la invitació primer.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "No es permet transferir la titularitat",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "És necessari ser titular del compte per poder transferir la titularitat a un altre usuari",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Transferir titularitat",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "¿Segur que vols transferir la titularitat a <em>{{firstName}} {{lastName}} ({{username}})</em>?<br /> <br /> <b>Nota:</b> Perdràs la titularitat del compte.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Titularitat transferida",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> és ara titular del compte.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "Email",
  "MENU_RULES": "Regles",
  "MENU_HOOKS": "Integracions",
  "SUB_FORM_NO_LONGER_USED_TITLE": "El subformulari ja no s'utilitza",
  "SUB_FORM_NO_LONGER_USED_BODY": "El subformulari no s'utilitza en cap altre lloc de la carpeta. Vols eliminar el subformulari?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Aquests subformularis no s'utilitzen en cap altre lloc de la carpeta. Vols eliminar els subformularis?",
  "UNSUPPORTED_FILE_TYPE": "Només s'admeten els següents tipus de fitxers: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Aquesta versió del formulari no es pot editar",
  "FORM_FINALIZED_MESSAGE": "Si vols editar el teu formulari, torna a la Vista General i fes clic a 'Editar'.",
  "PUBLISH_FAILED_TITLE": "No s'ha pogut publicar el teu formulari",
  "PUBLISH_FAILED_MESSAGE": "No et preocupis; el formulari s'ha desat correctament, però no s'ha pogut publicar. Torna-ho a provar més tard. Si el problema persisteix, posa't en contacte amb nosaltres.",
  "INTEGRATION_EOL_MESSAGE": "Aquest formulari utilitza una integració que s'ha desactivat. Si us plau, elimina-la o substitueix-la per una altra integració o webhook. <a href=\"https://help.moreapp.com/es/support/tickets/new\"> Contacta amb suport</a> si necessites ajuda.",
  "USER_PICKER_ADD_USER": "Convidar usuari(s)",
  "USER_PICKER_ADD_GROUP": "Crear un grup nou",
  "USER_PICKER_ALL_USERS": "Tots els usuaris",
  "USER_PICKER_INVITES": "Convidar",
  "USER_PICKER_INVITED": "(Convidat)",
  "COLLECTION_PICKER_ADD_FORM": "Nou Formulari",
  "COLLECTION_PICKER_ADD_COLLECTION": "Nova Carpeta",
  "SEARCH_FORMS_PLACEHOLDER": "Cerca carpetes i formularis",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Seleccionar Columnes",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Cercar",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Vista",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Eliminar Tot",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "Estàs completament segur?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "Segur que vols eliminar tots els registres d'aquest formulari? Aquesta acció no es pot desfer!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Aquesta acció elimina TOTS els registres d'aquest formulari, no només els que has seleccionat",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Si us plau, introdueix el teu número de client (ID) per confirmar",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Exportar",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Exportar Tot",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Exportar Filtrat",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Exportar Seleccionats",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Incloure imatges",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Incloure fitxers",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Exportar en un sol full d'Excel",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "L'exportació pot prendre una estona",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Estàs a punt d'exportar una gran quantitat de registres. La descàrrega pot prendre una estona.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "No hi ha usuaris per aquest formulari.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Seleccionat",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Desseleccionar",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Detalls del Registre",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Eliminar Registre",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Eliminar registre permanentment",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "Estàs segur que vols eliminar aquest registre? Aquesta acció no es pot desfer!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Reenviar Registre",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Reenviar Registre",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "Vols reenviar aquest registre?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "S'està enviant el registre",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Error en reenviar el registre. Contacta amb l'equip de suport si el problema persisteix.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "Email amb PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Destinataris",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Informació",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Usuari",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Dades",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Tots els fitxers",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Localització",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Mostrar a Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "No s'ha registrat",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Data d'enviament",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Dispositiu",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Xarxa",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "Versió de l'aplicació",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Tasca",
  "SERIAL_NUMBER": "Número de sèrie",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integracions",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Reintenta el pas {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Descarrega l'informe",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Següent intent en",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "Email",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Descarregar PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Intent de reenviament",
  "DOWNLOAD": "Descarregar",
  "MAIL_STATUS_SEND": "{{number}} enviats",
  "MAIL_STATUS_QUEUED": "{{number}} en cua",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} rebutjats",
  "BAD_MAILBOX": "Bad mailbox",
  "INVALID_DOMAIN": "Invalid domain",
  "IMAGES": "Imatges",
  "UNPAID_SUBMISSION": "Aquest enviament encara no s'ha processat.",
  "TRY_BETA_EXPORT": "Prova la nova versió beta",
  "EXPORT_ALL_COLUMNS": "Exportar totes les columnes",
  "SELECTED_COLUMNS": "Seleccionar columnes",
  "EXPORT_OPTIONS_TIMEZONE": "Zona horària",
  "EXPORT_SUCCESS_TITLE": "Exportació iniciada amb èxit",
  "EXPORT_SUCCESS_MESSAGE": "La teva exportació s'ha iniciat. Una vegada finalitzi, s'enviarà a <b>{{email}}</b>. Depenent del volum de l'exportació, pot requerir fins a diverses hores.",
  "EXPORT_SERVICE_UNAVAILABLE": "L'exportació no està disponible en aquest moment. Si us plau, intenta-ho més tard.",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "Les exportacions que inclouen fitxers estan limitades a {{limit}} registres",
  "MORE_INFORMATION": "Més informació",
  "SELECTABLE_REORDARABLE_DONE": "Fet",
  "ACCEPT_CUSTOMER_INVITE": "Vols acceptar la invitació de '{{customer}}'?",
  "ACCEPT_BUTTON": "Acceptar",
  "ACTIVATION_FORM_TITLE": "Activació del Compte",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Informació del Client",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Nom",
  "ACTIVATION_USER_INFORMATION_TITLE": "Informació de l'Usuari",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Nom",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Cognom",
  "ACTIVATION_PASSWORD": "Contrasenya",
  "ACTIVATION_PASSWORD_CONFIRM": "Confirmació de la Contrasenya",
  "ACTIVATION_PASSWORD_MISMATCH": "Les contrasenyes no coincideixen. Assegura't que la contrasenya coincideixi amb la de confirmació.",
  "ACTIVATION_FORM_TOC": "Termes i Condicions",
  "ACTIVATION_FORM_TOC_MESSAGE": "Estic d'acord amb ",
  "ACTIVATION_SUBMIT_BUTTON": "Activar",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Entrar Token",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "La teva invitació ha caducat o no és vàlida",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Si us plau, demana al teu administrador que t'enviï una nova invitació",
  "ACTIVATION_CHECK_TOKEN": "Comprovar Token",
  "ACTIVATION_SUCCESS": "El teu compte s'ha activat correctament!",
  "LICENSE_AGREEMENT": "Acord de Llicencia",
  "PRIVACY_POLICY": "Política de Privacitat",
  "SECURITY_STATEMENT": "Declaració de Seguretat",
  "LOGIN_ON_MOBILE_APP": "Inicia sessió a l'aplicació mòbil:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Descarrega l'app al teu dispositiu Android o iOS per començar.",
  "OR": "o",
  "SCAN_QR_CODE": "Escaneja el codi QR",
  "NO_SMARTPHONE": "No disposes d'un smartphone ara mateix?",
  "OPEN_IN_WEBCLIENT": "Obrir al navegador",
  "ICON_PICKER_SELECT": "Seleccionar Icona",
  "ICON_PICKER_MODAL_TITLE": "Seleccionador d'Icones",
  "MORE_GRID_VIEW": "Vista",
  "MORE_GRID_LOAD_MORE": "Carregar més",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Detalls",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Detalls",
  "DATE_FILTER_FROM_LABEL": "De",
  "DATE_FILTER_TO_LABEL": "A",
  "DATE_FILTER_PLACEHOLDER": "Seleccionar data",
  "NO_RESULTS_FOUND": "No s'han trobat resultats",
  "CANVAS_NOT_SUPPORTED_TITLE": "Signatura no compatible",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "No pots utilitzar aquesta funció a la Plataforma. Només és compatible amb l'aplicació mòbil i el Webclient.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Foto no compatible",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "No pots utilitzar aquesta funció a la Plataforma. Només és compatible amb l'aplicació mòbil i el Webclient.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Biblioteca de fotos no compatible",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "No pots utilitzar aquesta funció a la Plataforma. Només és compatible amb l'aplicació mòbil i el Webclient.",
  "BARCODE_SCAN_FAILURE": "Escàner de codi de barres no compatible",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "No pots utilitzar aquesta funció a la Plataforma. Només és compatible amb l'aplicació mòbil.",
  "DATE_MODAL_TITLE": "Seleccionador",
  "WIDGET_DETAIL_LINK": "Permetre que el subformulari es reutilitzi en altres widgets-Subformulari",
  "WIDGET_DETAIL_UNLINK": "Aquest widget-Subformulari està vinculat a un altre subformulari",
  "WIDGET_DETAIL_NEW_TITLE": "Nou Subformulari",
  "WIDGET_DETAIL_REMOVE_TITLE": "Eliminar Subformulari",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Seleccionar un subformulari existent",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Els canvis seran aplicats a altres widgets que utilitzin aquest subformulari",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Utilitzat en {{value}} widget(s)",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "No utilitzat",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "Segur que vols eliminar aquest subformulari?",
  "COLLECTION": "Carpeta",
  "DESCRIPTION": "Descripció",
  "LOGIN": "Iniciar Sessió",
  "LOGIN_USERNAME": "Nom d'usuari",
  "LOGIN_PASSWORD": "Contrasenya",
  "LOGIN_SIGN_IN_BUTTON": "Iniciar Sessió",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Combinació de nom d'usuari/contrasenya incorrecte",
  "LOGIN_FORGOT_PASSWORD_LINK": "Has oblidat la teva contrasenya?",
  "LOGIN_NO_ACCOUNT_MESSAGE": "¿No tens compte? ¡Selecciona el teu pla aquí!",
  "LOGOUT_SUCCESSFUL_TITLE": "S'ha tancat sessió",
  "LOGOUT_SUCCESSFUL_MESSAGE": "La teva sessió ha estat tancada",
  "LOGOUT_SUCCESSFUL_ACTION": "Iniciar sessió de nou",
  "FORGOT_PASSWORD_TITLE": "Has oblidat la teva contrasenya?",
  "FORGOT_PASSWORD_EMAIL": "Email",
  "FORGOT_PASSWORD_USERNAME": "Nom d'usuari",
  "FORGOT_PASSWORD_SUBMIT": "Enviar",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Adreça electrònica incorrecta",
  "FORGOT_PASSWORD_EMAIL_SENT": "La teva contrasenya ha estat reenviada. Segueix l'enllaç que hem enviat la teva adreça electrònica per restablir la teva nova contrasenya.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Tornar a l'inici de sessió",
  "ACTIVATE_USER_TITLE": "Activar usuari",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Nova contrasenya",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Nova contrasenya (verificació)",
  "MENU_HELP": "Ajuda",
  "MENU_REGIONS": "Regions",
  "MENU_CUSTOMERS": "Clients",
  "MENU_LOGOUT": "Tancar sessió",
  "MENU_USERS": "Usuaris",
  "MENU_REGISTRATIONS": "Registres",
  "MENU_FORMS": "Formularis",
  "MENU_TEMPLATES": "Plantilles",
  "MENU_SETTINGS": "Configuració",
  "MENU_API_DOC": "Docs",
  "MENU_DOCUMENTATION": "Emails",
  "MENU_SUPPORT": "Suport",
  "MENU_PLUGINS": "Plugins",
  "MENU_TRANSACTIONS": "Transaccions",
  "MENU_BILLING": "Facturació",
  "MENU_FINANCIAL": "Finances",
  "MENU_CONTACT": "Contactar",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Escollir client",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Dades",
  "MENU_PROFILE": "Perfil",
  "MENU_ONBOARDING": "Registre",
  "MENU_QUEUE": "Queue",
  "PROFILE_INFO": "Info",
  "PROFILE_EMAIL_VALID": "L'email s'ha validat correctament",
  "PROFILE_EMAIL_NOT_VALIDATED": "L'email encara no s'ha validat",
  "SUBMITTED": "Enviat",
  "SENT": "Enviat",
  "ACCEPTED": "Acceptat",
  "REVOKED": "Revocat",
  "REJECTED": "Rebutjat",
  "CONFIRM": "Confirmar",
  "AWAITING_PACKAGE_UPLOAD": "S'està esperant la càrrega del paquet",
  "RESELLER": "Distribuïdor",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Configuració",
  "CUSTOMER_APPLICATION_OPERATIONS": "Acció de Carpeta",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "La carpeta només es pot eliminar si està buida",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Total de formularis enviats",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Formularis enviats",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Cercar...",
  "ADD_FORM_PANEL_TITLE": "Afegir un formulari",
  "ADD_FORM_PANEL_TITLE_LABEL": "Títol del formulari",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Títol del formulari",
  "ADD_FORM_PANEL_SAVE": "Desar",
  "ADD_FORM_PANEL_ACTIONS_CREATE_WITH_BETA": "Crear (Beta)",
  "ADD_TEMPLATE": "Afegir una plantilla",
  "MARK_GLOBAL": "Marcar com a global",
  "UNMARK_GLOBAL": "Desmarcar com a global",
  "MARK_GLOBAL_TOOLTIP": "Només disponible com a ADMIN de MoreApp. Fes clic a 'Marcar com a global' perquè estigui disponible públicament a la pestanya \"Nou Formulari\"",
  "ADD_APPLICATION_PANEL_TITLE": "Afegir una carpeta",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Nom de la carpeta",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Nom",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Eliminar formulari",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "Estàs segur que vols eliminar aquest formulari?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Copiar formulari",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Escollir una carpeta per copiar el formulari",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Carpeta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Carpeta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Nom de la còpia",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publicar",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Administrar Usuaris",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Copiar",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Eliminar",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Copiar carpeta",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Client",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Publicar carpeta",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "Estàs segur que vols publicar aquesta carpeta?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "Carpeta publicada correctament!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Error en publicar la carpeta a causa de motius desconeguts. Si us plau, consulta les propietats de definició que falten. Si no pots resoldre el problema, si us plau, posa't en contacte amb el teu partner.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Error en publicar la carpeta. Si us plau, arregla els errors següents i reintenta-ho:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "L'intent anterior tenia els errors següents:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Mostrar errors previs",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publicar",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Posar al Marketplace",
  "INVALID_APPLICATION_HEADER": "Errors de l'aplicació:",
  "INVALID_FORM_HEADER": "Problemas del formulari:",
  "INVALID_APPLICATION_START_PAGE": "La carpeta ha de tenir una pàgina d'inici",
  "INVALID_APPLICATION_NAME": "La carpeta ha de tenir un nom",
  "INVALID_APPLICATION_THEME": "La carpeta ha de tenir un tema",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Configuració de cerca \"Ítem markup\" no és vàlid",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "<code>{{fieldName}}</code> té propietats no vàlides",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "<code>{{fieldName}}</code> té una propietat no vàlida <code>{{property}}</code>",
  "INVALID_VIEW_ITEM_DATA_NAME": "<code>{{fieldName}}</code> té un nom de dades no vàlid",
  "INVALID_VIEW_ITEM_WIDGET_UID": "<code>{{fieldName}}</code> widget no està instal·lat",
  "INVALID_VIEW_ITEM_VIEW_ID": "<code>{{fieldName}}</code> no fa referència a una vista coneguda",
  "INVALID_VIEW_ITEM_MAIL": "Placeholder no vàlid a la plantilla d'email a prop de <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Destinataris dinàmics no vàlids en la plantilla d'email a prop de <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Destinatari erroni en l'email: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Regla no vàlida amb el nom de <code>{{ fieldName }}</code>",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Gestió d'Usuaris",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Usuaris que tenen accés",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Cap usuari",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "usuari",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "usuaris",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Eliminat ",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Grups que tenen accés",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "No hi ha cap grup",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Defineix qui pot accedir i utilitzar aquest formulari",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Defineix qui pot afegir formularis a aquesta carpeta",
  "CONTACT": "Contacte",
  "ADDITIONAL_FEES_APPLY": "S'apliquen costos addicionals",
  "CUSTOMER_CONTACT_VAT_NUMBER": "CIF",
  "CUSTOMER_CONTACT_COC_NUMBER": "CdC",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Suport",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Hem rebut la teva notificació i farem l'acció apropiada.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Hi ha hagut un error en enviar la teva notificació. Contacta amb nosaltres per informar sobre aquest problema.",
  "BILLING_INFO": "Informació de facturació",
  "CONTACT_INFO": "Informació de contacte",
  "INVOICES": "Factures",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Compte",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Seleccionar client",
  "COLLECTION_REMOVE_MODAL_TITLE": "Eliminar carpeta",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "Segur que vols eliminar aquesta carpeta? Aquesta acció no es pot desfer!",
  "COPY_FORM_MODAL_TITLE": "Copiar formulari",
  "COPY_FORM_MODAL_MESSAGE": "Escull una carpeta on copiar el formulari i, si ho prefereixes, canvia el nom",
  "COPY_FORM_MODAL_NAME_LABEL": "Nom",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Carpeta objectiu",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Seleccionar la carpeta on copiar el formulari",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Utilitza opcionalment un nom diferent per a la còpia.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "El compte al qual es copia aquest formulari.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "La còpia es col·locarà en aquesta carpeta.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Eliminar vista",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "Estàs segur que vols eliminar aquesta vista?",
  "SUPPORT_TITLE": "Suport",
  "SUPPORT_QUESTION": "Alguna cosa ha anat malament?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "Has trobat un error o alguna característica que falti, o tens alguna pregunta? Si us plau, envia'ns un correu electrònic a <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Si us plau, facilita'ns informació detallada sobre el problema amb els possibles passos fets per reproduir-los i quins usuaris i/o característica està relacionada.",
  "SUPPORT_QUESTION_SUBTITLE": "Si us plau, omple el formulari de sota per fer-nos-ho saber!",
  "SUPPORT_APPLICATION_QUESTION": "Quina carpeta estaves utilitzant?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Nom/id de la carpeta",
  "SUPPORT_FORM_QUESTION": "Quin formulari estaves utilitzant?",
  "SUPPORT_FORM_PLACEHOLDER": "Nom del formulari",
  "SUPPORT_DEVICE_QUESTION": "Quin tipus de dispositiu estaves utilitzant?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Tipus / Versió del sistema",
  "SUPPORT_DESCRIPTION_QUESTION": "Alguna cosa més que creguis que ens pot ajudar?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Descripció",
  "SUPPORT_EMAIL_QUESTION": "Si fos necessari, com podríem contactar amb tu?",
  "SUPPORT_EMAIL_PLACEHOLDER": "Adreça electrònica",
  "SUPPORT_SUCCESS_TITLE": "Moltes gràcies!",
  "SUPPORT_SUCCESS_MESSAGE": "Hem rebut la teva notificació i prendrem l'acció apropiada.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Editar carpeta: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Configuració",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Nom",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Posar nom a la carpeta",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Pàgina d'inici",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Tema",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Imatge",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Vistes",
  "APPLICATION_EDITOR_TO_LABEL": "A",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Canviar al format bàsic",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "Segur que vols tornar al format bàsic? Això vol dir que perdràs el teu disseny personalitzat del PDF i tornaràs al nostre disseny de PDF per defecte.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Nom",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Llista de destinataris separada per comes",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Destinatari dinàmic",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Assumpte",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "De",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Nom del remitent",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Respondre a",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Enviar resposta a aquesta direcció",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "Des de l'Email",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Restablir pin adjunt",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Restablir document adjunt",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "Segur que vols restablir el PDF?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Això sobreescriurà els canvis de personalització i tornarà a generar el PDF basat en la configuració del formulari actual.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Totes les imatges",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Adjuntar PDF amb nom: ",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Utilitzar encapçalament i peu al PDF",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Amb el logotip:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Ocultar els camps que no estan emplenats",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Utilitzar l'orientació horitzontal per al subformulari",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Placeholders",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Mostrar mapa",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Omple una o més adreces electròniques de qui vols enviar el formulari, separades per comes. <br><br><b>Exemple</b><br>marta@exemple.com, carles@exemple.com",
  "${sys.user}": "usuari",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Error al publicar",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "El formulari s'ha desat, però no s'ha pogut publicar a causa d'alguns errors.",
  "VIEW_EDITOR_FORM": "Formulari",
  "VIEW_EDITOR_EMAIL": "Email",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Envia un email amb un PDF a cada registre realitzat",
  "VIEW_EDITOR_RULES": "Regles",
  "VIEW_EDITOR_RULES_SUBTITLE": "Ocultar o mostrar camps sota certes condicions",
  "VIEW_EDITOR_SETTINGS": "Configuració",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Canvia l'aparença i el comportament del teu formulari",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Permet als usuaris cercar registres des de l'app i fer-los servir com a punt d'inici per a un nou registre",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integracions",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Fa funcionar un sistema extern per cada registre",
  "VIEW_EDITOR_PROPERTIES": "Propietats",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "El nom de dades no és vàlid",
  "VIEW_EDITOR_DATA_NAME": "Nom de dades",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Aquest nom de dades està duplicat, cosa que podria donar lloc a un comportament inesperat",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Afegir regla",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Afegir Integració",
  "HOOK_SELECT_TITLE": "Seleccionar integració",
  "INTEGRATIONS_SUBTITLE": "Com a alternativa, pots realitzar integracions utilitzant <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, la nostra <a href=' https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, o <a href='https://zapier.com/apps/moreapp/ integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "No hi ha res a configurar per a aquesta integració",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "La configuració ha estat validada amb èxit",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Eliminar Integració",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "Segur que vols eliminar aquesta integració? Aquesta acció no es pot desfer!",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Actualment, no hi ha integracions disponibles per afegir",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "La configuració ha estat desada, però no ha pogut ser validada en aquest moment. Si el problema persisteix, si us plau, contacta amb l'autor d'aquesta integració: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Obtenir més integracions",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Aquesta integració no estarà disponible fins a {{lastAvailableDate|date:'medium'}}. Pots desar la configuració, però no serà validada. A més, la integració no serà executada fins que estigui disponible novament.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Nom",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Condicions",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Altres tipus",
  "VIEW_EDITOR_RULE_USER": "Nom d'usuari",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Escollir un camp",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Escollir una condició",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Escollir un valor",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Comença amb",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Acaba amb",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Conté",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Major que",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Menor que",
  "VIEW_EDITOR_RULE_CONDITION_IS": "És ",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Té valor",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Camps actuals del formulari",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Altres camps del formulari",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Accions",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Quan",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "de les condicions coincidents, realitzeu el següent",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": " Escull una acció",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Configuració",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Nom",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Descripció",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Icona",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Ocultar a l'aplicació",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Vista arrel",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Aquest formulari s'hauria de mostrar com a vista arrel",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Descripció a l'app",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Opció d'enviament",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Guardar i sortir (Per defecte)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Guardar i sortir & Guardar i nou",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "No guardar (només llegir)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Configuració de cerca",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Activat",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Mostra únicament registres realitzats per l'usuari actual",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filtratge habilitat",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Camps",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Escollir camp",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formulari",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Marcatge d'elements",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Paràmetres disponibles:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "Aquest valor no existeix",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Valor en el PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Afegir",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Utilitzar imatges com a etiquetes",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Seleccionar imatge",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Seleccionar imatge",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Seleccionar icona pin",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Icones pin per defecte",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Seleccionar icona pin per defecte",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Afegir a preferits",
  "VIEW_EDITOR_ADVANCED_MODE": "Mode avançat",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Per defecte",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Preferits",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "No s'ha definit cap preferit.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Afegir Widget Preferit",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Nom del widget Preferit",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Nom",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Valor original",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Valor del widget Preferit",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "No hi ha canvis en el widget existent.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Afegir Email",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Eliminar Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "Segur que vols eliminar aquest widget preferit?",
  "ERROR_SOMETHING_WENT_WRONG": "Alguna cosa ha anat malament.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Si us plau, intenta-ho més tard. Si el problema persisteix, contacta amb nosaltres.",
  "ACCESS_DENIED_MESSAGE": "Accés denegat",
  "ACCESS_DENIED_DESCRIPTION": "La Plataforma és per a Administradors. Contacta amb el Titular del Compte o inicia sessió a l' <a href='{{webclientUrl}}'>App Web</a> per omplir formularis.",
  "ACCESS_DENIED_LOGOUT": "Tancar sessió",
  "ACCESS_DENIED_TO_LOGIN": "Anar a l'inici de sessió",
  "PAGE_NOT_FOUND_TITLE": "No s'ha trobat la pàgina",
  "PAGE_NOT_FOUND_DESCRIPTION": "No s'ha pogut trobar la pàgina",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Tornar a la Vista General de la Plataforma",
  "CUSTOMER_RESOURCES_TITLE": "Recursos",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Tipus",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Galeria",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Nom",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Carregar un fitxer",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Fitxer",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Carregar des d'URL",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Eliminar recurs",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "Segur que vols eliminar aquest recurs? Aquesta acció no es pot desfer!",
  "RESOURCES_UPLOAD_FAILURE": "Càrrega Fallida",
  "RESOURCES_UPLOAD_INVALID_FILE": "El document seleccionat no és una imatge.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "El document seleccionat sobrepassa el límit de 5MB.",
  "REGISTRATIONS": "Registres",
  "START": "Començar",
  "CONFIGURE": "Configurar",
  "UPGRADE": "Actualitzar",
  "STEP": "Pas",
  "CREATED": "Creat",
  "CREATE": "Crear",
  "READ": "Llegir",
  "DELETE": "Eliminar",
  "REMOVE": "Eliminar",
  "SUBMIT": "Enviar",
  "CANCEL": "Cancel·lar",
  "CLOSE": "Tancar",
  "SEND": "Enviar",
  "SAVE": "Desar",
  "RENAME": "Reanomenar",
  "RENAME_LINKED_SUBFORM_TITLE": "Reanomenar el subformulari vinculat",
  "SAVE_PAYMENT_METHOD": "Confirmar i Utilitzar aquest 'Mètode de Pagament'",
  "SELECTED_PAYMENT_METHOD": "Mètode de pagament seleccionat",
  "SELECT_OTHER_PAYMENT_METHOD": "Utilitza un nou mètode de pagament",
  "LOADING": "Carregant",
  "SAVE_AND_CLOSE": "Desar & Tancar",
  "SAVE_AND_PUBLISH": "Desar & Publicar",
  "VALIDATE": "Validar",
  "EDIT": "Editar",
  "UNDO": "Desfer",
  "BACK": "Enrere",
  "NEXT": "Següent",
  "PREVIOUS": "Previ",
  "CLEAR": "Esborrar",
  "SELECT": "Seleccionar",
  "UPLOAD": "Carregar",
  "BUSY_UPLOADING": "Càrrega lenta...",
  "UPDATE": "Actualitzar",
  "ADD": "Afegir",
  "COPY": "Copiar",
  "SHARE": "Compartir",
  "ACTIONS": "Operacions",
  "THEME": "Tema",
  "ON": "On",
  "OFF": "Off",
  "OPTIONS": "Opcions",
  "INSTALLED": "Instal·lat",
  "EMPTY": "Buit",
  "QUARTER_HOUR": "Cada 15 minuts",
  "HALF_HOUR": "Cada 30 minuts",
  "DAILY": "Diàriament",
  "WEEKLY": "Mensualment",
  "HOURLY": "Cada hora",
  "API": "API",
  "NAME": "Nom",
  "EMAIL": "Email",
  "DATE": "Data",
  "DUE_DATE": "Data de venciment",
  "HOOKS": "Integracions",
  "NONE": "Cap",
  "NEVER": "Mai  ",
  "USER": "Usuari",
  "GROUP": "Grup",
  "VIA_GROUP": "a través de grup(s)",
  "BETA": "beta",
  "LOCKED": "Bloquejat",
  "URL": "URL",
  "FILE": "Document",
  "YES": "Sí",
  "NO": "No",
  "DEFAULT": "Per defecte",
  "SEARCH": "Cerca",
  "DETAILS": "Detalls",
  "UNSAVED_CHANGES_TITLE": "Canvis no guardats",
  "UNAVAILABLE": "No disponible",
  "VERSION": "Versió",
  "PRICE": "Preu",
  "FREE": "Gratis",
  "ADDRESS": "Adreça",
  "ADDRESS_LINE_1": "Adreça",
  "ADDRESS_LINE_1_PLACEHOLDER": "C/ Principal 123",
  "CITY": "Ciutat",
  "COUNTRY": "País",
  "ZIP_CODE": "Codi postal",
  "OK": "OK",
  "INTERNAL_ERROR": "Error intern",
  "OPEN": "Obrir",
  "STALLED": "Aturat",
  "ERROR": "Error",
  "SUCCESS": "Èxit",
  "RETRY": "Intenta-ho de nou",
  "SKIP": "Ometre",
  "IN_PROGRESS": "En progrés",
  "COMPLETED": "Completat",
  "DECLINED": "Declinat",
  "BLOCKED": "Una de les integracions ha fallat",
  "NOT_VALIDATED": "No validat",
  "INVALID": "No vàlid",
  "VALID": "Vàlid",
  "VERIFIED": "Verificat",
  "UNVERIFIED": "Sense verificar",
  "TYPE": "Tipus",
  "VALUE": "Valor",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Avançat",
  "PREVIEW": "Vista prèvia",
  "BY": "Per",
  "UNTIL": "Fins",
  "COUPON": "Cupó",
  "COUPON_ADD_ERROR": "El cupó no és vàlid",
  "DISCOUNT": "Descompte",
  "COUPON_REMOVE_TITLE": "Eliminar cupó",
  "COUPON_REMOVE_MESSAGE": "Segur que vols eliminar aquest cupó?",
  "PERMISSIONS": "Permisos",
  "THIS_MIGHT_TAKE_SOME_TIME": "Pot prendre una estona",
  "FIELD_ERROR_REQUIRED": "Aquest camp és obligatori",
  "FIELD_ERROR_MIN_LENGTH": "El camp ha de tenir com a mínim {{minLength}} caràcters",
  "FIELD_ERROR_MAX_LENGTH": "El camp sobrepassa la longitud màxima {{maxLength}}",
  "FIELD_ERROR_MIN": "El valor és inferior al valor mínim {{min}}",
  "FIELD_ERROR_MAX": "El valor és superior al valor màxim {{max}}",
  "FIELD_ERROR_EMAIL": "El valor no és un email vàlid",
  "BILLING_BANK_ACCOUNT_NUMBER": "Compte Bancari (IBAN)",
  "BILLING_VAT_NUMBER": "Número d'identificació IVA",
  "BILLING_VAT_NUMBER_CHECKING": "Comprovant",
  "BILLING_VAT_NUMBER_VALID": "Vàlid",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "Percentatge IVA",
  "BILLING_VAT_NUMBER_INVALID": "No vàlid",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "El país no coincideix",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "El país del teu número d'identificació IVA i el país seleccionat no coincideixen. Si us plau, arregla-ho per poder processar la teva comanda.",
  "BILLING_COC_NUMBER": "Cambra de comerç",
  "BILLING_EMAIL": "Email de Facturació",
  "VAT_DISCLAIMER": "Els preus mostrats no inclouen IVA. L'import de l'IVA es mostrarà després de processar el pagament i es mostrarà a les factures.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Vull rebre la newsletter",
  "TERMS_OF_CONDITION_DOWNLOAD": "Descarregar els termes i condicions",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Subformulari",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Nom del nou formulari",
  "FORM_BUILDER_SUB_FORM_NAME": "Nom del formulari",
  "TOOLTIP_ADD_APPLICATION": "Una carpeta contindrà els teus formularis. <br><br>Cada formulari té la seva pròpia configuració, permisos d'usuaris i pot ser publicat de manera individual.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Aquest nom es mostrarà a l'app.",
  "TOOLTIP_ADD_FORM": "Afegir un formulari per fer registres a l'app. <br><br>Cada formulari té un nombre de camps, regles i la seva configuració.",
  "TOOLTIP_ADD_FORM_NAME": "Aquest nom es mostrarà a l'app.",
  "TOOLTIP_COPY_FORM_NAME": "Utilitza opcionalment un nom diferent per a la còpia.",
  "TOOLTIP_COPY_FORM_DESTINATION": "La còpia es col·locarà en aquesta carpeta.",
  "TOOLTIP_DELETE_FORM": "<b>Aquesta acció és irreversible</b>.<br>Tingues en compte que els registres es conservaran.",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Arrossega</a> per canviar l'ordre dels teus formularis dins de la carpeta.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "La carpeta ha estat arxivada. Reverteix aquesta acció fent clic al botó <a>Desfer</a>.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "La carpeta ha estat creada, però encara no s'ha publicat.<br><br>Si vols veure aquesta carpeta a l'app, primer l'hauràs de <a>Publicar</a>.",
  "TOOLTIP_APP_MANAGE_USERS": "Especifica quins grups i/o usuaris veuran aquesta carpeta a l'app després d'iniciar sessió.",
  "TOOLTIP_VIEW_SETTING_NAME": "Aquest nom es mostrarà a l'app.",
  "TOOLTIP_VIEW_SETTING_ICON": "Aquesta icona és utilitzada a l'app quan el formulari es mostra en una llista",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Habilitar que es mostri aquest formulari a la vista general de <a>Formularis</a>.",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Escull la manera de desar:<br><br><a>Només Desar i Tancar (per defecte):</a><br>Un simple botó de <b>Guardar</b> apareixerà, que guarda i tanca el formulari (quan és vàlid).<br><br><a>Tots:</a><br>El botó de <b>Guardar</b> i <b>Guardar i Nou</b> apareix, perquè puguis continuar afegint registres del mateix formulari.<br><br><a>No desar:</a><br>Tenir aquest formulari només per a lectura. No apareix cap botó.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Això defineix com cada registre, esborrany o tasca d'aquest formulari es mostra a l'App. Fes servir els placeholders dels camps del formulari per poder reconèixer millor l'ítem.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Habilitar cerca en aquest formulari a l'app.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Habilita cercar només en els registres enviats per l'usuari que està actualment connectat.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Habilitar el filtratge de certs camps de formularis trobats.",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Excloure els valors següents en el resultat",
  "TOOLTIP_FORM_RULE_CONDITION": "Especifica una o més <a>Condicions</a> que s'han de complir per executar les <a>Accions</a>.<br><br>D'aquesta manera, els camps irrellevants o inaplicables es pot ometre en determinades condicions.<br><br><b>Per exemple:</b><br>Amagar <a>Nom del cònjuge</a> quan <a>Estat civil</a> sigui <a>'solter'</a>",
  "TOOLTIP_FORM_RULE_ACTION": "Especifica una o més <a>Accions</a> perquè s'executin quan totes les <a>Condicions</a> es compleixin.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Nom que serà utilitzat a la columna nom a <a>Quadrícula de Registres</a>. Per defecte, aquest valor serà emplenat automàticament basat en el <b>text de l'etiqueta</b><br><br><b>Per exemple:</b><br>Si el teu text de l'etiqueta és <a >'Quants anys tens?'</a>, el nom de dades serà <a>quantsAnysTens</a>. És possible que vulgueu canviar això a <a>'anys'</a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Afegir base de dades",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Nom de la base de dades",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Seleccionar un fitxer Excel per importar. Assegura't que l'extensió del fitxer Excel sigui `.xlsx`. fitxers `.xls` no seran importats.",
  "TOOLTIP_HOOK": "La configuració específica d'aquesta integració en aquest formulari",
  "TOOLTIP_DIRECT_FORM_LINK": "Crear un URL únic pel teu formulari, perquè el puguis compartir amb altres.<br><br>Utilitzant aquest URL, la gent sense compte de MoreApp pot omplir el teu formulari.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Comparteix el teu formulari al nostre Marketplace, així altres el poden utilitzar com a plantilla.",
  "RULE_HIDDEN": "Ocultar",
  "RULE_VISIBLE": "Visible",
  "RULE_GETS_VALUE": "Té valor",
  "WIDGET_LICENSE_INVALID": "Llicència no vàlida",
  "WIDGET_LICENSE_BRAND": "Marca",
  "WIDGET_LICENSE_TYPE": "Tipus",
  "WIDGET_LICENSE_POWER": "Power",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "Primera admissió",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Sector",
  "SEGMENT_AUTOMOTIVE": "Automòbil",
  "SEGMENT_CONSTRUCTION": "Construcció",
  "SEGMENT_FACILITY": "Instal·lacions",
  "SEGMENT_FINANCIAL": "Financer",
  "SEGMENT_TRADE_AND_INDUSTRY": "Comerç i Indústria",
  "SEGMENT_GOVERNMENT": "Entitats Públiques",
  "SEGMENT_HEALTH_CARE": "Sanitat",
  "SEGMENT_INSTALLATION": "Instal·lació",
  "SEGMENT_OTHER": "Altres",
  "GENERIC": "General",
  "FACILITY": "Instal·lacions",
  "CONSTRUCTION": "Construcció",
  "SALES": "Ventes",
  "HR": "RRHH",
  "AUTOMOTIVE": "Automòbil",
  "SAFETY": "Seguretat i Protecció",
  "LOGISTICS": "Logística",
  "INSTALLATION": "Instal·lació",
  "MAINTENANCE": "Serveis i Manteniment",
  "SECURITY": "Seguretat",
  "REAL_ESTATE": "Immobiliària",
  "HEALTHCARE": "Salut",
  "AGRICULTURE": "Agricultura",
  "INDUSTRY": "Indústria",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Permisos insuficients",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Has iniciat sessió a la plataforma. Aquesta és una secció on només tenen accés els administradors de la teva organització. Si només necessites omplir formularis, si us plau, obre l'app al teu smartphone o tauleta.",
  "FORM_SUBMISSION_SERVER_ERROR": "El formulari no s'ha pogut enviar. Això pot ser un problema del servidor. Si us plau, torneu-ho a provar més tard",
  "UNKNOWN_ERROR": "Error desconegut",
  "ALL": "totes",
  "ANY": "qualsevol",
  "GLOBAL_ERRORS_TITLE": "Error de Validació",
  "USE_TEMPLATE": "Utilitzar plantilla",
  "LANGUAGE_EN": "Anglès",
  "LANGUAGE_NL": "Holandès",
  "LANGUAGE_DE": "Alemany",
  "LANGUAGE_ES": "Espanyol",
  "LANGUAGE_RU": "Rus",
  "LANGUAGE_PT": "Portuguès",
  "LANGUAGE_FR": "Francès",
  "LANGUAGE_IT": "Italià",
  "LANGUAGE_CA": "Català",
  "UNSAVED_CHANGES": "Hi ha canvis no guardats que seran descartats si continues",
  "CHANGE_FILE": "Seleccionar un altre fitxer",
  "AUTHENTICATED": "Autenticat",
  "LANGUAGE": "Idioma",
  "TAGS": "Tags",
  "SELECT_TAGS": "Seleccionar tags",
  "SELECT_ALL": "Seleccionar tot",
  "SEARCH_TEMPLATES": "Buscar plantilles",
  "CUSTOMER": "Client",
  "PARTNER": "Partner",
  "CUSTOM": "Personalitzat",
  "AMOUNT": "Quantitat",
  "PHONE": "Número de telèfon",
  "REGION": "Regió",
  "STATUS": "Estat",
  "ACTIVE": "Actiu",
  "INACTIVE": "Inactiu",
  "DISABLED": "Deshabilitat",
  "ACCOUNT": "Compte",
  "DOCUMENTATION": "Documentació",
  "COMPANY_NAME": "Nom de l'Empresa",
  "COMPANY": "Empresa",
  "SHOW_MORE": "Mostrar més",
  "WEBSITE": "Pàgina web",
  "CLICK_HERE": "Fes clic aquí",
  "MAIL_NOTIFICATION": "Notificació email",
  "CHECKED": "Verificat",
  "UNCHECKED": "Sense verificar",
  "ACCOUNT_NAME": "Empresa",
  "ACCOUNT_NAME_PLACEHOLDER": "E.x. Empresa SL o Juan Diaz",
  "FEATURE_HOOKS": "Integracions",
  "FEATURE_HOOKS_MESSAGE": "<h4>Les integracions s'utilitzen per processar dades després d'enviar formularis</h4><p>Configura un flux de treball, envia fitxers a OneDrive, envia dades a Google Sheets o Word</p><p>Aquesta característica està disponible a partir del <b>pla</b>Branca.</p>",
  "FEATURE_MANUAL_DATASOURCES": "Importació de dades",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importa dades utilitzant Excel, Google Sheets o una URL (JSON)</h4><p>Aquesta característica està disponible canviant al <b>pla</b>Fulla.</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Importació de dades automàtica",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importa dades automàticament afegint una nova entrada al teu Google Sheet o URL</h4><p>Aquesta característica està disponible a partir del pla <b>Branca</b>.</p>",
  "FEATUER_ADVANCED_PDF": "PDF personalitzat",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Dissenya el teu informe PDF amb codi HTML</h4><p>Aquesta característica està disponible a partir del <b>pla</b>Branca.</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Administració avançada d'usuaris",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Dóna accés als grups a certes parts de la Plataforma</h4><p>Aquesta característica està disponible a partir del <b>pla </b>Arbre.</p>",
  "FEATURE_PDF_LOGO": "El teu logotip al PDF",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Reemplaça el logotip de MoreApp pel teu</h4><p>Aquesta característica està disponible a partir del <b>pla</b>Fulla.</p>",
  "FEATURE_MULTIPLE_EMAIL": "Múltiples emails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Afegeix diversos emails a un formulari</h4><p>Aquesta característica està disponible a partir del <b>pla</b>Fulla.</p>",
  "FEATURE_WIDGETS": "Widgets Pro",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Comença a fer servir més widgets, com els widgets Catàleg, Càlcul o Rating</h4><p>Aquesta característica està disponible a partir del <b>pla</b>Fulla.</p>",
  "FEATURE_SEARCH": "Funció de cerca a l'app",
  "FEATURE_SEARCH_MESSAGE": "<h4>Gràcies a la funció de cerca, buscar formularis emplenats és fàcil</h4><p>La funció de cerca et permet recuperar registres enviats prèviament i utilitzar-los per a un nou registre</p><p>Aquesta característica està disponible a partir del <b>pla</b>Fulla.</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Plantilles personalitzades",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Crea les plantilles personalitzades i estalvia temps en la creació de nous formularis.</h4><p>Aquesta característica està disponible canviant al pla <b>Árbol/b>.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Utilitza webhooks per subscriure's als esdeveniments del teu compte.</h4><p>A cada esdeveniment, enviem un missatge a la URL, que has configurat, perquè puguis realitzar una acció. Això et permet implementar fluxos de treball complexos.</p><p><b>Exemple</b>: Configura un webhook perquè s'activi cada vegada que s'envia un formulari, per emmagatzemar les dades del formulari a la teva pròpia base de dades.</p><br><p>Aquesta característica està disponible a partir del <b>pla</b>Branca.</p>",
  "SWITCH_PLAN_CTA": "Selecciona un nou Pla",
  "SWITCH_PLAN_CONTACT_OWNER": "Contactar Titular del Compte",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Sol·licita actualitzar el compte de MoreApp per utilitzar la funció \"{{feature}}\".",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Seleccionar un compte de servei...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Connectar",
  "SERVICE_ACCOUNTS_NEW_TEXT": "un nou compte de servei",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Cap compte de servei de '{{provider}}' configurat.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Envia'ns un missatge",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "Gràcies pel teu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "Hem resolt els teus dubtes?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "Com qualificaries la teva experiència?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "Com podem millorar?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "Moltes gràcies pel teu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "Indica la teva puntuació aquí!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Deixa els teus suggeriments aquí",
  "ADD_USER_OR_GROUP": "Afegir usuari o grups",
  "ADD_USER_TO_GROUP": "Afegir usuari",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Seleccionar un o múltiples usuaris",
  "SELECT_ROLE": "Seleccionar rol",
  "ALL_USERS_ADDED_TO_GROUP": "Ja has afegit tots els usuaris existents a aquest grup",
  "WHATS_NEW": "Quines novetats hi ha?",
  "WIDGET_LABEL": "Etiqueta",
  "WIDGET_LABEL_DESCRIPTION": "Utilitza el widget <b>Etiqueta</b> per mostrar un separador dins del teu <a>formulari</a>.<br><br><b>Nota:</b><br>Això no forma part de les <a>dades del registre</a>.",
  "WIDGET_TEXT": "Text",
  "WIDGET_TEXT_DESCRIPTION": "Utilitza el widget <b>Text</b> perquè l'usuari empleni una sola línia de text.",
  "WIDGET_TEXT_AREA": "Àrea de Text",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Utilitza el widget <b>Àrea de Text</b> perquè l'usuari empleni frases completes.",
  "WIDGET_NUMBER": "Número",
  "WIDGET_NUMBER_DESCRIPTION": "Utilitza el widget <b>Número</b> perquè l'usuari empleni un nombre enter.",
  "WIDGET_RADIO": "Ràdio",
  "WIDGET_RADIO_DESCRIPTION": "Utilitza el widget <b>Ràdio</b> perquè l'usuari pugui escollir una de les opcions predefinides.<br><br><b>Per exemple:</b><br>Bé / Malament.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Utilitza el widget <b>Checkbox</b> perquè l'usuari marqui una propietat com a Veritable o Falsa.",
  "WIDGET_PHOTO": "Foto",
  "WIDGET_PHOTO_DESCRIPTION": "Utilitza el widget <b>Foto</b> perquè l'usuari faci una foto amb la <a>càmera</a> del dispositiu o afegeixi una de la <a>galeria</a>.",
  "WIDGET_SIGNATURE": "Signatura",
  "WIDGET_SIGNATURE_DESCRIPTION": "Utilitza el widget <b>Firma</b> perquè l'usuari dibuixi la seva signatura.",
  "WIDGET_HEADER": "Títol",
  "WIDGET_HEADER_DESCRIPTION": "Utilitza el widget <b>Títol</b> per introduir títols a les seccions del teu formulari.<br><br><b>Nota:</b><br>Això no forma part de les <a>dades del registre</a>.",
  "WIDGET_DATE": "Data",
  "WIDGET_DATE_DESCRIPTION": "Utilitza el widget <b>Data</b> perquè l'usuari indiqui una data.",
  "WIDGET_DATETIME": "Data i Hora",
  "WIDGET_DATETIME_DESCRIPTION": "Utilitza el widget <b>Data i Hora</b> perquè l'usuari indiqui la data i l'hora.",
  "WIDGET_TIME": "Hora",
  "WIDGET_TIME_DESCRIPTION": "Utilitza el widget <b>Hora</b> perquè l'usuari indiqui l'hora.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Utilitza el widget <b>Email</b> perquè l'usuari introdueixi la seva adreça de correu.<br><br><b>Nota:</b><br>Aquest camp es pot utilitzar com a <a>Destinatari Dinàmic </a> a la <a>configuració Email</a>.",
  "WIDGET_PHONE": "Telèfon",
  "WIDGET_PHONE_DESCRIPTION": "Utilitza el widget <b>Telèfon</b> perquè l'usuari introdueixi un número de telèfon.",
  "WIDGET_LOOKUP": "Desplegable",
  "WIDGET_LOOKUP_DESCRIPTION": "Utilitza el widget <b>Desplegable</b> perquè l'usuari seleccioni un o més dels valors predefinits.",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Utilitza el widget <b>List Item</b> per crear un enllaç a un altre <a>formulari</a>.",
  "WIDGET_SUBFORM": "Subformulari",
  "WIDGET_SUBFORM_DESCRIPTION": "Utilitza el widget <b>Subformulari</b> perquè l'usuari pugui afegir <a>els mateixos widgets diverses vegades</a> al formulari.<br><br><b>Per exemple:</b><br >Afegir múltiples <a>materials utilitzats, hores treballades o fotos</a> a un <a>enviament</a>. També és possible enllaçar subformularis i treballar amb subformularis a subformularis.",
  "WIDGET_BARCODE": "Codi de Barres",
  "WIDGET_BARCODE_DESCRIPTION": "Utilitza el widget <b>Codi de Barres</b> perquè l'usuari pugui escanejar un codi amb la <a>càmera</a> del dispositiu.<br><br><b>Nota:</b> <br>Admet QR, UPC, EAN, CODE_39 i altres.\n",
  "WIDGET_SLIDER": "Slider",
  "WIDGET_SLIDER_DESCRIPTION": "Utilitza el widget <b>Slider</b> perquè l'usuari llisqui un botó per escollir un nombre entre els valors mínim i màxim definits.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Utilitzeu el widget <b>HTML</b> per afegir personalitzacions al formulari. (Només per a usuaris amb coneixements tècnics)",
  "WIDGET_IMAGE": "Imatge",
  "WIDGET_IMAGE_DESCRIPTION": "Utilitza el widget <b>Imatge</b> per mostrar una imatge al <a>formulari</a>.<br><br><b>Nota:</b><br>Això no forma part dels <a>dades del registre</a>. Si voleu que els usuaris afegeixin imatges, utilitzeu el widget <a>Foto</a>.",
  "WIDGET_SEARCH": "Cerca",
  "WIDGET_SEARCH_DESCRIPTION": "Utilitza el widget <b>Cerca</b>, en combinació amb les Bases de Dades, per permetre als usuaris seleccionar dades importades. Per exemple, informació de clients, productes o empleats.",
  "WIDGET_RDW": "Matrícula (NL)",
  "WIDGET_RDW_DESCRIPTION": "Utilitza el widget <b>Matrícula (NL)</b> perquè l'usuari introdueixi un número de matrícula. El widget mostrarà informació addicional de la matrícula. <b>Nota:</b> Només per a matrícules dels Països Baixos. Cal connexió Internet per afegir aquesta informació addicional.",
  "WIDGET_PIN": "Pin",
  "WIDGET_PIN_DESCRIPTION": "Utilitza el widget <b>Pin</b> perquè l'usuari pugui afegir un o més pins en una imatge/mapa determinada. Cada pin que es col·loca obre un <a>subformulari</a> perquè l'usuari afegeixi informació addicional sobre el pin.",
  "WIDGET_HELP": "Ajuda",
  "WIDGET_HELP_DESCRIPTION": "El widget Ajuda permet afegir informació addicional a qualsevol widget. Quan un usuari faci clic al widget Ajuda, apareixerà una finestra emergent on es mostrarà informació addicional.",
  "WIDGET_RATING": "Valoració",
  "WIDGET_RATING_DESCRIPTION": "El widget Valoració permet als usuaris puntuar un tema determinat amb un nombre d'estrelles. És possible configurar una escala de valoració entre dues i deu.",
  "WIDGET_CATALOGUE": "Catàleg",
  "WIDGET_CATALOGUE_DESCRIPTION": "Crea la teva pròpia aplicació de comandes amb el widget Catàleg. Omple un carretó amb productes com ho faries en una botiga web. Utilitza una Base de Dades amb les columnes obligatòries següents: id, name, priceExVat (en cèntims), description. Opcional: foto, miniatura i vatRate. La foto i la miniatura són URL; per tant, només es mostren si el dispositiu té connexió a Internet. Pots afegir tantes columnes com vulguis a la Base de Dades. Aquestes seran visibles a la pàgina de detalls del producte.",
  "WIDGET_SEARCH_WITH_GPS": "Cerca amb GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Cerca amb localització GPS",
  "WIDGET_TIME_DIFFERENCE": "Diferència de Temps",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Aquest widget calcula la diferència entre dos widgets Temps o widget Data i Hora.",
  "WIDGET_PAYMENT": "Pagament",
  "WIDGET_PAYMENT_DESCRIPTION": "Permet a l'usuari fer una compra, dins de l'aplicació, per pagar el formulari",
  "WIDGET_STOPWATCH": "Cronòmetre",
  "WIDGET_STOPWATCH_DESCRIPTION": "Utilitza el widget Cronòmetre per controlar el temps al formulari. El resultat d'aquest widget serà per exemple 00:16.47.",
  "WIDGET_SMILEY": "Emoticona",
  "WIDGET_SMILEY_DESCRIPTION": "Widget per donar una qualificació basada en emoticones.",
  "WIDGET_CURRENT_LOCATION": "Localització Actual",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Aquest widget obté automàticament la teva localització actual utilitzant el GPS. El resultat seria per exemple “Pinzon 12, 08003 Barcelona, ​​Espanya”. El resultat sempre es pot editar manualment, si no és correcte.",
  "WIDGET_DRAWING": "Dibuix",
  "WIDGET_DRAWING_DESCRIPTION": "Amb aquest widget, pots afegir dibuixos, línies i textos a una foto.",
  "WIDGET_VIDEO": "Vídeo",
  "WIDGET_VIDEO_DESCRIPTION": "Aquest widget permet gravar o seleccionar un vídeo. Aquest widget crearà un URL, a l'informe PDF, que podràs utilitzar per descarregar el vídeo.",
  "WIDGET_ZIPCODE": "Codi Postal",
  "WIDGET_ZIPCODE_DESCRIPTION": "El widget Codi Postal proporciona les dades d'una adreça, a partir del codi postal introduït.",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "El widget IBAN valida números IBAN.",
  "WIDGET_DRAWING_FREE": "Dibuix Gratis",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "Una còpia del widget dibuix, però gratuït. Pels clients del partner Blauwdruk.",
  "WIDGET_CALCULATION": "Càlcul",
  "WIDGET_CALCULATION_DESCRIPTION": "El widget Càlcul permet fer operacions com suma, resta, multiplicació i divisió, amb un o diversos widgets.",
  "WIDGET_POSTCODE_NL": "Codi Postal (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Aquest widget només funciona amb codis postals holandesos. Amb el widget Codi Postal (NL) obtindràs adreces basades en un codi postal i un número de casa. Necessites connexió a Internet per rebre les dades. Si no tens connexió, pots afegir les dades manualment.",
  "WIDGET_ADVANCED_SEARCH": "Cerca Avançada",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Amb el widget Cerca Avançada pots afegir color als resultats de la cerca a l'app. Pots definir els colors mitjançant descriptors (com \"vermell\" o \"verd\") o mitjançant codis de color hexadecimals (com #1dc4c2)",
  "WIDGET_FILE": "Fitxer",
  "WIDGET_FILE_DESCRIPTION": "Aquest widget et permetrà afegir fitxers al teu formulari. Comptaràs amb un URL en el teu informe PDF per descarregar els fitxers.",
  "WIDGET_LOCATION": "Localització",
  "WIDGET_LOCATION_DESCRIPTION": "Aquest widget et permet seleccionar una ubicació en un mapa.\n\nEl resultat sempre es pot editar manualment, per si no és del tot correcte.",
  "WIDGET_PROPERTY_PIN_NAME": "Nom",
  "WIDGET_PROPERTY_PIN_FORM": "Formulari",
  "WIDGET_PROPERTY_PIN_ICON": "Icona",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Resum a l'app",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "ID de seguiment",
  "WIDGET_PROPERTY_LABEL": "Etiqueta",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "El nom que es mostra a sobre del widget",
  "WIDGET_PROPERTY_OPERATOR": "Operador",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Operador utilitzat en el càlcul.",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Termes del Càlcul",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Tots els camps que s'han d'incloure en el càlcul.",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Valor Inicial",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Valor inicial per al càlcul.",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Precisió Decimal",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "El nombre de dígits que té el càlcul.",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "No hi ha cap valor en el terme no vàlid",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "El widget no hauria de produir un valor quan un dels termes no sigui vàlid (ex. no és un número).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Base de Dades del Catàleg",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Selecciona una de les bases de dades per utilitzar-la al catàleg. La base de dades ha de complir la configuració requerida.",
  "WIDGET_PROPERTY_CURRENCY": "Moneda",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Configura la moneda a utilitzar; però només si el valor és un preu",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "Percentatge d'IVA",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "El percentatge d'IVA que s'aplicarà a tots els articles del catàleg.",
  "WIDGET_PROPERTY_SHOW_PRICES": "Mostrar preus",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Selecciona aquesta opció perquè els preus unitaris i totals es mostrin a l'app i al PDF",
  "WIDGET_PROPERTY_SHOW_VAT": "Mostrar IVA",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Selecciona aquesta opció perquè els valors de l'IVA es mostrin a l'app i al PDF",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Permetre observacions",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Selecciona aquesta opció per permetre als usuaris que facin un comentari en afegir un article.",
  "WIDGET_PROPERTY_RATE_ICON": "Icona de valoració",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Selecciona quina icona s'utilitza per a la valoració, per exemple, estrelles",
  "WIDGET_PROPERTY_RATE_SCALE": "Escala de valoració",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Selecciona la quantitat d'icones mostrades.",
  "WIDGET_PROPERTY_TITLE": "Títol",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "El títol de l'explicació donada.",
  "WIDGET_PROPERTY_EXPLANATION": "Explicació",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "L'explicació.",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Utilitza el text del botó d'ajuda",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Selecciona aquesta opció perquè el text definit a continuació aparegui abans del signe d'interrogació.",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Text del botó d'ajuda",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "El text que es mostra abans del signe d'interrogació, si selecciones la casella anterior",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "El valor calculat és un preu",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Donar el resultat com un preu.",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ignorar els widgets ocults",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Aquesta opció permet ignorar els widgets ocults en realitzar els càlculs. Està disponible a partir de la versió 7.0.32 en endavant.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Utilitzar l'escàner de codi de barres",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Seleccionar per permetre als usuaris cercar per un codi de barres escanejat.",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Valor predeterminat",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Aquest valor ja està predeterminat.",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Consulta de Cerca predeterminada",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "En obrir la cerca s'utilitzarà aquesta consulta predeterminada.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Etiqueta",
  "WIDGET_PROPERTY_START_TIME": "Hora d'inici",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Hora d'inici per utilitzar al càlcul.",
  "WIDGET_PROPERTY_END_TIME": "Hora de finalització",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Hora de finalització per utilitzar en el càlcul.",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Imatge de fons",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Duració màxima en segons",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "La duració màxima del vídeo en segons",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Qualitat del Vídeo",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Escull la qualitat de vídeo que prefereixis.",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Tipus d'Arxiu",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Selecciona el tipus de fitxer que es pot carregar.",
  "WIDGET_PROPERTY_VALUE": "Valor",
  "WIDGET_PROPERTY_PLACEHOLDER": "Valor d'exemple",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Valor d'exemple que només es mostra quan el camp és buit i s'elimina quan s'omple.",
  "WIDGET_PROPERTY_MAX_LENGTH": "Longitud màxima",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Quantitat màxima de caràcters. Es mostrarà un error de validació a l'app mòbil si se supera aquest valor.",
  "WIDGET_PROPERTY_MIN_LENGTH": "Longitud mínima",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Quantitat mínima de caràcters. Es mostrarà un error de validació a l'app mòbil si la longitud d'aquest valor és massa curta.",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Valor mínim",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "L'app no permet afegir un valor inferior a aquest.",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Valor màxim",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "L'app no permet afegir un valor superior a aquest.",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Utilitzar imatges com a etiquetes",
  "WIDGET_PROPERTY_OPTIONS": "Opcions",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Defineix les opcions que es poden seleccionar a l'app.",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Alineació vertical",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Seleccionar per alinear les opcions verticalment en comptes d'horitzontalment.",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Marcat per defecte",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Si el selecciones, aquest camp es marcarà quan obris un formulari nou.",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Qualitat de la foto",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Escull la qualitat de foto que prefereixis. <br><br><a>Best possible (recomanada)</a><br>Sense reducció d'escala, sense compressió. Podria causar problemes en l'enviament d'emails amb moltes fotos. Podria causar problemes de memòria als dispositius de gamma baixa. <br><br><br>High Quality</a><br>Disminueix l'escala a un màxim de 1920x1920, comprimeix un 10%. Podria causar problemes de memòria en dispositius de gamma baixa i enviament d'emails amb moltes fotos.",
  "WIDGET_PROPERTY_FILENAME": "Nom del fitxer",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Escull el nom de la foto que vols que aparegui a l'exportació.",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Permetre selecció de fotos des del dispositiu",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Permet als usuaris seleccionar una foto feta prèviament des del dispositiu.",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Vista prèvia a mida completa",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Selecciona aquesta opció si vols mostrar una vista prèvia de la foto, a mida completa, en el formulari.",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Guardar una còpia en el dispositiu",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Mida del llapis",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Augmentar/disminuir la mida del llapis de l'usuari",
  "WIDGET_PROPERTY_WIDE": "Ample",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Això reduirà l'alçada permesa d'una signatura, fent que la signatura resultant sigui més ampla",
  "WIDGET_PROPERTY_TEXT": "Text",
  "WIDGET_PROPERTY_HEADER_SIZE": "Mida del títol",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Mida del títol, des de h1 (més gran) fins a h6 (més petit).",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Ara per defecte",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Selecciona per establir automàticament el valor d' 'ara' quan s'obri el formulari",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Ordenar alfabèticament",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Selecciona per ordenar les opcions alfabèticament",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Permetre múltiples seleccions",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Seleccionar per permetre l'elecció de múltiples opcions. Aquestes opcions es guardaran com a valors separats per comes en el registre.",
  "WIDGET_PROPERTY_EXPAND": "Expandir",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Selecciona per expandir la llista en obrir el formulari",
  "WIDGET_PROPERTY_TARGET_VIEW": "Vista de l'objectiu",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Escull el formulari que vols enllaçar.",
  "WIDGET_PROPERTY_IMAGE": "Imatge",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Aquesta imatge s'utilitzarà com a miniatura a l'app.",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Afegir text del botó",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Nom del botó per afegir un element al subformulari.",
  "WIDGET_PROPERTY_SUBFORM": "Subformulari",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Escull el formulari que vols utilitzar per crear el teu objecte compost.",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Resum en l'aplicació",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Especifica com es mostra l'element afegit, utilitzant valors de camps específics.<br><br>Per exemple:</b><br>Cada element afegit s'ha de mostrar com:<br> ${firstName} ${lastName}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Mínim",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "La quantitat mínima de vegades que cal emplenar un subformulari",
  "WIDGET_PROPERTY_MAXIMUM": "Màxim",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "El nombre màxim de vegades que es pot emplenar un subformulari.",
  "WIDGET_PROPERTY_STEP_SIZE": "Esglaó",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Quan s'ajusti a 3, el lliscador sempre s'arrodonirà a un múltiple de 3.",
  "WIDGET_PROPERTY_HIDE_VALUE": "Ocultar el valor",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Determina si es mostra o no el valor a l'aplicació.",
  "WIDGET_PROPERTY_HTML_CODE": "Codi HTML",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Afegeix un codi HTML que suporti CSS en línia.",
  "WIDGET_PROPERTY_RESOURCE": "Recurs",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Selecciona una imatge de la galeria.",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Amplada màxima",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Ample de la imatge dins de l'app. L'amplada es limitarà a l'ample de la pantalla si aquest valor el supera",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Altura màxima",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Alçada de la imatge dins de l'app. L'alçada es limitarà a l'altura de la pantalla si aquest valor el supera",
  "WIDGET_PROPERTY_DATA_SOURCE": "Base de dades",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Selecciona una de les teves bases de dades per cercar-hi. Es poden crear noves bases de dades des del menú principal, a la pestanya Dades.",
  "WIDGET_PROPERTY_FILTER": "Filtre",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filtra els resultats fent servir el valor d'altres camps del formulari.",
  "WIDGET_PROPERTY_LIST_COLORS": "Llista de colors",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Aplica els colors a les entrades de la base de dades que coincideixen amb els valors configurats.",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Permetre escanejar codis de barres",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Selecciona per permetre a l'usuari cercar a la base de dades utilitzant l'escàner de codi de barres.",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Recordar la darrera cerca",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Selecciona per recordar la consulta de cerca. La consulta de cerca s'executarà automàticament",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Permet a l'usuari canviar d'imatge",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Selecciona per permetre als usuaris utilitzar les seves pròpies imatges com a fons per als pins, utilitzant la càmera o la galeria",
  "WIDGET_PROPERTY_PINS": "Pins",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Defineix un o més pins que poden ser col·locats a la imatge. Cada pin té la seva pròpia icona i obrirà el formulari especificat quan es col·loqui.<br><br>A l'app, els pins es numeraran automàticament. Per utilitzar la teva pròpia numeració o etiquetatge, defineix un ID de seguiment.",
  "WIDGET_PROPERTY_REQUIRED": "Obligatori",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Si ho selecciones, aquest camp haurà de ser emplenat per poder desar el formulari",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Recordar entrada",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Selecciona aquesta opció per recordar la darrera entrada de l'usuari, en aquest dispositiu, per al següent registre. Si també has establert un valor per defecte, l'opció de recordar l'entrada serà anul·lada.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Signe decimal",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Quantitat mínima",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "L'app no permet afegir un valor inferior a aquest.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Quantitat màxima",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "L'app no permet afegir un valor superior a aquest.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Fes clic per veure la imatge",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Selecciona aquesta opció si vols mostrar una vista prèvia de la foto, a mida completa, fent clic a la foto.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Localització actual per defecte",
  "INTEGRATION_TASK": "Tasca",
  "INTEGRATION_TASK_DESCRIPTION": "Aquesta integració permet crear una tasca de MoreApp basada en el registre entrant.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "Aquesta integració permet enviar un correu utilitzant Mailchimp. Pots utilitzar les dades del registre com a placeholders a la plantilla de Mailchimp.",
  "INTEGRATION_DELETE": "Eliminar",
  "INTEGRATION_DELETE_DESCRIPTION": "Aquesta integració et permet eliminar automàticament els registres d'un formulari. Assegura't que aquesta és l'última integració que afegiràs al teu formulari. Quan un registre és eliminat no pot ser restaurat. Ves amb compte quan utilitzis aquesta integració.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Aquesta integració permet utilitzar el Microsoft Word per exportar dades. Pots enviar fitxers adjunts en PDF o Word amb aquesta integració. Pots carregar una plantilla Word.docx per personalitzar els informes.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "La integració WebDAV permet enviar el PDF des d'un correu electrònic a un servidor WebDAV.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Aquesta integració permet enviar els formularis emplenats a un full de càlcul de Google. Pots obtenir l'ID del teu full de càlcul de Google des de la URL. Nota: Els formularis enviats simplement s'afegiran com una fila nova al full. No actualitzem les capçaleres del full cada cop que actualitzes el teu formulari; així que ho hauràs de fer manualment. No s'admeten imatges/arxius. Totes les dades imbricades (subformularis, formularis de pins, widget Cerca) s'afegiran a una sola cel·la. En formularis amb regles i camps buits, les dades es poden col·locar a la columna equivocada.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "La integració MongoDB Insert permet inserir les dades dels teus registres en una base de dades Mongo.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Aquesta integració permet enviar el PDF adjunt al formulari a Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Aquesta integració permet utilitzar l'Excel per exportar dades. Carrega la plantilla Excel.xlsx i rebre les dades en un fitxer Excel.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Actualitza una cel·la específica en un full existent. Aquesta integració permet actualitzar un full que també s'utilitza com a base de dades importada a MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Message Board",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Permet enviar missatges",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Google Sheet Accept",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "No per a producció",
  "INTEGRATION_PROPERTY_FOLDER": "Carpeta",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "La carpeta a la qual cal enviar una tasca.",
  "INTEGRATION_PROPERTY_FORM": "Formulari",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "El formulari (dins de la carpeta seleccionada) que cal utilitzar com a objectiu per a la tasca.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Destinataris (llista d'emails separats per comes)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Destinataris (llista d'emails separats per comes)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Destinataris dinàmics",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utilitzar les dades d'un widget Email com a destinatari",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Missatge per a la tasca",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Missatge per a la tasca",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Retard (en dies)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "La quantitat de dies que el registre ha de persistir abans de ser eliminat",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Format de sortida",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Configura el format de sortida que s'utilitzarà com a fitxer adjunt",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Respondre a (opcional)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "La direcció a la qual respondran els destinataris",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "Des de (opcional)\n",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "El nom des del qual vols que s'originin els emails",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Destinataris",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Llista de destinataris separada per comes",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Enviar a l'usuari que va omplir el formulari",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Selecciona aquesta opció per afegir l'usuari que envia el formulari com a destinatari",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Llista de destinataris separada per comes",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Llista de destinataris separada per comes",
  "INTEGRATION_PROPERTY_SUBJECT": "Assumpte",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Assumpte amb placeholders opcionals",
  "INTEGRATION_PROPERTY_BODY": "Cos de l'email",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Cos de l'email amb placeholders opcionals",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Qualitat de la imatge",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Si el teu registre té moltes fotos d'alta resolució, és possible que vulguis baixar la qualitat. En cas contrari, l'email podria ser massa gran per a la bústia.",
  "INTEGRATION_PROPERTY_FILENAME": "Nom del fitxer",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Nom del fitxer amb placeholders opcionals",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Pujar una plantilla de Word",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "La plantilla de Word a utilitzar",
  "INTEGRATION_PROPERTY_SERVER": "Servidor",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL al servidor. S'utilitzarà com a ruta base. Per exemple: http://my.server.com",
  "INTEGRATION_PROPERTY_PATH": "Ruta",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Ruta on cal emmagatzemar els PDF. Pots incloure placeholders, per exemple: /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "Nom d'usuari WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Nom d'usuari a utilitzar quan s'autentiqui al servidor WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "Contrasenya WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Contrasenya a utilitzar quan s'autentiqui al servidor WebDAV",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Inici de sessió a Google",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Escull un compte de Google per permetre'ns introduir dades al teu Google Spreadsheets",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "Spreadsheet ID",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "L'ID únic del full de càlcul que es mostra a l'URL com https://docs.google.com/spreadsheets/d/<b>my-spreadsheet-id</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Nom del full",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "El nom del full; que sol ser, per defecte, 'Full1'",
  "INTEGRATION_PROPERTY_METADATA": "Metadades",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Selecciona els camps meta que vols incloure a l'exportació. Aquests s'introduiran abans de les dades del formulari",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB Host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "L'adreça IP del host de MongoDB",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "MongoDB Port",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "El port on MongoDB està connectat",
  "INTEGRATION_PROPERTY_USE_SSL": "Utilitza SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Utilitzar una connexió TLS segura",
  "INTEGRATION_PROPERTY_USERNAME": "Nom d'usuari",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "El nom d'usuari de Mongo amb què ens hem d'autenticar",
  "INTEGRATION_PROPERTY_PASSWORD": "Constrasenya",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "La contrasenya de l'usuari de Mongo amb què ens hem d'autenticar",
  "INTEGRATION_PROPERTY_DATABASE": "Base de dades",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "La base de dades on vols guardar els registres",
  "INTEGRATION_PROPERTY_COLLECTION": "Col·lecció",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "La col·lecció on vols guardar els registres",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Widget-Cerca",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "El Widget-Cerca",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "Inici de sessió a OneDrive",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Escull un compte d'OneDrive per poder guardar els fitxers",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Incloure fitxer CSV",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Afegeix automàticament un fitxer CSV (Comma Separated Value) al teu directori de OneDrive",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Pujar una plantilla d'Excel",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "La plantilla d'Excel a utilitzar",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Nom del fitxer Excel",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Nom del fitxer Excel a adjuntar",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Còpia a l'usuari",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envia una còpia a l'usuari que ha omplert el formulari",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Adjuntar imatges",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Adjuntar imatges i firmes a l'email",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Widget de valor nou",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Selecciona el widget del qual vols obtenir el nou valor",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Nom de la columna a actualitzar",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Defineix la columna que vols actualitzar amb el valor del \"widget de valor nou\" seleccionat",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "La integració FTPS guarda les dades del registre al servidor FTPS proporcionat.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocol",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "El protocol a utilitzar quan es connecta al servidor FTP",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Desactivar la verificació SSL",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Pots utilitzar aquesta opció quan tinguis un certificat autosignat o una cadena de certificats no vàlida (només aplicable quan s'utilitza FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Utilitzar el mode FTPS 'implícit'",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Canviar entre el mode 'implícit' i 'explícit' de FTPS (només aplicable quan s'utilitza FTPS)",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL al servidor sense el protocol. S'utilitzarà com a ruta base. Per exemple: my.server.com",
  "INTEGRATION_PROPERTY_PORT": "Port",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Port del servidor FTP(S). Per defecte serà el 21 o el 990 si no s'omple.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Posar cada registre a la seva pròpia carpeta",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Afegeix automàticament '/customerId/form-name/serial-number' a la ruta remota per crear una ruta única per cada registre",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "Nom d'usuari FTP",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Nom d'usuari que cal utilitzar per autenticar-se al servidor WebDAV.",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "Contrasenya FTP",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Contrasenya que cal utilitzar per autenticar-se al servidor WebDAV.",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Incloure PDF",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Indicar si cal copiar també el PDF.",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Emmagatzemar el PDF dels emails sense destinatari",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Indica si s'emmagatzemen o no els PDF que es generen per als emails sense destinatari",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Incloure fitxers",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Indica si s'han de copiar també els fitxers",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Puja una plantilla d'Excel",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "La plantilla d'Excel que s'utilitzarà",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Utilitzar configuració anterior",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Selecciona aquesta opció si vols utilitzar '${' com a delimitador d'inici per als placeholders. Això només es recomana per a les plantilles existents més antigues.",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Nom del fitxer Excel",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Nom del fitxer Excel que rebràs adjunt",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Destinataris",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Llista de destinataris separats per comes.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Destinataris dinàmics",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utilitza les dades d'un widget-Email com a destinatari",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Còpia a l'usuari",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envia una còpia a l'usuari que ha omplert el formulari",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Qualitat de la imatge",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Més qualitat portarà més temps per processar l'email",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Assumpte",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Assumpte amb placeholders opcionals",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Cos de l'email",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Cos de l'email amb placeholders opcionals",
  "DISABLED_PENDING_VERIFICATION": "Aquesta funcionalitat s'activarà després de posar-te en contacte amb suport. Si us plau, contacta el nostre equip per a activar aquesta opció.",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Comprova la configuració de l'hora",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "No pots iniciar sessió a causa de l'hora del dispositiu.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>SOLUCIÓ</b>: canvia l'hora i la zona horària del dispositiu perquè reflecteixi amb precisió l'hora i la zona horària de la teva ubicació actual. A continuació, torna-ho a provar.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Tornar a provar d'iniciar sessió",
  "TOUR_PROGRESS_STEP1_LABEL": "Com funciona",
  "TOUR_PROGRESS_STEP2_LABEL": "Explica'ns més",
  "TOUR_PROGRESS_STEP3_LABEL": "Primer formulari",
  "TOUR_SKIP": "Ometre tour",
  "TOUR_STEP1_TRIAL": "Estàs a la teva prova gratuïta de 14 dies",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Crea i modifica formularis des del teu escriptori",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Omple formularis al teu mòbil o tauleta",
  "TOUR_STEP1_RECEIVE_DATA": "Rep un informe a la teva safata d'entrada",
  "TOUR_STEP2_HEADER": "Explica'ns més",
  "TOUR_STEP3_HEADER": "¿Com podem contactar amb tu?",
  "TOUR_ERROR_MODAL_TITLE": "Oops...",
  "TOUR_ERROR_MODAL_MESSAGE": "Alguna cosa ha anat malament en aquest tour. Mentrestant, pots explorar {{appName}} tu mateix.",
  "TOUR_ERROR_MODAL_BUTTON": "Explorar {{appName}}",
  "FIRST_NAME": "Nom",
  "LAST_NAME": "Cognom",
  "PAGE_CONTROL_PAGE_SIZE": "Mida de la pàgina",
  "PAGE_CONTROL_TOTAL_ITEMS": "Ítems en total",
  "VIEW_MANAGEMENT_OVERVIEW": "Vista General",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Registres",
  "VIEW_MANAGEMENT_TASKS": "Tasques",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Costos",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Estat",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "El",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Editar (Beta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Compartir",
  "FORM_REMOVE_MODAL_TITLE": "Eliminar Formulari",
  "FORM_REMOVE_MODAL_MESSAGE": "Segur que vols eliminar aquest formulari?",
  "REMOVE_DRAFT_TITLE": "Eliminar Esborrany",
  "REMOVE_DRAFT_MESSAGE": "Segur que vols eliminar aquest esborrany?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Eliminar Plantilla",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "Segur que vols eliminar aquesta plantilla?",
  "UNPUBLISHED": "No Publicat",
  "PUBLISHED": "Publicat",
  "PUBLISHED_UPDATED": "Publicat, actualització disponible",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Gestionar usuaris",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Publicar el formulari al Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Compartit al Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Ocult al Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Veure al Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Actualitzar a l'última versió",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "La Plantilla del Formulari no s'ha pogut actualitzar a l'última versió. Torna-ho a provar més tard.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Ocultar del Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Mostrar en el Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Publicar el formulari al Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Vols compartir el teu formulari amb tots els altres usuaris?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Si us plau, omple primer aquest formulari. Aquesta informació (excepte el teu compte bancari) serà visible en el Marketplace.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Actualitzar informació",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Nom del espai",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Exemple: com.mycompany",
  "FORM_TEMPLATE_KEY_LABEL": "Clau",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Exemple: specialform",
  "FORM_TEMPLATE_TYPE_LABEL": "Tipus",
  "FORM_TEMPLATE_NAME_LABEL": "Nom",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Posa nom a la Plantilla del Formulari",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Descripció",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Descriu aquesta Plantilla",
  "FORM_TEMPLATE_LOGO_LABEL": "Logotip",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "La URL del logo de la teva Plantilla",
  "DIRECT_FORM_LINK": "Generar URL",
  "DIRECT_FORM_LINK_GENERATED": "Obrir URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Revocar URL",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "Segur que vols revocar l'URL que dona accés directe al teu formulari? Això significa que l'URL deixarà de funcionar.",
  "PUBLISH": "Publicar",
  "PUBLISH_FORM_VERSION": "Publicar aquesta versió",
  "PUBLISH_FORM_VERSION_MESSAGE": "Segur que vols publicar aquesta versió del formulari?",
  "PUBLISHED_BY": "Publicat per",
  "PUBLISHED_ON": "Publicat",
  "REMARKS": "Observacions",
  "DRAFT": "Esborrany",
  "FORM_HISTORY": "Historial de versions",
  "FORM_DESCRIPTION_PLACEHOLDER": "Afegeix una breu descripció del teu formulari",
  "FORM_MOVE_MODAL_TITLE": "Moure",
  "FORM_SAVE_AS_TEMPLATE": "Desar com a plantilla",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "Segur que vols desar aquest formulari com a plantilla?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Mou el formulari a la carpeta seleccionada",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Seleccionar carpeta",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Sembla que tu o un altre usuari esteu editant aquest formulari en un altre lloc. Assegura't s que ets l'única persona que estàs editant el formulari i torna-ho a provar.",
  "INTEGRATIONS_EOL_MESSAGE": "Aquest formulari utilitza una integració que ha deixat de funcionar. Si us plau, elimina-la o substitueix-la per una altra integració o webhook. <a href=\"https://help.moreapp.com/es/support/tickets/new\">Contacta amb nosaltres</a> si necessites ajuda.",
  "DATASOURCE_PICKER_ADD": "Afegir una base de dades",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Cercar bases de dades",
  "GROUP_PICKER_ADD_USER": "Convida usuari(s)",
  "GROUP_PICKER_ADD_GROUP": "+ Crear un nou grup",
  "GROUP_PICKER_ALL_USERS": "Tots els Usuaris",
  "GROUP_PICKER_INVITED": "(Convidat)",
  "MANAGE_ROLES": "Gestionar rols",
  "ROLE": "Rols",
  "SEARCH_GROUP_PLACEHOLDER": "Cercar grups",
  "BILLING_TITLE": "Facturació",
  "BILLING_OVERVIEW_MENU_ITEM": "Vista General",
  "BILLING_INVOICES_MENU_ITEM": "Factures",
  "BILLING_USAGE_MENU_ITEM": "Ús",
  "SUBTOTAL": "Subtotal",
  "TOTAL": "Total",
  "NEXT_INVOICE": "Pròxima Factura",
  "PREVIEW_CHANGE_EXPLANATION": "Aquesta vista et mostra la teva propera factura un cop aplicats els canvis. Consulta el següent article del <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Centre d'Ajuda</a> que t'explica com es crea la teva factura.",
  "PREVIEW_CHANGE": "Vista prèvia dels canvis",
  "CHANGE_SUBSCRIPTION_BUTTON": "Canviar subscripció",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Veure canvi",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Canvi de subscripció programat",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Programat",
  "SCHEDULED_CANCEL_MESSAGE": "La teva subscripció està programada per cancel·lar-se al final del període de facturació actual",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Anul·lar canvi",
  "SELECT_PLAN": "Selecciona el teu pla",
  "YOUR_SELECTED_PLAN": "El teu pla seleccionat",
  "LOADING_PLANS": "Carregant els plans disponibles",
  "MONTHLY_PLAN": "Pla Mensual",
  "YEARLY_PLAN": "Pla Anual",
  "MONTH_SHORT": "mes",
  "YEAR_SHORT": "any",
  "TRIAL_ACTIVE": "Període de prova actiu",
  "DAYS": "dies",
  "TRIAL_DAYS_REMAINING": "dies restants",
  "TRIAL_ENDS_TODAY": "El teu període de prova acaba avui",
  "TRIAL_ENDS_TOMORROW": "El teu període de prova acaba demà",
  "TRIAL_ENDS_ON": "El teu període de prova acaba el",
  "SUBMISSIONS": "Registres",
  "USERS": "Usuaris",
  "USAGE": "Ús",
  "UNLIMITED": "Il·limitat",
  "CHANGE_PLAN": "Canviar Pla",
  "CANCEL_PLAN": "Cancel·lar Pla",
  "UNCANCEL_PLAN": "Anul·lar Cancel·lació",
  "PLAN_MORE_OPTIONS": "Més opcions",
  "DELETE_ACCOUNT": "Eliminar Compte",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Encara tens una factura pendent",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Encara tens una factura pendent. Per tant, no pots realitzar aquesta acció. Ves a la pestanya Factures per a completar el pagament.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Anar a Factures",
  "CANCEL_PLAN_MODAL_TITLE": "Cancel·lar Pla",
  "CANCEL_PLAN_MODAL_MESSAGE": "Pots seguir utilitzant el nostre producte fins al final del cicle de facturació. Després d'aquest període, els registres no es processaran. Encara pots accedir al teu compte per veure i exportar les dades durant 30 dies. Després, eliminarem el teu compte.",
  "UNCANCEL_PLAN_MODAL_TITLE": "Anul·lar Cancel·lació",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Molt bé! Així que has canviat d'opinió i vols seguir utilitzant el nostre producte?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "El pla ja està programat per a cancel·lar-se",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "Està programat que el pla es cancel·li al final del cicle de facturació actual.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Si us plau, fes-nos saber com podem millorar el nostre producte. Valorem molt els teus comentaris.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>La teva opinió ens importa</h4><p> Si us plau, fes-nos saber com podem millorar el nostre producte.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN": "Per què voleu cancel·lar el pla? Podeu seleccionar diverses respostes.",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "Per què vols eliminar el teu compte?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "He trobat una altra alternativa",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "És massa car",
  "TERMINATION_FEEDBACK_MODAL_REASON_RECENT_PRICE_INCREASE": "Augment recent del preu",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Ja no ho necessito",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Estic tancant el meu negoci",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "El producte no satisfà les necessitats de l'empresa",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Falta alguna característica",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Altres",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Si us plau, selecciona almenys un motiu.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "Hi ha res més que vulguis compartir?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Si us plau, escriu un missatge.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Cancel·lar Pla",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Eliminar Compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Sí, vull eliminar el meu compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Entenc que després de <b>30 dies</b>, el meu compte d'empresa <b>{{customerName}}</b> ({{customerId}}) i totes les dades relacionades seran eliminades de forma permanent.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Si us plau, confirma-ho omplint el nom del teu compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "El nom del compte no coincideix amb el del compte que estàs intentant eliminar.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Eliminar Compte",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "No s'ha pogut eliminar el compte. Torna-ho a provar més tard.",
  "BILLING_PROFILE": "Perfil de Facturació",
  "BILLING_PHONE_NUMBER": "Número de telèfon",
  "INVOICE_ADDRESS": "Adreça de Facturació",
  "PAYMENT_METHOD": "Mètode de Pagament",
  "UPCOMING_INVOICE": "Pròxima Factura",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "En període de prova",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Comença gratis amb MoreApp durant el període de prova. Desitges continuar després de la prova? Afegeix un mètode de pagament per a rebre la teva primera factura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Comença gratis amb MoreApp durant el període de prova. Una vegada finalitzat, rebràs la següent factura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Import a pagar després de la prova",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "No hi ha pròximes factures",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "La teva subscripció ha estat cancel·lada. No hi ha pròximes factures. El teu període de facturació finalitza el dia ",
  "CARD_HOLDER_NAME": "Nom del Titular de la Targeta",
  "CARD_HOLDER_NAME_PLACEHOLDER": "p. ex. Josep Soler",
  "ADD_CARD": "+ Afegir Mètode de Pagament",
  "MAKE_DEFAULT": "Establir com a predeterminada",
  "EXPIRES": "caduca",
  "PAYMENT_METHOD_REMOVE_TITLE": "Eliminar mètode de pagament",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "Segur que vols eliminar aquest mètode de pagament?",
  "PLAN_LEAF": "Fulla",
  "PLAN_BRANCH": "Branca",
  "PLAN_TREE": "Arbre",
  "PLAN_FOREST": "Bosc",
  "PLAN_LEAF_DESCRIPTION": "Crea formularis professionals",
  "PLAN_BRANCH_DESCRIPTION": "Processament de dades avançat",
  "PLAN_TREE_DESCRIPTION": "Per a tot el negoci",
  "PLAN_FOREST_DESCRIPTION": "Per empreses",
  "PLAN_LEAF_FEATURES_HEADER": "Característiques",
  "PLAN_BRANCH_FEATURES_HEADER": "Pla Fulla +",
  "PLAN_TREE_FEATURES_HEADER": "Pla Branca +",
  "PLAN_FOREST_FEATURES_HEADER": "Pla Arbre +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Un usuari",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Usuaris il·limitats",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} enviaments gratuïts",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} arbres",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / enviament addicional",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / enviament addicional",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / enviament addicional",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "0,10€ / enviament addicional",
  "FEATURE_FORM_BUILDER": "Generador de Formularis",
  "FEATURE_APP": "App",
  "FEATURE_EXCEL_EXPORT": "Exportació Excel",
  "FEATURE_EMAIL": "Email amb PDF",
  "FEATURE_RULES": "Regles",
  "FEATURE_API": "Accés API",
  "FEATURE_MARKETPLACE_TEMPLATES": "Plantilles",
  "FEATURE_TASKS": "Tasques",
  "FEATURE_LOGO_PDF": "El teu logo al PDF",
  "FEATURE_DATA_IMPORT": "Importació de dades",
  "FEATURE_MULTIPLE_PDF": "Múltiples emails",
  "FEATURE_ADVANCED_PDF": "PDF personalitzat",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Importació de dades automàtica",
  "FEATURE_SINGLE_SIGN_ON": "Inici de Sessió Únic",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Inici de Sessió Únic",
  "FEATURE_SENDING_DOMAIN": "Domini d’Email Personalitzat",
  "FEATURE_DEDICATED_SUPPORT": "Suport dedicat",
  "VAT": "IVA",
  "APPLIED_BALANCE": "Saldo aplicat",
  "AMOUNT_DUE": "Quantitat a pagar",
  "REMAINING_BALANCE": "Saldo restant",
  "CREDIT_CARD": "Targeta de Crèdit",
  "SEPA_DIRECT_DEBIT": "Dèbit Directe SEPA",
  "PAY_ON_FILE": "Transferència",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Aquesta opció no està disponible a la teva subscripció actual",
  "SEPA_MANDATE": "En proporcionar el teu IBAN, autoritzes (A) al MoreApp i a Stripe, el nostre proveïdor de serveis de pagament, a enviar instruccions al teu banc perquè carregui el teu compte i (B) al teu banc perquè carregui el teu compte d'acord amb aquestes instruccions. Aquest mandat només està destinat a les transaccions entre empreses. No tindràs dret a què el teu banc et torni els diners després que s'hagi efectuat el càrrec al teu compte, però sí que tens dret a sol·licitar al teu banc que no carregui l'import al teu compte fins al dia que s'hagi de fer el pagament.",
  "INVOICE_NUMBER": "Número de factura",
  "INVOICE_DATE": "Data",
  "INVOICE_TOTAL": "Total",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Estat",
  "BASE_MONTH": "Pla mensual",
  "BASE_YEAR": "Pla anual",
  "FORMS_MONTH": "Ús mensual",
  "FORMS_YEAR": "Ús anual",
  "BRANDING_MONTH": "Branding mensual",
  "BRANDING_YEAR": "Branding anual",
  "ADDON_MONTH": "Extensió mensual",
  "ADDON_YEAR": "Extensió Anual",
  "MAINTENANCE_FTPS_MONTH": "Manteniment - FTPS mensual",
  "MAINTENANCE_FTPS_YEAR": "Manteniment - FTPS anual",
  "MANUAL_INVOICE_DESCRIPTION": "Les factures s'enviaran a l'email de facturació",
  "USAGE_DAILY": "Diàriament",
  "USAGE_MONTHLY": "Mensualment",
  "MONTH": "Mes",
  "YEAR": "Any",
  "INVOICE_REFERENCE": "Informació addicional de la factura",
  "INVOICE_REFERENCE_DESCRIPTION": "Aquesta informació s'afegirà a la vostra factura per a l'administració, per exemple un número d'ordre de compra.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Aquest canvi s'efectuarà immediatament",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Aquest canvi s'efectuarà a partir de la propera factura",
  "TREES_PLANTED": "Arbres plantats",
  "TREES_REVENUE_COMMITMENT": "Contribuïm amb l’1% dels nostres ingressos a projectes de reforestació",
  "DOWNGRADE_QUESTION": "Per què vols canviar el teu pla?",
  "DOWNGRADE_NOT_NEEDED": "Ja no el necessito",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Observacions",
  "DOWNGRADE_ALTERNATIVE": "He trobat una alternativa",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "Quina alternativa?",
  "DOWNGRADE_MISSING_FEATURE": "Falta una característica",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "Quina característica et falta?",
  "DOWNGRADE_PRICING": "És massa car",
  "DOWNGRADE_PRICING_DESCRIPTION": "Observacions",
  "DOWNGRADE_UNSATISFIED": "Insatisfet/a",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "Què podem millorar?",
  "DOWNGRADE_OTHER": "Altres",
  "DOWNGRADE_OTHER_DESCRIPTION": "Quina és la raó?",
  "SELECT_OPTION": "Selecciona una opció",
  "CONTACT_BUTTON": "Contacta",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/account-manager-es",
  "PRICE_LABEL_NOW": "ara",
  "PRICE_LABEL_UPCOMING": "des de gener 2025",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Facturat anualment",
  "BILLING_FLOW_STEP1_LABEL": "Selecciona un Pla",
  "BILLING_FLOW_STEP2_LABEL": "Detalls de la Facturació",
  "BILLING_FLOW_STEP3_LABEL": "Mètode de Pagament",
  "BILLING_FLOW_STEP4_LABEL": "Confirmar",
  "ONETIME": "Únic",
  "MONTHLY": "Mensual",
  "YEARLY": "Anual",
  "RECURRENCE": "Recurrència",
  "BRANDING": "Branding",
  "RESTORE": "Restaurar",
  "RESTORE_FORM_MESSAGE": "En restaurar el formulari, aquest tornarà a aparèixer a la llista de formularis de la Plataforma. També es mostrarà a l'aplicació, si el formulari estava publicat.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Crea el teu compte gratuïtament",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Comença la prova gratuïta de 14 dies",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "Adreça electrònica",
  "SIGN_UP_CUSTOMER_PASSWORD": "Contrasenya",
  "SIGN_UP_CUSTOMER_BUTTON": "Crear un compte",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@empresa.com",
  "EMAIL_VALIDATION_VALIDATING": "Validant adreça electrònica...",
  "EMAIL_VALIDATION_FAILED": "La validació de l'adreça electrònica no s'ha pogut completar. No hem trobat el compte lligat a la validació corresponent. Potser aquest compte ja ha estat validat anteriorment.",
  "EMAIL_VALIDATION_SUCCESS": "La teva adreça electrònica s'ha validat correctament!",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Fes clic a la imatge per afegir marcadors",
  "ALERT_TOUCH_DEVICE": "La Plataforma no funciona correctament en dispositius mòbils. Si us plau, utilitza un ordinador",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>El teu mètode de pagament ha caducat!</b> Assegura't <a href ui-sref='portal.customers.billing.overview'>d'afegir un nou mètode de pagament</a> per continuar enregistrant formularis.",
  "ADD_VIEW_TEMPLATE_TYPE": "Utilitzant la plantilla:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "En blanc",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Instal·lat",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Obtenir més formularis del Marketplace",
  "BLANK_FORM": "En blanc",
  "BLANK_FORM_DESCRIPTION": "Començar amb un formulari en blanc",
  "AGREEMENTS_MODAL_TITLE": "Actualització de Termes i Condicions",
  "AGREEMENTS_MODAL_MESSAGE": "Hem actualitzat el nostre Acord d'Usuari Final, inclòs l'Annex de Processament de Dades. Si us plau, llegeix-los atentament. Si tens cap pregunta o dubte, posa't en contacte amb nosaltres. En cas contrari, no cal que facis cap acció; els Termes i Condicions actualitzats s'aplicaran l'1 de febrer de 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Continuar",
  "ADD_PHOTO": "Afegir foto",
  "CAPTURE_PHOTO": "Fer foto",
  "BROWSE_PHOTO": "Buscar foto",
  "DRAW_SIGNATURE": "Dibuixar signatura",
  "START_DRAWING": "Començar a dibuixar",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Tria la imatge de fons",
  "ADD_VIDEO": "Afegir vídeo",
  "SIGN_UP_THANKS": "Gràcies per registrar-te!",
  "SIGN_UP_CHECK_MAIL": "Si us plau, comprova la teva safata d'entrada per verificar el teu email.",
  "FORM_BUILDER": "Generador de Formularis",
  "MOBILE_APP": "Aplicació mòbil",
  "PDF_REPORTS": "Informe PDF",
  "EXCEL_EXPORT": "Exportació Excel",
  "TASKS": "Tasques",
  "PDF_LOGO": "Personalitza el logo al PDF",
  "REALTIME_DATA_SOURCES": "Importació de dades automàtica",
  "PDF_LAYOUT": "PDF personalitzat",
  "RULES": "Regles",
  "API_ACCESS": "Accés a API",
  "COLOR_THEMES": "Temes de color",
  "THEMES_SYSTEM": "Temes predeterminats",
  "THEMES_CUSTOM": "Temes personalitzats",
  "GROUP_ROLES": "Administració avançada d'usuaris",
  "CUSTOM_DOMAIN": "Domini personalitzat",
  "DEDICATED_ACCOUNT_MANAGER": "Suport dedicat",
  "PREMIUM_SUPPORT": "Suport Premium",
  "FILE_SELECT": "Selecciona un arxiu",
  "NEW_CUSTOMER": "+ Nou Client",
  "TO_PORTAL": "Anar al Portal",
  "EXPIRED": "Caducada",
  "READ_MORE": "Llegeix-ne més",
  "CHOOSE_A_PLAN": "Selecciona un pla",
  "TRIAL": "Prova",
  "TRIAL_BANNER_TITLE": "Estàs en el període de prova gratuït",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} dies restants",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} registres restants",
  "TRIAL_BANNER_CTA_CONTACT": "Contacta",
  "TRIAL_BANNER_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/tours-es",
  "UPGRADE_NOW": "Actualitza el pla",
  "CONTACT_SALES": "Contacta",
  "BANNER_DELETION_LABEL": "El teu compte està programat per a ser eliminat en {{days}} dia(es)",
  "BANNER_DELETE_TODAY_LABEL": "El teu compte serà eliminat avui",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Tria un pla per continuar utilitzant MoreApp",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Si us plau, selecciona un pla per continuar utilitzant MoreApp. En cas contrari, aquest compte s'eliminarà en {{days}} dia(es).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Cap subscripció activa",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Si us plau, selecciona un pla per continuar",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Important:</b> A causa de treballs de <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>manteniment</a> programats, MoreApp no estarà disponible dimecres 19/04/2023 entre les 19:00-21:00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Important:</b> A causa de treballs de manteniment programats, el nostre software no estarà disponible dimecres 19/04/2023 entre les 19:00-21:00 CET.",
  "BANNER_OVER_USAGE_TITLE": "Has excedit el nombre d'enviaments gratuïts.",
  "BANNER_OVER_USAGE_DESCRIPTION": "<b>Has superat el nombre d'enviaments gratuïts.</b> ✈️ {{ nrOfSubmissionsOverIncluded }} enviaments addicionals 💰 {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }} cost addicional",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Actualitza el pla",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Contacta",
  "BANNER_OVER_USAGE_CTA_CONTACT_URL": "https://meet.moreapp.com/meetings/moreapp/account-manager-es",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "La teva targeta de crèdit ha caducat.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Afegeix un mètode de pagament vàlid.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Actualitzar Mètode de Pagament",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Tens una factura pendent de pagament.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Paga la factura abans que el teu compte es bloquegi.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Pagar Factura",
  "BANNER_UNPAID_INVOICE_TITLE": "El teu compte ha sigut bloquejat temporalment perquè tens factures sense pagar.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Paga la factura per desbloquejar-la.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Pagar Factura",
  "BANNER_PAYMENT_DISPUTE_TITLE": "S'ha produït una disputa de pagament. El teu compte serà bloquejat.",
  "BANNER_PAYMENT_DISPUTE_PRIMARY_ACTION": "Contacta amb Suport",
  "BANNER_PAYMENT_DISPUTE_MAIL_SUBJECT": "Disputa de Pagament",
  "BANNER_PAYMENT_DISPUTE_MAIL_BODY": "Hola Suport,\n\nM'agradaria solucionar una disputa de pagament.\n\nSalutacions,\n\n",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Vista de selecció de columnes",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Editar Base de Dades",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Carregar Excel",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Programar importació",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Cercar",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Habilitar",
  "DATA_SOURCES_TITLE": "Bases de Dades",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Última actualització",
  "DATA_SOURCES_GENERAL_INFORMATION": "Informació General",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Última actualització correcta",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Mostrar avisos",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "Configuració URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Programar importació URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Important dades...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Programar importació Excel",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Important dades...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Últims avisos:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Mostrar tots {{numberOfWarningMessages}} )",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Mostrar menys )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Carregar fitxer Excel",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "L'arxiu seleccionat és massa gran per a carregar-lo. El límit per a la càrrega d'arxius d'Excel és de 10MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Selecciona un Excel per a importar. Assegura't que l'extensió de l'Excel sigui `.xlsx`. Els documents `.xls` no seran importats.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "deixa anar els arxius aquí",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "No és compatible amb arxius HTML5!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Detalls de l'Entrada",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Paràmetres",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Tipus d'última actualització",
  "DATA_SOURCES_SEARCH_LABEL": "Base de Dades",
  "DATA_SOURCES_ADD_BUTTON": "+ Afegir base de dades",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Afegir una base de dades",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Editar base de dades",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Nom",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Introdueix un nom per a la base de dades",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Tipus",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Introdueix un URL des d'on obtenir la base de dades",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Paràmetres",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Afegir Paràmetre",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "No hi ha paràmetres",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Clau",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Capçaleres de la sol·licitud",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Afegir una capçalera de sol·licitud",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Clau",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Interval d'Actualització",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Autenticació Bàsica",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Autenticar-se amb Google",
  "AUTHENTICATED_WITH_GOOGLE": "Autenticat amb Google",
  "CHANGE_ACCOUNT": "canviar compte",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Valor entre /d/ i /edit en l'URL de la teva spreadsheet",
  "DATA_SOURCE_GRID_NO_ENTRIES": "No hi ha entrades per a aquesta base de dades.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Carregant entrades",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Important entrades",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Última actualització fallida",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Última actualització: mai",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Última actualització",
  "DATA_SOURCE_WARNINGS_TITLE": "Avisos de la importació",
  "DATA_SOURCE_REMOVE_TITLE": "Eliminar Base de Dades",
  "DATA_SOURCE_REMOVE_QUESTION": "Segur que vols eliminar aquesta base de dades? Aquesta acció no es pot desfer!",
  "DATA_SOURCE_ENABLE_TITLE": "Habilitar Base de Dades",
  "DATA_SOURCE_ENABLE_MESSAGE": "La base de dades s'ha inhabilitat perquè 10 o més importacions consecutives han fallat. Assegura't de tenir una configuració url correcta. Si habilites la base de dades, ho intentarem de nou.",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Configuració",
  "SETTINGS_TAB_GENERAL": "General",
  "SETTINGS_TAB_SSO": "Inici de Sessió Únic",
  "SETTINGS_TAB_SENDING_DOMAIN": "Domini de l'email",
  "BILLING_SUCCESS_MESSAGE": "S'ha actualitzat correctament la informació de facturació del client.",
  "BILLING_DETAILS_HEADER": "Client",
  "BILLING_INVOICES_HEADER": "Client",
  "GENERAL_HEADER": "General",
  "GENERAL_SUCCESS_MESSAGE": "S'ha actualitzat correctament la configuració del client.",
  "LOGO_HEADER": "Logotip",
  "LOGO_SELECT": "Seleccionar logotip",
  "SETTINGS_DATE_FORMAT": "Format de la Data",
  "SETTINGS_CUSTOMER_NAME": "Nom de l'Empresa",
  "SETTINGS_TAB_THEMES": "Temes",
  "SETTINGS_TAB_THEMES_CUSTOM": "Temes Personalitzats",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Afegir Tema",
  "THEME_FORM": "Tema",
  "THEME_FORM_NAME_LABEL": "Nom",
  "THEME_FORM_BUTTON_LABEL": "Botó",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Botó Desar",
  "THEME_FORM_BAR_LABEL": "Barra",
  "THEME_FORM_BACKGROUND_LABEL": "Fons",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service Accounts",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Nova API key",
  "API_KEY_CREATED": "Nova API key creada",
  "API_KEY_SAFETY": "Guarda-la en un lloc segur",
  "API_KEY_CREATED_MESSAGE": "Emmagatzema aquesta clau en un lloc segur, com ara un gestor de contrasenyes. <br><b>No podràs tornar-la a veure.</b>",
  "API_KEY_REMOVE_TITLE": "Eliminar API key?",
  "API_KEY_REMOVE_MESSAGE": "Eliminar aquesta clau pot causar que certes integracions deixin de funcionar! No es pot restaurar un cop eliminada.",
  "WEBHOOK_DETAILS": "Detalls dels Webhook",
  "WEBHOOK_ATTEMPTS": "Intents del Webhook",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Intents d'enviar un esdeveniment al vostre endpoint en els últims 30 dies",
  "WEBHOOK_REQUEST_BODY": "Request body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "El payload que enviem al teu endpoint",
  "WEBHOOK_RESPONSE": "Resposta",
  "WEBHOOK_RESPONSE_MESSAGE": "La resposta que rebem del vostre endpoint",
  "WEBHOOK_REMOVE_TITLE": "Eliminar Webhook?",
  "WEBHOOK_REMOVE_MESSAGE": "Segur que vols eliminar aquest Webhook? Sabies que també pots <b>desactivar-ho</b> accedint als detalls, a la columna Vista?",
  "ADD_SUBSCRIBER": "Add subscriber",
  "EVENT_TYPES": "Event types",
  "SELECT_EVENT_TYPES": "Selecciona els tipus d'esdeveniments als quals vols subscriure't",
  "SECRET": "Secret",
  "SERVICE_ACCOUNTS_NAME": "Nom",
  "SERVICE_ACCOUNTS_TYPE": "Tipus",
  "SERVICE_ACCOUNTS_ACTIONS": "Accions",
  "SERVICE_ACCOUNTS_RECONNECT": "Reconnectar",
  "SERVICE_ACCOUNTS_DELETE": "Eliminar",
  "SERVICE_ACCOUNTS_ADD": "Afegir Service Account",
  "SERVICE_ACCOUNTS_NON_FOUND": "No hi ha Service Accounts configurades",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "No s'han pogut carregar les Service Accounts",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "No s'ha pogut actualitzar la Service Account",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "No s'ha pogut crear la Service Account",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "No s'ha pogut reconnectar la Service Account",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "No s'ha pogut eliminar la Service Account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Eliminar Service Account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "Segur que vols eliminar la Service Account <b>'{{ displayName }}'</b>? <br><br> Els formularis amb integracions que utilitzen aquesta Service Account deixaran de funcionar.",
  "GET_STARTED": "Començar",
  "MANAGE": "Gestionar",
  "SSO_DSYNC": "Directory Synchronisation",
  "SSO": "Inici de Sessió Únic",
  "SSO_DESCRIPTION": "Connecta el directori de l'empresa i el mètode d'inici de sessió, per simplificar la gestió d'usuaris",
  "SSO_SHARED_WARNING": "Aquesta configuració d'Inici de Sessió Únic (SSO) és utilitzada per diversos clients. Qualsevol canvi afectarà a tots.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Utilitza Inici de Sessió Únic per iniciar sessió amb les credencials que utilitzeu per a totes les altres aplicacions.</h4><p></p><br><p>Aquesta característica està disponible a partir del pla <b>Bosc</b>.</p>",
  "SSO_CONNECTION_ACTIVE": "Actiu",
  "SSO_CONNECTION_INACTIVE": "Inactiu",
  "SSO_CONNECTION_DRAFT": "Esborrany",
  "SSO_CONNECTION_PENDING": "Pendent",
  "DSYNC_CONNECTION_LINKED": "Vinculat",
  "DSYNC_CONNECTION_UNLINKED": "Desvinculat",
  "DSYNC_CONNECTION_PENDING": "Pendent",
  "SSO_STEP_1_TITLE": "Comença",
  "SSO_STEP_2_TITLE": "Configura l'Inici de Sessió Únic (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Connecta't al teu Identity Provider",
  "SSO_STEP_3_TITLE": "Configura el Directory Synchronisation",
  "SSO_STEP_3_DESCRIPTION": "Sincronitza els teus grups i usuaris per a mantenir-los actualitzats",
  "SSO_STEP_4_TITLE": "SSO habilitat",
  "SSO_STEP_4_DESCRIPTION": "Els teus usuaris ja poden utilitzar SSO per a iniciar sessió",
  "SSO_IMPORTED_USERS": "{{count}} usuaris importats del teu directori",
  "SSO_IMPORTED_GROUPS": "{{count}} grups importats del teu directori",
  "SSO_NO_IMPORTED_USERS_GROUPS": "No hi ha usuaris ni grups importats",
  "SENDING_DOMAIN_TITLE": "Configura el domini de l'email",
  "SENDING_DOMAIN_DESCRIPTION": "Fes que els emails incloguin la marca de la teva empresa. Enviarem emails (com els informes PDF) des de <b>mevaempresa.com</b> en comptes de moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Aquest domini d'email és utilitzat per diversos clients. Qualsevol canvi els afectarà a tots.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Per canviar a un domini diferent, només has d'esborrar-lo i crear-ne un de nou",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Domini",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Domini",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "El domini des del que s'envien els emails, com <a>mevaempresa.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Nom",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Un nom o títol que puguin reconèixer els teus destinataris. En la majoria dels casos, és el nom de la teva empresa.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "Adreça Electrònica",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "S'utilitzarà com a adreça electrònica d'origen. També s'utilitzarà com a adreça electrònica de resposta.",
  "SENDING_DOMAIN_DELETE_TITLE": "Vols esborrar el domini personalitzat de l'email?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Això significa que els teus emails s'enviaran amb el domini original per defecte. Ja no necessitaràs els registres DNS associats. Pots eliminar-los del teu proveïdor de domini.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Afegir Registres DNS",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Afegeix aquests registres DNS al teu proveïdor per verificar que ets propietari del teu domini. La verificació pot tardar fins a 48 hores mentre es programen els canvis als registres.",
  "SENDING_DOMAIN_VERIFY_BTN": "Verificar registres",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Domini no vàlid '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "L'email d'origen ha d'incorporar el domini '{{domain}}'",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Envia emails (com els dels informes PDF) des del domini de la teva empresa</h4><p>Aquesta característica està disponible a partir <b>pla</b> Branca.</p>",
  "SETTINGS_SUCCESS_MESSAGE": "La teva configuració s'ha desat",
  "DYNAMIC_RECIPIENTS_ADD": "Afegir destinatari",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Per defecte",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Camps de l'Email",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Altres camps",
  "TASKS_NEW": "Nova tasca",
  "TASKS_PUBLISH_DATE": "Publicada",
  "TASKS_MESSAGE": "Missatge",
  "TASKS_AND_X_OTHERS": "i {{others}} més",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "S'ha produït un error en carregar les tasques. Disculpa les molèsties. Pots provar d'actualitzar la pàgina.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "No s'ha publicat el formulari seleccionat. Per crear tasques en aquest formulari cal publicar-lo.",
  "TASK_ADD_TITLE": "Nova tasca",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Seleccionar usuari(s) i/o grup(s)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} usuari(s)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "No hi ha usuaris en aquest grup",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Usuaris en aquest grup:",
  "TASK_ADD_MESSAGE_LABEL": "Missatge",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "No hi ha usuaris o grups amb accés a aquest formulari. Si us plau, dona accés als usuaris des del botó 'Gestionar usuaris' a la Vista General del formulari.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Enviar tasca",
  "TASK_DETAIL_TITLE": "Tasca",
  "TASK_DETAIL_MESSAGE": "Missatge",
  "TASK_DETAIL_ACTIONS": "Accions",
  "TASK_DETAIL_ACTION_COMPLETE": "Completar",
  "TASK_DETAIL_ACTION_REVOKE": "Anul·lar",
  "TASKS_GRID_NO_ENTRIES": "No hi ha tasques per a aquest formulari",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Immediatament",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "En 1 hora",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "En 2 hores",
  "TASKS_ADD_PUBLISH_TOMORROW": "Demà",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Realitzada",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "Pendent",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Rebutjada",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Anul·lada",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Completada",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Registre afegit a la fila: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Tasques creades per a:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "No s'ha creat cap tasca perquè no hi havia destinataris",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "L'informe s'ha enviat per email a:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "L'informe s'ha enviat per email a:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Substituir el logotip predeterminat"
}
;