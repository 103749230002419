'use strict';

angular.module('administrationPortal').controller('AdminUtilsSuppressionCtrl', ['moreConstants', '$scope', '$adminEmailService', function (moreConstants, $scope, $adminEmailService) {
    const self = this;
    self.removing = false;
    self.success = false;
    self.error = undefined;

    $scope.removeDomainSuppression = () => {
        if (!self.domain || self.domain.length === 0) {
            self.error = 'Enter a valid domain';
            return;
        }
        self.removing = true;
        self.success = false;
        self.error = undefined;
        $adminEmailService.removeDomainSuppression(self.domain).$promise.then(() => {
            self.success = true;
        }).catch((e) => {
            self.error = e.data.message;
        }).finally(() => {
            self.removing = false;
        });
    };
}]);
