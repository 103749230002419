'use strict';

angular.module('administrationPortal').controller('AdminUtilsIndexCtrl', function () {

  var self = this;

  self.menuItems = [
    {name: 'Mail signature', state: 'mailsignature'},
    {name: 'Account owners', state: 'account-owners'},
    {name: 'Partners', state: 'partners'},
    {name: 'Suppression', state: 'suppression'}
  ];
});
