'use strict';

angular.module('MoreAppsServices').factory('$billingService', ['$resource', 'moreConstants', '$modalService', '$http', '$q', function ($resource, moreConstants, $modalService, $http, $q) {

  const BillingPlans = $resource(moreConstants.apiEndpoint + '/billing/products/:customerId/:productId/plans', {}, {});
  const BillingProducts = $resource(moreConstants.apiEndpoint + '/billing/products/:customerId', {}, {});
  const BillingInvoices = $resource(moreConstants.apiEndpoint + '/billing/invoice/:customerId/:action', {}, {
    upcoming: { method: 'GET', params: {action: 'upcoming'}}
  });
  const BillingKey = $resource(moreConstants.apiEndpoint + '/billing/account/key/:customerId', { customerId: '@customerId' }, {});
  const BillingPayment = $resource(moreConstants.apiEndpoint + '/billing/payment/:customerId', { customerId: '@customerId' }, {});
  const BillingPaymentMethod = $resource(moreConstants.apiEndpoint + '/billing/payment/:customerId/:methodId', {customerId: '@customerId', methodId: '@methodId'}, {});
  const BillingPaymentMethodDefault = $resource(moreConstants.apiEndpoint + '/billing/payment/:customerId/:methodId/default', {customerId: '@customerId', methodId: '@methodId'}, {});
  const BillingSubscription = $resource(moreConstants.apiEndpoint + '/billing/subscription/:customerId/:subscription/:action', {}, {
    update: { method: 'PUT' },
    cancelSchedule: { method: 'DELETE', params: {action: 'schedule'} },
    uncancel: { method: 'POST', params: {action: 'uncancel'} }
  });
  const BillingSubscriptionPreview = $resource(moreConstants.apiEndpoint + '/billing/subscription/:customerId/:subscription/preview', {}, {});
  const BillingPreview = $resource(moreConstants.apiEndpoint + '/billing/subscription/:customerId/preview', {}, {});
  const BillingUsage = $resource(moreConstants.apiEndpoint + '/billing/subscription/:customerId/usage', {}, {});
  const BillingStatus = $resource(moreConstants.apiEndpoint + '/billing/subscription/:customerId/status', {}, {});
  const BillingConversion = $resource(moreConstants.apiEndpoint + '/billing/:customerId/conversion', {}, {});
  const BillingCoupon = $resource(moreConstants.apiEndpoint + '/billing/coupon/:customerId', {}, {});

  const openBillingFlow = (isTrialing) =>
    $modalService.open({
      template: require('../../Billing/templates/billing.flow.modal.html'),
      size: 'lg',
      windowClass: 'tour-modal',
      controller: 'BillingFlowCtrl as ctrl',
      resolve: {
        isTrialing
      }
    });

  return {
    openBillingFlow,
    getPlans: function (customerId) {
      return this.getProducts(customerId).$promise.then(products => {
        const productId = products.find(x => x.type === 'BASE').product;
        return BillingPlans.query({customerId, productId}).$promise;
      });
    },
    getProducts: customerId => BillingProducts.query({customerId}),
    getInvoices: customerId => BillingInvoices.query({customerId}),
    hasOpenInvoices: customerId => BillingInvoices.query({customerId}).$promise.then(invoices => invoices.some(i => i.invoiceStatus === 'OPEN')),
    getUpcomingInvoice: customerId => BillingInvoices.upcoming({customerId}),
    getActiveSubscriptions: customerId => BillingSubscription.query({customerId}),
    getUsage: customerId => BillingUsage.get({customerId}),
    changeSubscription: (customerId, subscription, product, plan, reason, payOnFile) => {
      const request = {product, plan, reason, payOnFile};
      return BillingSubscription.update({customerId, subscription}, request);
    },
    cancelSubscription: (customerId, subscription, terminationFeedback) => {
      const deferred = $q.defer();
      const url = moreConstants.apiEndpoint + `/billing/subscription/${customerId}/${subscription}`;
      // $resource doesn't support request body in a DELETE request, so we need to do it via $http
      $http({method: 'delete', url: url, data: terminationFeedback})
          .success(deferred.resolve)
          .error(deferred.reject);
      return deferred.promise;
    },
    uncancelSubscription: (customerId, subscription) => BillingSubscription.uncancel({customerId, subscription}, {}),
    cancelSubscriptionSchedule: (customerId, subscription) => BillingSubscription.cancelSchedule({customerId, subscription}),
    convertToPlan: (customerId, plan, payOnFile) => BillingConversion.save({customerId, plan, payOnFile}, {}),
    previewChangeSubscription: (customerId, subscription, product, plan) => {
      let request = {product, plan};
      if (subscription) {
        return BillingSubscriptionPreview.save({customerId, subscription}, request);
      }
      return BillingPreview.save({customerId}, request);
    },
    createSetupIntent: customerId => BillingPayment.save({customerId}),
    setDefaultPaymentMethod: (customerId, methodId) => BillingPaymentMethodDefault.save({customerId, methodId}),
    removePaymentMethod: (customerId, methodId) => BillingPaymentMethod.delete({customerId, methodId}),
    getPaymentMethods: customerId => BillingPayment.query({customerId}),
    getPublishableKey: customerId => BillingKey.get({customerId}),
    getStatus: customerId => BillingStatus.get({customerId}),
    getCoupon: (customerId) => BillingCoupon.get({customerId}),
    addCoupon: (customerId, coupon) => BillingCoupon.save({customerId}, {coupon}),
    deleteCoupon: (customerId) => BillingCoupon.delete({customerId})
  };
}]);
