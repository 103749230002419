'use strict';

angular.module('administrationPortal').controller('PortalCtrl', ['$rootScope', '$scope', '$state', '$securityService', function ($rootScope, $scope, $state, $securityService) {

  $scope.$state = $state;

  $scope.headerTemplate = require('../../../shared/templates/navbar.html');

  if ($scope.currentUser) {
    $scope.prettyName = $scope.currentUser.name;
  }
  $rootScope.settings = {
    dateFormat: 'dd-MM-yyyy'
  };

  $scope.logout = function () {
    $securityService.logout();
  };

  $scope.menuItems = {
    top: [{
      'id': 'customers',
      route: 'portal.customers',
      active: 'portal.customers',
      icon: 'users',
      name: 'MENU_CUSTOMERS'
    }, {
      'id': 'users',
      route: 'portal.users',
      active: 'portal.users',
      icon: 'user',
      name: 'MENU_USERS'
    }, {
      'id': 'submissions',
      route: 'portal.submissions',
      active: 'portal.submissions',
      icon: 'file',
      name: 'Submissions'
    }, {
      'id': 'financial',
      route: 'portal.financial.trees',
      active: 'portal.financial',
      icon: 'money',
      name: 'MENU_FINANCIAL'
    }, {
      'id': 'queue',
      route: 'portal.queue',
      active: 'portal.queue',
      icon: 'tasks',
      name: 'MENU_QUEUE'
    },
    {
      'id': 'gdpr',
      route: 'portal.gdpr',
      active: 'portal.gdpr',
      icon: 'shield',
      name: 'GDPR'
    }],
    bottom: [{
      'id': 'utils',
      icon: 'cube',
      route: 'portal.utils.mailsignature',
      active: 'portal.utils',
      name: 'Utilities'
    }, {
      'id': 'user',
      icon: 'user',
      dynamicName: function () {
        return $scope.prettyName;
      },
      subs: [{
        'id': 'logout',
        click: function () {
          $scope.logout();
        },
        icon: 'power-off',
        name: 'MENU_LOGOUT'
      }]
    }]
  };
}]);
