'use strict';

angular.module('MoreDirectives').directive('datePicker', ['$dateHelper', function ($dateHelper) {
  return {
    restrict: 'A',
    scope: {
      ngModel: '=',
      onParent: '='
    },
    link: function (scope, element) {
      $dateHelper.setupDateTime(element, { onParent: scope.onParent }, function (value) {
        scope.ngModel = value.text;
      });
    }
  };
}]);