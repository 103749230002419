//Dependencies
require('../modules/MoreSecurity');
require('../modules/MoreServices');
require('../modules/AdminServices');
require('../modules/MoreI18n');
require('../modules/MoreFilters');
require('../modules/MoreConstants');
require('../modules/MoreUtils');
require('../modules/MoreDirectives');
require('../modules/MoreDirectives/templates/*.html');
require('../modules/MoreMenu');
require('../modules/PortalContext');
require('../modules/MoreGrid');
require('../modules/MoreGrid/templates/*.html');
require('../modules/MoreValidation');
require('../modules/Modals');
require('../modules/MoreSidePanel');

require('../modules/PageControl');
require('../modules/PageControl/templates/*.html');

require('../modules/DynamicForms');
require('../modules/DynamicForms/templates/*.html');

require('../shared/templates/**/*.html');
require('../administration-portal/templates/*.html');

require('../modules/SelectableDropdown');
require('../modules/SelectableDropdown/templates/*.html');

require('../modules/more.ui.form');
require('../modules/more.ui.form-builder');
require('../modules/more.ui.form-builder/templates/*.html.js');

//App
require('./scripts/administration-portal-bootstrap');
require('./scripts/stateManager');

require('./scripts/controllers/AdminCustomersCtrl');

require('./scripts/controllers/AccessDeniedCtrl');
require('./scripts/controllers/AdminFinancialIndexCtrl');
require('./scripts/controllers/AdminFinancialTreesCtrl');
require('./scripts/controllers/AdminQueueCtrl');
require('./scripts/controllers/AdminQueueJobCtrl');
require('./scripts/controllers/PortalCtrl');
require('./scripts/controllers/AdminGDPRCtrl.js');
require('./scripts/controllers/AdminUsersCtrl.js');
require('./scripts/controllers/AdminSubmissionsCtrl.js');
require('./scripts/controllers/AdminUtilsIndexCtrl');
require('./scripts/controllers/AdminUtilsMailSignatureCtrl');
require('./scripts/controllers/AdminUtilsAccountOwnersCtrl');
require('./scripts/controllers/AdminUtilsSuppressionCtrl');
require('./scripts/controllers/AdminUtilsPartnersCtrl');

require('./scripts/administration-portal');
