'use strict';

angular.module('MoreAppsServices').factory('$adminEmailService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const AdminDomainSuppression = $resource(moreConstants.apiEndpoint + '/admin/email/suppression/:domain', {}, {
    remove: {
      method: 'DELETE'
    }
  });

  return {
    removeDomainSuppression: (domain) => AdminDomainSuppression.remove({domain}),
  };
}]);
