'use strict';

angular.module('MoreAppsServices').factory('$adminSubmissionService', ['moreConstants', '$resource', function (moreConstants, $resource) {

  const AdminDraftSubmissions = $resource(moreConstants.apiEndpoint + '/admin/submissions/user/drafts/:userName', {userName: '@userName'}, {
  });

  const AdminHistoricSubmissions = $resource(moreConstants.apiEndpoint + '/admin/submissions/user/history/:userName', {userName: '@userName'}, {
  });

  return {
    get: (userName) => AdminDraftSubmissions.query({userName}),
    getHistory: (userName) => AdminHistoricSubmissions.get({userName}),
  };
}]);
