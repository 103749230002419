'use strict';

angular.module('administrationPortal').controller('AdminSubmissionsCtrl', ['$location', '$adminSubmissionService', '$modalService', function ($location, $adminSubmissionService, $modalService) {
    const self = this;
    self.error = null;
    self.historyError = null;
    self.submissions = null;
    self.historySubmissions = null;
    self.findSubmissions = findSubmissions;
    self.viewDetails = viewDetails;

    init();

    function init() {
        const username = $location.search().username;
        if (username) {
            self.query = username;
            findSubmissions(username);
        }
    }

    function findSubmissions(username) {
        self.error = null;
        self.submissions = null;

        $adminSubmissionService.get(username).$promise
            .then((submissions) => {
                self.submissions = submissions;
                $location.search('username', username);
            })
            .catch((err) => {
                self.error = err.status === 404 ? 'Submissions not found' : 'Server error: ' + err.data.message;
                self.submissions = null;
                $location.search(''); // remove search
            });
        $adminSubmissionService.getHistory(username).$promise
            .then((submissions) => {
                self.historySubmissions = submissions;
            })
            .catch((err) => {
                self.historyError = err.status === 404 ? 'Submissions not found' : 'Server error: ' + err.data.message;
                self.historySubmissions = null;
            });

    }

    function viewDetails(submission) {
        $modalService.open({
            template: require('../../templates/portal/submissions.detail.html'),
            backdrop: 'static',
            size: 'lg',
            windowClass: 'modal-fullscreen',
            controller: ['$scope', ($scope) => {
                $scope.submission = submission;
            }]
        });
    }

}]);
