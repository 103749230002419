'use strict';

angular.module('StateConstants', []);
angular.module('StateConstants').constant('stateConfig', {
  defaultAccess: ['ADMIN']
});

angular.module('administrationPortal').config(['$stateProvider', 'stateConfig', function ($stateProvider, stateConfig) {

  $stateProvider.state('portal', {
    url: '/',
    template: require('../templates/portal.html'),
    controller: 'PortalCtrl',
    resolve: {
      currentCustomer: function () {
        return null;
      }
    },
    access: stateConfig.defaultAccess
  }).state('portal.users', {
    url: 'users',
    template: require('../../administration-portal/templates/portal/users.index.html'),
    controller: 'AdminUsersCtrl as ctrl',
    access: stateConfig.defaultAccess
  }).state('portal.submissions', {
      url: 'submissions',
      template: require('../../administration-portal/templates/portal/submissions.index.html'),
      controller: 'AdminSubmissionsCtrl as ctrl',
      access: stateConfig.defaultAccess
  }).state('portal.customers', {
    url: 'customers?customerId',
    template: require('../../administration-portal/templates/portal/customers.html'),
    controller: 'AdminCustomersCtrl as ctrl',
    params: {customerId: null},
    access: stateConfig.defaultAccess,
    reloadOnSearch: false
  }).state('portal.financial', {
    url: 'financial',
    template: require('../../administration-portal/templates/portal/financial.index.html'),
    abstract: true,
    controller: 'AdminFinancialIndexCtrl as ctrl',
    access: stateConfig.defaultAccess
  }).state('portal.financial.trees', {
    url: '/trees',
    data: {
      viewContentTemplate: require('../templates/portal/financial.trees.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.queue', {
    url: 'queue',
    template: require('../../administration-portal/templates/portal/queue.html'),
    access: stateConfig.defaultAccess,
    controller: 'AdminQueueCtrl as ctrl'
  }).state('portal.gdpr', {
    url: 'gdpr',
    template: require('../templates/portal/gdpr.html'),
    controller: 'AdminGDPRCtrl as ctrl',
    access: stateConfig.defaultAccess
  }).state('portal.utils', {
    url: 'utils',
    abstract: true,
    template: require('../templates/portal/utils.index.html'),
    controller: 'AdminUtilsIndexCtrl as ctrl',
    access: stateConfig.defaultAccess
  }).state('portal.utils.mailsignature', {
    url: '/mailsignature',
    data: {
      viewContentTemplate: require('../templates/portal/utils.mailsignature.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.utils.account-owners', {
    url: '/account-owners',
    data: {
      viewContentTemplate: require('../templates/portal/utils.account-owners.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.utils.partners', {
    url: '/partners',
    data: {
      viewContentTemplate: require('../templates/portal/utils.partners.html')
    },
    access: stateConfig.defaultAccess
  }).state('portal.utils.suppression', {
    url: '/suppression',
    data: {
      viewContentTemplate: require('../templates/portal/utils.suppression.html')
    },
    access: stateConfig.defaultAccess
  });
}]);
